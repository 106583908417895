import React, {useEffect, useRef} from 'react';
import {FormLabel, InfoIcon, ShareLinkIcon, Tooltip} from 'components';
import {ReferralButtons} from 'features/Member/Share/ShareButtons';
import {useToggle} from 'hooks';
import {SliceStatus} from 'interfaces';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {yupResolver} from '@hookform/resolvers/yup';

import {getCopyContent, getShareUrl} from '../utils';
import {artValidationSchema} from '../validation';
import {
  selectWellniteArt,
  selectWellniteArtStatus,
} from '../wellniteArtSelectors';

export function ShareWellniteArt(): React.ReactElement {
  const {t} = useTranslation();
  const sliceStatus = useSelector(selectWellniteArtStatus);
  const wellniteArt = useSelector(selectWellniteArt);
  const {toggleOn: shareOn, onToggleClick: onShareClick} = useToggle();
  const {toggleOn: consentOn, onToggleClick: onConsentClick} = useToggle();
  const tooltipRef = useRef<Element>(null);

  const wellniteArtImageSrc = wellniteArt?.generatedImageUrl;

  const form = useForm({
    resolver: yupResolver(artValidationSchema),
    reValidateMode: 'onChange',
    defaultValues: {
      caption: '',
      title: '',
    },
  });

  useEffect(() => {
    if (wellniteArt) {
      form.setValue('caption', wellniteArt.generatedImageCaption);
      form.setValue('title', wellniteArt.generatedImageTitle);
    }
  }, [wellniteArt]);

  return (
    <div className="text-center flex flex-col justify-center items-center space-y-2 self-center h-full w-full max-w-[1500px]">
      {sliceStatus === SliceStatus.resolved && !!wellniteArt ? (
        <div className="pb-20 md:pb-12 md:pt-6 w-full flex flex-col h-full overflow-scroll">
          <div className="flex flex-col md:flex-row w-full justify-center">
            <div className="font-inter text-xl md:text-sm text-gray-900 flex flex-col md:text-left md:my-4 md:mx-8 m-auto">
              <p>{t('wellniteArt.yourWellniteArt')}:</p>
              <img
                src={wellniteArtImageSrc}
                className="rounded-[40px] max-h-[60vh] max-w-[80vw] w-auto mr-4 my-2"
                alt="Generated Image"
              />
            </div>
            <div className="flex flex-col w-full max-w-[600px] gap-4 justify-center p-3 m-auto md:m-0">
              <div className="font-inter text-2xl text-gray-900 text-left">
                "{wellniteArt?.generatedImageTitle}"
              </div>
              <div className="font-inter text-sm text-left">
                {wellniteArt?.generatedImageCaption}
              </div>
              <FormLabel htmlFor="" className="!text-[16px] text-gray-800">
                {t('wellniteArt.hashtagsForThisArtwork')}:
              </FormLabel>
              <div className="items-left flex flex-wrap gap-4">
                {wellniteArt?.tags.map(tag => (
                  <span
                    key={tag._id}
                    className="text-gray-500 text-sm float-left bg-gray-200 rounded-full px-4 py-2"
                  >
                    #{tag.name}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <p className="p-3 text-normal font-inter flex items-center md:justify-center gap-2 text-left md:text-center m-auto">
            {t('wellniteArt.shareWithFriends')}

            <Tooltip
              ref={tooltipRef}
              className="border-none m-0 p-0 bg-gray-300 right-0"
              content={
                <div className="p-2 bg-gray-300 text-black max-w-[200px] text-left">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('wellniteArt.sharingConsent', {
                        termsUrl: '/wellnite-art-terms-of-use',
                      }),
                    }}
                  ></p>
                </div>
              }
              theme="translucent"
              visible={consentOn}
              onClickOutside={onConsentClick}
              arrow={false}
              interactiveBorder={0}
              interactive
              placement="right"
            >
              <button onClick={onConsentClick}>
                <InfoIcon
                  classes="-mt-2"
                  strokeColor="text-primary"
                  height={14}
                ></InfoIcon>
              </button>
            </Tooltip>
          </p>
          <div className="pt-6 px-3 w-full">
            <Tooltip
              ref={tooltipRef}
              content={
                <div className="p-1">
                  <ReferralButtons
                    includeCopy
                    copyContent={getCopyContent(wellniteArt)}
                    copyHoverText={t(
                      'share_wellnite_art',
                      'Share Wellnite Art',
                    )}
                    copySuccessText={t('copied', 'Copied')}
                    shareUrl={getShareUrl(wellniteArt._id)}
                    title={wellniteArt.generatedImageTitle}
                    classes="flex flex-wrap gap-2"
                  />
                </div>
              }
              theme="radius-lg"
              visible={shareOn}
              onClickOutside={onShareClick}
              interactive
              placement="auto"
            >
              <button
                type="button"
                className="h-12 xs:text-sm px-4 sm:px-8 flex items-center group justify-center bg-primary text-white hover:bg-white hover:text-primary py-2.5 rounded-full text-base border border-primary mx-auto xs:min-w-[150px] min-w-full"
                onClick={onShareClick}
              >
                <p className="pr-1">{t('wellniteArt.shareArt')}</p>
                <ShareLinkIcon
                  height={15}
                  strokeColor="text-[#FFFFFF] group-hover:text-primary"
                  strokeWidth={1}
                />
              </button>
            </Tooltip>
          </div>
        </div>
      ) : null}
    </div>
  );
}
