import React from 'react';

import {SvgIcon} from './SvgIcon';

export const CircleCheckIcon: SvgIcon = ({
  width = 20,
  height = 20,
  classes = '',
  fillColor = 'none',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fillColor}
    viewBox="2 2 20 20"
    stroke="currentColor"
    className={`${classes}`}
    width={width}
    height={height}
  >
    <path d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
  </svg>
);

export const FilledCircleCheckIcon: SvgIcon = ({
  width = 24,
  height = 24,
  classes = '',
  fillColor = '#0C1C48',
  strokeColor = 'white',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
  >
    <rect width="24" height="24" rx="12" fill={fillColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 8.10589L9.85043 17L6 12.7978L7.01332 11.6919L9.85043 14.7882L16.9867 7L18 8.10589Z"
      fill={strokeColor}
    />
  </svg>
);
