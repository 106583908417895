import {
  GetSuperCoachChatMessages,
  SendSuperCoachChatMessageData,
  SuperCoachChatMessage,
  SuperCoachChatMessages,
  SuperCoachChatMessageStreamed,
  SuperCoachOnboardingData,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

/**
 * Actions responsible for SuperCoach onboarding.
 */
export const sendSuperCoachOnboardingData = createAction<
  SuperCoachOnboardingData & {onSuccess?: VoidFunction}
>('sendSuperCoachOnboardingData');

export const sendSuperCoachOnboardingDataSuccess = createAction<boolean>(
  'sendSuperCoachOnboardingDataSuccess',
);

export const sendSuperCoachOnboardingDataFailure = createAction<string>(
  'sendSuperCoachOnboardingDataFailure',
);

/**
 * Actions responsible for fetching SuperCoach chat messages.
 */
export const getSuperCoachChatMessages = createAction<
  GetSuperCoachChatMessages & {reset?: boolean}
>('getSuperCoachChatMessages');

export const getSuperCoachChatMessagesSuccess =
  createAction<SuperCoachChatMessages>('getSuperCoachChatMessagesSuccess');

export const getSuperCoachChatMessagesFailure = createAction<string>(
  'getSuperCoachChatMessagesFailure',
);

/**
 * Actions responsible for sending a chat message to SuperCoach.
 */
export const sendSuperCoachChatMessage =
  createAction<SendSuperCoachChatMessageData>('sendSuperCoachChatMessage');

export const receiveSuperCoachChatMessageStreamChunk =
  createAction<SuperCoachChatMessageStreamed>(
    'receiveSuperCoachChatMessageStreamChunk',
  );

export const endSuperCoachChatMessageStreamChunk = createAction(
  'endSuperCoachChatMessageStreamChunk',
);

export const sendSuperCoachChatMessageSuccess =
  createAction<SuperCoachChatMessage>('SendSuperCoachChatMessageSuccess');

export const sendSuperCoachChatMessageFailure = createAction<string>(
  'SendSuperCoachChatMessageFailure',
);
