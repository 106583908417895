import {SendReferralLinkToContactsData} from 'services/api';

import {createAction} from '@reduxjs/toolkit';

export const sendReferralLinkToContacts = createAction<{
  data: SendReferralLinkToContactsData;
  onSuccess?: VoidFunction;
  onError?: (message: string) => void;
}>('sendReferralLinkToContacts');
export const sendReferralLinkToContactsSuccess = createAction(
  'sendReferralLinkToContactsSuccess',
);
export const sendReferralLinkToContactsFailure = createAction<string>(
  'sendReferralLinkToContactsFailure',
);
