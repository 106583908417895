import React, {ForwardedRef, forwardRef, ReactNode} from 'react';
import onboardBg from 'assets/images/onboard-bg@4x.webp';
import {useHistory} from 'react-router';
import styled from 'styled-components';

import {Button} from '../Button';
import {CloseIcon} from '../SvgIcon';

const Container = styled.article<{isMobile?: boolean}>`
  // background: url(${onboardBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${props => (props.isMobile ? 'center' : 'right')};
  width: 100vw;
  height: 100vh;
`;

type BackgroundContainerProps = {
  children: ReactNode;
  isMobile?: boolean;
  containerClasses?: string;
  contentClasses?: string;
  id?: string;
  applyMaxWidth?: boolean;
  showClose?: boolean;
  onClick?: () => void;
};

const _BackgroundContainer = (
  {
    children,
    isMobile = false,
    containerClasses = '',
    contentClasses = '',
    id = '',
    applyMaxWidth = false,
    showClose = true,
    onClick,
  }: BackgroundContainerProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const history = useHistory();
  const goToDashboard = () => {
    history.push('/dashboard');
  };

  const _onClick = typeof onClick === 'function' ? onClick : goToDashboard;

  return (
    <Container
      isMobile={isMobile}
      id={id}
      ref={ref}
      className={containerClasses}
    >
      {showClose ? (
        <Button
          onClick={_onClick}
          bgColor="white"
          btnType="custom"
          borderRadius="full"
          className="flex justify-center items-center hover:bg-black border !border-gray-400 h-10 w-10 sm:h-14 sm:w-14 group fixed top-5 right-5 lg:top-8 lg:right-10 z-999"
        >
          <CloseIcon
            strokeColor="text-gray-700 group-hover:text-white"
            strokeWidth={40}
            classes="w-8 h-8"
          />
        </Button>
      ) : null}

      {applyMaxWidth ? (
        <section className={`w-full h-full ${contentClasses} responsive-w`}>
          {children}
        </section>
      ) : (
        <section className={`w-full h-full ${contentClasses}`}>
          {children}
        </section>
      )}
    </Container>
  );
};

export const BackgroundContainer = forwardRef<
  HTMLDivElement,
  BackgroundContainerProps
>(_BackgroundContainer);
