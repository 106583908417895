import React from 'react';
import classNames from 'classnames';
import {OptionType} from 'interfaces';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  UnPackAsyncDefaultValues,
} from 'react-hook-form';

interface Props<T extends FieldValues> {
  options: OptionType[];
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
  control: Control<T>;
  name: Path<UnPackAsyncDefaultValues<T>>;
}

export const MultiSelect = <T extends FieldValues>({
  options,
  selectedOptions,
  // setSelectedOptions,
  control,
  name,
}: Props<T>) => {
  // const toggleOption = (optionValue: string): void => {
  //   setSelectedOptions(prev =>
  //     prev.includes(optionValue)
  //       ? prev.filter(item => item !== optionValue)
  //       : [...prev, optionValue],
  //   );
  // };

  return (
    <Controller
      name={name}
      control={control}
      render={({fieldState: {error}}) => (
        <div className="space-y-2">
          {options.map(({value, label}) => (
            <label
              key={value}
              className="flex items-center space-x-2 px-2 py-2 border border-blue-500 rounded-lg"
            >
              <input
                type="checkbox"
                checked={true}
                // onChange={() => toggleOption(value)}
                className="hidden"
              />
              <span
                className={classNames(
                  'w-5 h-5 flex items-center justify-center border-blue-500 border rounded',
                  {
                    'bg-blue-500 text-white border-blue-500':
                      selectedOptions.includes(value),
                    'bg-white': !selectedOptions.includes(value),
                  },
                )}
              >
                {selectedOptions.includes(value) && '✓'}
              </span>
              <span className="text-gray-700">{label}</span>
            </label>
          ))}
          {error && <p className="text-red-500">{error.message}</p>}
        </div>
      )}
    />
  );
};
