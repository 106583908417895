import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {
  catchError,
  concat,
  concatMap,
  filter,
  from,
  ignoreElements,
  mergeMap,
  of,
  tap,
} from 'rxjs';
import {MoodTrackerService, ProgressAssessmentService} from 'services/api';

import {progressActions} from './progressSlice';

export const createMoodTrackerEntryEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(progressActions.createMoodTrackerEntry.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(MoodTrackerService.createMoodTrackerEntry(payload)).pipe(
        concatMap(entry => {
          if (onSuccess) {
            onSuccess();
          }
          return of(progressActions.createMoodTrackerEntrySuccess(entry));
        }),
        catchError((message: string) =>
          concat(of(progressActions.createMoodTrackerEntryFailure(message))),
        ),
      ),
    ),
  );

export const createMoodTrackerSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(progressActions.createMoodTrackerEntrySuccess.match),
    tap(() => {
      Toast({type: 'success', message: 'Saved'});
    }),
    ignoreElements(),
  );

export const createMoodTrackerFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(progressActions.createMoodTrackerEntryFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const getMoodTrackerEntriesEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(progressActions.getMoodTrackerEntries.match),
    mergeMap(({payload}) =>
      from(
        MoodTrackerService.getMoodTrackerEntries({
          email: payload.email,
          ...(payload?.cursor ? {cursor: payload.cursor} : {}),
        }),
      ).pipe(
        mergeMap(data => [progressActions.getMoodTrackerEntriesSuccess(data)]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'progress',
                message,
              }),
            ),
            of(progressActions.getMoodTrackerEntriesFailure(message)),
          ),
        ),
      ),
    ),
  );

const getProgressDataFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(progressActions.getMoodTrackerEntriesFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

export const getMoodTrackerStatsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(progressActions.getMoodTrackerStats.match),
    mergeMap(({payload}) =>
      from(MoodTrackerService.getMoodTrackerStats(payload)).pipe(
        mergeMap(data => [
          progressActions.getMoodTrackerStatsSuccess({
            email: payload.email,
            period: payload.period,
            data,
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'progress',
                message,
              }),
            ),
            of(
              progressActions.getMoodTrackerStatsFailure({
                email: payload.email,
                period: payload.period,
                error: message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

const getMoodTRackerStatsFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(progressActions.getMoodTrackerEntriesFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

export const updateProgressAssessmentSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(progressActions.updateProgressAssessment.fulfilled.match),
    tap(() => {
      Toast({type: 'success', message: 'Saved progress'});
    }),
    ignoreElements(),
  );

export const updateProgressAssessmentFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(progressActions.updateProgressAssessment.rejected.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message: message.toString()});
      }
    }),
    ignoreElements(),
  );

export const getProgressAssessmentStatsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(progressActions.getProgressAssessmentStats.match),
    mergeMap(({payload}) =>
      from(ProgressAssessmentService.getProgressAssessmentStats(payload)).pipe(
        mergeMap(data => [
          progressActions.getProgressAssessmentStatsSuccess({...payload, data}),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'progress',
                message,
              }),
            ),
            of(
              progressActions.getProgressAssessmentStatsFailure({
                ...payload,
                error: message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

const getProgressAssessmentStatsFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(progressActions.getProgressAssessmentStatsFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload.error});
    }),
    ignoreElements(),
  );

export const getProgressAssessmentAllStatsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(progressActions.getAllProgressAssessmentStats.match),
    mergeMap(({payload}) =>
      from(
        ProgressAssessmentService.getProgressAssessmentAllStats(payload),
      ).pipe(
        mergeMap(data => [
          progressActions.getAllProgressAssessmentStatsSuccess({
            ...payload,
            data,
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'progress',
                message,
              }),
            ),
            of(
              progressActions.getAllProgressAssessmentStatsFailure({
                ...payload,
                error: message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

const getProgressAssessmentAllStatsFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(progressActions.getAllProgressAssessmentStatsFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload.error});
    }),
    ignoreElements(),
  );

export const progressEpics = [
  createMoodTrackerEntryEpic,
  createMoodTrackerSuccessEpic,
  createMoodTrackerFailureEpic,
  getMoodTrackerEntriesEpic,
  getProgressDataFailureEpic,
  getMoodTrackerStatsEpic,
  getMoodTRackerStatsFailureEpic,
  updateProgressAssessmentFailureEpic,
  getProgressAssessmentStatsEpic,
  getProgressAssessmentStatsFailureEpic,
  getProgressAssessmentAllStatsEpic,
  getProgressAssessmentAllStatsFailureEpic,
  updateProgressAssessmentSuccessEpic,
];
