import {
  EndPoints,
  GetSuperCoachChatMessages,
  HttpMethods,
  SendSuperCoachChatMessageData,
  SuperCoachChatMessages,
  SuperCoachOnboardingData,
} from 'interfaces';
import {Response} from 'redaxios';
import {getBaseUrl, requestHandler} from 'services/api';
import {attachQueryParameters, unwrapAPIError} from 'utils';

const sendSuperCoachOnboardingData = async (
  formData: SuperCoachOnboardingData,
): Promise<boolean> => {
  try {
    await requestHandler<{message: boolean}, typeof formData>({
      method: HttpMethods.POST,
      url: EndPoints.SuperCoachCompleteOnboarding as unknown as EndPoints,
      data: formData,
    });
    return true;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getSuperCoachChatMessages = async ({
  limit = 20,
  page = 0,
}: GetSuperCoachChatMessages): Promise<
  Response<{message: SuperCoachChatMessages}>
> => {
  try {
    const url = attachQueryParameters(EndPoints.GetSuperCoachChatMessages, {
      limit: `${limit}`,
      page: `${page}`,
    });

    const result = await requestHandler<{message: SuperCoachChatMessages}>({
      method: HttpMethods.GET,
      url: url as unknown as EndPoints,
    });
    return result;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const sendSuperCoachChatMessage = (
  formData: SendSuperCoachChatMessageData,
): EventSource => {
  const eventSource = new EventSource(
    `${getBaseUrl()}${EndPoints.SendSuperCoachChatMessage}?question=${
      formData.message
    }`,
    {withCredentials: true},
  );
  return eventSource;
};

export const SuperCoachService = {
  sendSuperCoachOnboardingData,
  getSuperCoachChatMessages,
  sendSuperCoachChatMessage,
};
