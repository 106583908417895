import {RootState} from 'app/rootReducer';

import {createSelector} from '@reduxjs/toolkit';

import {ProviderState} from '.';

export function getProvider(state: RootState): ProviderState {
  return state.provider;
}

export const selectProvider = createSelector(getProvider, provider => provider);

export const selectMedications = createSelector(
  getProvider,
  provider => provider.medications,
);
export const selectProviderUploading = createSelector(
  getProvider,
  provider => provider.uploading,
);
export const selectBilling = (roomMode: 'normal' | 'group') =>
  createSelector(getProvider, provider => {
    let filteredAppts;

    if (roomMode === 'normal') {
      filteredAppts = provider.billing.appointmentData.filter(
        item =>
          ![
            'Group-Call with Therapist',
            'Group-Call with Therapist-TEST',
          ].includes(item.type),
      );
    } else {
      filteredAppts = provider.billing.appointmentData.filter(
        item =>
          [
            'Group-Call with Therapist',
            'Group-Call with Therapist-TEST',
          ].includes(item.type) && item.classID,
      );
    }

    return {
      ...provider.billing,
      appointmentData: filteredAppts,
    };
  });

export const selectBillingHistory = (roomMode: 'normal' | 'group') =>
  createSelector(getProvider, provider => provider.billingHistory[roomMode]);

export const selectMemberUploading = createSelector(
  getProvider,
  member => member.uploading,
);

export const selectProviderOnboardingDetails = createSelector(
  getProvider,
  onboarding => onboarding.onboarding,
);
export const selectProviderStatus = createSelector(
  getProvider,
  provider => provider.status,
);
export const selectInternationalProviderOnboardingStatus = createSelector(
  getProvider,
  provider => provider?.internationalOnboardingStatus,
);
export const selectProviderPicUploadStatus = createSelector(
  getProvider,
  provider => provider.providerPicStatus,
);

export const selectProviderResumeUploadStatus = createSelector(
  getProvider,
  provider => provider.providerResumeUploadStatus,
);

export const selectProviderOnboardingStatus = createSelector(
  getProvider,
  provider => provider.onboardingStatus,
);
export const selectProviderOnboardingStatusIsInitialized = createSelector(
  getProvider,
  provider => provider.onboardingStatusIsInitialized,
);
export const selectProviderVerifiedByWellniteTeam = createSelector(
  getProvider,
  provider => provider?.verifiedByWellniteTeam,
);
export const selectProviderLanguageStatus = createSelector(
  getProvider,
  provider => provider.languageStatus,
);
export const selectProviderExpertSummaryStatus = createSelector(
  getProvider,
  provider => provider.expertSummaryStatus,
);
export const selectProviderSetFeePercentageStatus = createSelector(
  getProvider,
  provider => provider.setFeePercentageStatus,
);
export const selectProviderFeePercentage = createSelector(
  getProvider,
  provider => provider.feePercentage,
);

// Select provider compensation modal status
export const selectHasShownMonthlyCompensationModalStatus = createSelector(
  getProvider,
  provider => provider.hasShownMonthlyCompensationModal,
);
