export interface GeneratePostSessionQuestionnaireArgs {
  appointmentId: string;
}

export interface GenerateIndividualQuestionArgs {
  questionId: string;
}

export interface RegenerateWellniteArtCaptionArgs {
  wellniteArtId: string;
}

export interface GeneratedQuestion {
  question: string;
  answer?: string;
  placeholderAnswer: string;
  _id: string;
}

export interface Answer extends GeneratedQuestion {
  answer: string;
}

export interface GeneratePostSessionQuestionnaireResponse {
  _id: string;
  appointmentID: number;
  questionnaires: GeneratedQuestion[];
  patientId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export type GenerateIndividualQuestionResponse =
  GeneratePostSessionQuestionnaireResponse;

export interface GenerateImageFromPostSessionQuestionnaireArgs {
  magicArtId: string;
  questionnaires: {answer: string; questionId: string}[];
}

export interface GenerateImageFromPostSessionQuestionnaireResponse
  extends GeneratePostSessionQuestionnaireResponse {
  generatedImageUrl: string;
  generatedImagePrompt: string;
  generatedImageThumbnailUrl: string;
  generatedImageTitle: string;
  generatedImageCaption: string;
  questionnaires: Answer[];
  status: string;
  tags: {
    _id: string;
    name: string;
  }[];
}

export type RegenerateWellniteArtCaptionResponse =
  GenerateImageFromPostSessionQuestionnaireResponse;

export interface GetPaginatedWellniteArtsPayload {
  maxResults: number;
  page?: number;
}

export interface WellniteArtImage {
  _id: string;
  createdAt: string;
  patientId: string;
  appointmentID: string;
  generatedImageUrl: string;
  generatedImageThumbnailUrl: string;
  generatedImageTitle: string;
  generatedImageCaption: string;
  status: string;
  tags: {
    _id: string;
    name: string;
  }[];
  liked?: boolean;
}

export interface GetPaginatedWellniteArtGalleryResponse {
  totalResults: number;
  totalPages: number;
  currentPage: number;
  hasMore: boolean;
  results: WellniteArtImage[];
}

export interface SearchWellniteArtsGalleryPayload {
  page: number;
  searchTerm: string;
  selectedConditionTags: string[];
  galleryType: GalleryType;
  maxResults: number;
}

export type SearchWellniteArtsResponse = GetPaginatedWellniteArtGalleryResponse;

export type GetPatientWellniteArtsResponse = WellniteArtImage[];

export type GetPatientLikedWellniteArtsResponse = WellniteArtImage[];

export type GetWellniteArtForYouResponse = WellniteArtImage[];

export type RecordWellniteArtReactionPayload = {
  wellniteArtId: string;
  reaction: 'like' | 'dislike';
};

export type GenerateWellniteArtTitleArgs = {
  wellniteArtId: string;
};

export type GenerateWellniteArtTitleResponse =
  GenerateImageFromPostSessionQuestionnaireResponse;

export type GetWellniteArtByIdArgs = {
  wellniteArtId: string;
};

export type GetWellniteArtByIdResponse =
  GenerateImageFromPostSessionQuestionnaireResponse;

export type GetWellniteArtConditionTagsArgs = {
  wellniteArtId?: string;
};

export type GetWellniteArtConditionTagsResponse = {
  _id: string;
  name: string;
}[];

export type UpdateWellniteArtInfoArgs = {
  magicArtId: string;
  title: string;
  caption: string;
};

export type UpdateWellniteArtInfoResponse =
  GenerateImageFromPostSessionQuestionnaireResponse;

export type GalleryType = 'wellnite_collection' | 'my_collection' | 'my_art';

export const MAX_RESULTS_PER_PAGE = 30;
