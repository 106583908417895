import React, {FC} from 'react';
import LogoBlue from 'assets/images/logo-blue.png';
import {CopyIcon, CopyText} from 'components';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share';
import styled from 'styled-components';

const ReferralButtonsContainer = styled.section`
  & > div {
    vertical-align: top;
    display: inline-block;
    text-align: center;
  }

  & .share-button {
    cursor: pointer;

    &:hover:not(:active) {
      opacity: 0.75;
    }
  }
`;

export interface ReferralButtonsProps {
  shareUrl: string;
  title?: string;
  copyContent?: string;
  copyHoverText?: string;
  copySuccessText?: string;
  includeCopy?: boolean;
  classes?: string;
}

export const ReferralButtons: FC<ReferralButtonsProps> = ({
  shareUrl,
  copyContent = '',
  copySuccessText = '',
  copyHoverText = '',
  title = 'Use my referral code to signup on wellnite.co',
  includeCopy = false,
  classes = 'flex flex-wrap gap-2',
}) => {
  return (
    <ReferralButtonsContainer className={classes}>
      {includeCopy ? (
        <div>
          <CopyText
            hoverText={copyHoverText}
            successText={copySuccessText}
            content={copyContent}
            classes="w-8 h-8 flex items-center justify-center rounded-full bg-blue-600 text-center share-button"
          >
            <CopyIcon strokeColor="text-white" width={15} height={15} />
          </CopyText>
        </div>
      ) : null}
      <div>
        <FacebookShareButton
          url={shareUrl}
          title={title}
          className="share-button"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </div>

      <div>
        <FacebookMessengerShareButton
          url={shareUrl}
          appId="521270401588372"
          className="share-button"
        >
          <FacebookMessengerIcon size={32} round />
        </FacebookMessengerShareButton>
      </div>

      <div>
        <TwitterShareButton
          url={shareUrl}
          title={title}
          className="share-button"
        >
          <XIcon size={32} round />
        </TwitterShareButton>
      </div>

      <div>
        <TelegramShareButton
          url={shareUrl}
          title={title}
          className="share-button"
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>
      </div>

      <div>
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          className="share-button"
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>

      <div>
        <LinkedinShareButton url={shareUrl} className="share-button">
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>

      <div>
        <PinterestShareButton
          url={String(window.location)}
          media={`${String(window.location)}/${LogoBlue}`}
          className="share-button"
        >
          <PinterestIcon size={32} round />
        </PinterestShareButton>
      </div>

      <div>
        <RedditShareButton
          url={shareUrl}
          title={title}
          windowWidth={660}
          windowHeight={460}
          className="share-button"
        >
          <RedditIcon size={32} round />
        </RedditShareButton>
      </div>

      <div>
        <TumblrShareButton
          url={shareUrl}
          title={title}
          className="share-button"
        >
          <TumblrIcon size={32} round />
        </TumblrShareButton>
      </div>

      <div>
        <EmailShareButton
          url={shareUrl}
          subject={title}
          body="body"
          className="share-button"
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>
    </ReferralButtonsContainer>
  );
};
