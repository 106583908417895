import React from 'react';

import {SvgIcon} from './SvgIcon';

export const SuperCoachIcon: SvgIcon = ({
  width = 32,
  height = 32,
  classes = 'text-white',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
  >
    <rect width="32" height="32" rx="9.26316" className="fill-current" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0525 9.28125C22.4477 9.28125 23.5788 10.4123 23.5788 11.8076V18.4069C23.5788 19.5327 22.6661 20.4454 21.5403 20.4454H21.4793C20.7624 20.4454 20.1813 21.0265 20.1813 21.7433C20.1813 22.5812 19.1933 23.0276 18.5645 22.4739L16.7384 20.8657C16.4309 20.5948 16.0351 20.4454 15.6253 20.4454H10.9472C9.55197 20.4454 8.4209 19.3143 8.4209 17.9191V11.8076C8.4209 10.4123 9.55197 9.28125 10.9472 9.28125H21.0525ZM20.3847 13.3956C20.7136 13.0668 20.7136 12.5336 20.3847 12.2047C20.0558 11.8758 19.5226 11.8758 19.1938 12.2047L15.4584 15.9401L14.1591 14.6408C13.8303 14.3119 13.2971 14.3119 12.9682 14.6408C12.6394 14.9697 12.6394 15.5028 12.9682 15.8317L14.863 17.7264L15.4584 18.3219L16.0539 17.7264L20.3847 13.3956Z"
      fill="url(#paint0_linear_9704_700)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9704_700"
        x1="15.9998"
        y1="9.28125"
        x2="10.4588"
        y2="24.5893"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="black" stopOpacity="1" />
        <stop offset="1" stopColor="#EAEFFD" stopOpacity="1" />
      </linearGradient>
    </defs>
  </svg>
);
