import React, {FC} from 'react';
import {DefaultLayout, MotionDiv} from 'components';
import {LayoutProps} from 'components/Layouts/Layout.types';
import {selectUserProfile} from 'features/User';
import {useSelector} from 'react-redux';
import {Redirect, RouteComponentProps} from 'react-router-dom';

import {RouteTransition} from './AnimatedRoutes';

export const PublicRoute: FC<{
  component: FC<RouteComponentProps>;
  path: string;
  layout?: FC<LayoutProps>;
  layoutProps?: {bgColor?: string; lgColumns?: string};
  exact?: boolean;
}> = ({
  component: Component,
  layout: ComponentLayout,
  layoutProps,
  ...rest
}) => {
  const user = useSelector(selectUserProfile);
  const Layout = ComponentLayout || DefaultLayout;
  const layoutStyles = {
    lgColumns: layoutProps?.lgColumns || 'lg:grid-cols-10',
    bgColor: layoutProps?.bgColor || 'bg-white',
  };

  const allowedPaths = [
    '/practice/register',
    '/post',
    '/unsubscribe/onboarding-reminder',
    '/minor-consent',
  ];

  const isPathAllowed = !allowedPaths.some(path => rest.path.includes(path));

  const renderLayout = (props: RouteComponentProps) => {
    //add to exclude public routes from redirecting to dasboard if logged in
    if (
      user?.role &&
      isPathAllowed &&
      (user.role !== 'patient' || !rest.path.includes('/direct-booking'))
    ) {
      return <Redirect to="/dashboard" />;
    } else if (!user?.role && rest.path.includes('/practice/register')) {
      return <Component {...props} />;
    } else {
      return (
        <Layout {...layoutStyles}>
          <Component {...props} />
        </Layout>
      );
    }
  };

  const renderPublicComponent: React.FC<RouteComponentProps> = props => {
    return <MotionDiv innerWidth={769}>{renderLayout(props)}</MotionDiv>;
  };
  return <RouteTransition {...rest} render={renderPublicComponent} />;
};
