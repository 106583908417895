import {SliceStatus, StreamedSuperCoachChatMessage} from 'interfaces';
import {v4 as uuid} from 'uuid';

import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  endSuperCoachChatMessageStreamChunk,
  getSuperCoachChatMessages,
  getSuperCoachChatMessagesFailure,
  getSuperCoachChatMessagesSuccess,
  receiveSuperCoachChatMessageStreamChunk,
  sendSuperCoachChatMessage,
  sendSuperCoachChatMessageFailure,
  sendSuperCoachChatMessageSuccess,
  sendSuperCoachOnboardingData,
  sendSuperCoachOnboardingDataFailure,
  sendSuperCoachOnboardingDataSuccess,
} from './superCoachActions';

type SuperCoachSliceState = {
  messages: StreamedSuperCoachChatMessage[];
  pagination: {
    page: number;
    nextPage: number | null;
    hasMore: boolean;
  };
  getMessagesStatus: SliceStatus;
  sendChatMessageStatus: SliceStatus;
  onboarded: boolean;
  sendSuperCoachDataStatus: SliceStatus;
  error: string | null;
};

export const superCoachInitialState: SuperCoachSliceState = {
  messages: [],
  pagination: {
    page: 0,
    nextPage: null,
    hasMore: false,
  },
  getMessagesStatus: SliceStatus.idle,
  sendChatMessageStatus: SliceStatus.idle,
  onboarded: false,
  sendSuperCoachDataStatus: SliceStatus.idle,
  error: null,
};

const superCoachSlice = createSlice({
  name: 'memberSuperCoach',
  initialState: superCoachInitialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<SuperCoachSliceState>) =>
    builder
      .addCase(sendSuperCoachOnboardingData, state => ({
        ...state,
        sendSuperCoachDataStatus: SliceStatus.pending,
        error: null,
      }))
      .addCase(sendSuperCoachOnboardingDataFailure, (state, {payload}) => ({
        ...state,
        sendSuperCoachDataStatus: SliceStatus.rejected,
        error: payload,
      }))
      .addCase(sendSuperCoachOnboardingDataSuccess, state => ({
        ...state,
        onboarded: true,
        sendSuperCoachDataStatus: SliceStatus.resolved,
        error: null,
      }))
      /**
       * Fetch SuperCoach chat messages.
       */
      .addCase(getSuperCoachChatMessages, (state, {payload}) => ({
        ...state,
        getMessagesStatus: SliceStatus.pending,
        messages: payload.reset ? [] : [...state.messages],
        error: null,
      }))
      .addCase(getSuperCoachChatMessagesFailure, (state, {payload}) => ({
        ...state,
        getMessagesStatus: SliceStatus.rejected,
        error: payload,
      }))
      .addCase(getSuperCoachChatMessagesSuccess, (state, {payload}) => ({
        ...state,
        messages: [...payload.items, ...state.messages],
        pagination: {
          hasMore: payload.hasMore,
          page: payload.page,
          nextPage: payload.nextPage,
        },
        getMessagesStatus: SliceStatus.resolved,
        error: null,
      }))
      /**
       * Send a new SuperCoach chat message.
       */
      .addCase(sendSuperCoachChatMessage, (state, {payload}) => ({
        ...state,
        messages: [
          ...state.messages,
          {
            id: `${uuid()}`,
            question: payload?.message ?? '',
            response: '',
            createdAt: new Date(),
            updatedAt: new Date(),
            isStreaming: true,
          },
        ],
        sendChatMessageStatus: SliceStatus.pending,
        error: null,
      }))
      .addCase(receiveSuperCoachChatMessageStreamChunk, (state, {payload}) => {
        const index = state.messages.length - 1;
        const message = state.messages[index];
        message.response = message.response + (payload.text ?? '');
        state.messages[index] = message;
      })
      .addCase(endSuperCoachChatMessageStreamChunk, state => {
        const index = state.messages.length - 1;
        const message = state.messages[index];
        message.isStreaming = false;
        state.messages[index] = message;
        state.sendChatMessageStatus = SliceStatus.resolved;
      })
      .addCase(sendSuperCoachChatMessageSuccess, (state, {payload}) => {
        state.sendChatMessageStatus = SliceStatus.resolved;
        state.messages[state.messages.length - 1] = {...payload};
      })
      .addCase(sendSuperCoachChatMessageFailure, (state, {payload}) => ({
        ...state,
        sendChatMessageStatus: SliceStatus.rejected,
        error: payload,
      })),
});

export const {reducer: superCoachReducer, name: superCoachReducerName} =
  superCoachSlice;

export type TSuperCoachActions =
  | ReturnType<typeof getSuperCoachChatMessages>
  | ReturnType<typeof getSuperCoachChatMessagesFailure>
  | ReturnType<typeof getSuperCoachChatMessagesSuccess>
  | ReturnType<typeof sendSuperCoachOnboardingData>
  | ReturnType<typeof sendSuperCoachOnboardingDataFailure>
  | ReturnType<typeof sendSuperCoachOnboardingDataSuccess>
  | ReturnType<typeof sendSuperCoachChatMessage>
  | ReturnType<typeof endSuperCoachChatMessageStreamChunk>
  | ReturnType<typeof receiveSuperCoachChatMessageStreamChunk>
  | ReturnType<typeof sendSuperCoachChatMessageFailure>
  | ReturnType<typeof sendSuperCoachChatMessageSuccess>;

export const superCoachActions = {
  getSuperCoachChatMessages,
  getSuperCoachChatMessagesFailure,
  getSuperCoachChatMessagesSuccess,
  sendSuperCoachOnboardingData,
  sendSuperCoachOnboardingDataFailure,
  sendSuperCoachOnboardingDataSuccess,
  sendSuperCoachChatMessage,
  endSuperCoachChatMessageStreamChunk,
  receiveSuperCoachChatMessageStreamChunk,
  sendSuperCoachChatMessageFailure,
  sendSuperCoachChatMessageSuccess,
};

export type SuperCoachState = ReturnType<typeof superCoachReducer>;
