import React, {FC, ReactNode, useState} from 'react';
import {CloseIcon} from 'components';
import {BookButton} from 'components/Headers/BookButton';
import MemberBookingBtn from 'features/Appointment/Booking/MemberBookingBtn';
import {MemberBookingModal} from 'features/Appointment/Booking/MemberBookingModal';
import {selectWellniteArt} from 'features/Member/Experiences/WellniteArt/wellniteArtSelectors';
import {PopupDrawer} from 'features/Provider/Calendar2/Components/Drawer';
import {useBreakpoint} from 'hooks';
import {Appointment, AppointmentTypes, CurrentUser} from 'interfaces';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {history, isMember, isMobile, isUserFromUnitedStates} from 'utils';

import {addMinutesToTime} from './utils';

const MainContainer = styled.div<{isMobile: boolean}>`
  width: 100%;
`;

type Props = {
  user: CurrentUser;
  appointment: Appointment;
  children: ReactNode;
};

const WherebyMemberVideoPage: FC<Props> = ({user, appointment, children}) => {
  const [appointmentType, setAppointmentType] = useState<
    AppointmentTypes | undefined
  >(undefined);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const wellniteArt = useSelector(selectWellniteArt);
  const {isLg} = useBreakpoint('lg');
  const {t} = useTranslation();

  const handleShowBookingModal = (
    onSetSession: (sessionType: AppointmentTypes) => void,
  ) => {
    if (user?.hasCompletedTherapyIntake) {
      onSetSession(AppointmentTypes.video_call_with_therapist);
    } else {
      history.push('/onboarding-lobby/therapy');
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('title.providerCall')}</title>
      </Helmet>
      <section className="flex flex-col gap-4 w-full h-screen font-inter p-4">
        <div className="flex flex-row items-center justify-between text-white bg-[#2E62EC] px-4 py-3 rounded-full">
          <div className="flex flex-row items-center gap-4">
            <div className="flex flex-row items-center gap-2 px-4 py-3  bg-[#4372EE] rounded-full">
              <img
                src={user?.image}
                className="w-6 h-6 bg-gray-300 rounded-full"
                alt="profile-photo"
              />
              <p className="text-base font-medium truncate">{user?.fullName}</p>
            </div>

            <div className="hidden md:flex flex-col">
              <p className="text-base">
                {t(`appointmentCategory.${appointment.category}`)}
              </p>
              <div className="flex flex-row gap-2">
                <p className="text-sm">
                  {t('other.sessionMinutes', {duration: appointment.duration})}{' '}
                  &#8226;
                </p>
                <p className="text-sm opacity-40">
                  {t('other.until')}&nbsp;
                  {addMinutesToTime(
                    appointment.timeOfAppointment,
                    Number(appointment.duration),
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center gap-4">
            {isUserFromUnitedStates(user) ? (
              <MemberBookingBtn borderColor="white" />
            ) : (
              <MemberBookingBtn
                type="custom"
                cb={onSetSession => (
                  <BookButton
                    setShowBookingModal={() =>
                      handleShowBookingModal(onSetSession)
                    }
                    borderColor="white"
                    btnType="custom"
                    classes="hover:bg-white hover:text-blue-600"
                  />
                )}
              />
            )}
            <button
              className="rounded-full flex items-center justify-center p-2 bg-[#4372EE] hover:bg-opacity-40"
              onClick={() => {
                const isWellniteArtGenerated =
                  wellniteArt?.appointmentID === appointment.appointmentID;
                history.push(
                  isWellniteArtGenerated
                    ? '/experiences/gallery?type=my_art'
                    : '/dashboard',
                );
              }}
              key="closeButton"
            >
              <CloseIcon
                title="Exit"
                strokeColor="text-white-400 hover:text-opacity-40"
                strokeWidth={30}
                width={30}
                height={30}
              />
            </button>
          </div>
        </div>

        <MainContainer
          isMobile={isMobile}
          className="flex flex-col items-center justify-center w-full h-screen bg-lightGray border rounded-xl relative mb-20 lg:mb-0"
        >
          <div className="flex md:hidden flex-col absolute right-3 top-5 bg-transparent text-white">
            <p className="text-base drop-shadow">
              {t(`appointmentCategory.${appointment.category}`)}
            </p>
            <div className="flex flex-row gap-2">
              <p className="text-sm drop-shadow">
                {t('other.sessionMinutes', {duration: appointment.duration})}{' '}
                &#8226;
              </p>
              <p className="text-sm opacity-40 drop-shadow">
                {t('other.until')} {appointment.timeOfAppointment}
              </p>
            </div>
          </div>
          {children}
        </MainContainer>
      </section>

      {/* Appointment Booking Modal */}
      {isLg && isMember(user) && appointmentType ? (
        <MemberBookingModal
          show={showBookingModal}
          appointmentType={appointmentType}
          member={user}
          setShowBookingModal={show => {
            setShowBookingModal(show);
          }}
          onClose={() => {
            setShowBookingModal(false);
            setAppointmentType(undefined);
          }}
        />
      ) : null}

      {!isLg && isMember(user) && appointmentType ? (
        <PopupDrawer
          show={showBookingModal}
          appointmentType={appointmentType}
          member={user}
          setShowBookingModal={show => {
            setShowBookingModal(show);
            setAppointmentType(undefined);
          }}
          onClose={() => {
            setShowBookingModal(false);
            setAppointmentType(undefined);
          }}
          ignoreOutsideClick
        />
      ) : null}
    </>
  );
};

export default WherebyMemberVideoPage;
