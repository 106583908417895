import {
  GenerateImageFromPostSessionQuestionnaireArgs,
  GenerateImageFromPostSessionQuestionnaireResponse,
  GenerateIndividualQuestionArgs,
  GeneratePostSessionQuestionnaireArgs,
  GeneratePostSessionQuestionnaireResponse,
  GenerateWellniteArtTitleArgs,
  GenerateWellniteArtTitleResponse,
  GetPaginatedWellniteArtGalleryResponse,
  GetPaginatedWellniteArtsPayload,
  GetPatientWellniteArtsResponse,
  GetWellniteArtByIdArgs,
  GetWellniteArtByIdResponse,
  GetWellniteArtConditionTagsResponse,
  GetWellniteArtForYouResponse,
  MemberProfile,
  RecordWellniteArtReactionPayload,
  RegenerateWellniteArtCaptionArgs,
  SearchWellniteArtsGalleryPayload,
  UpdateWellniteArtInfoArgs,
  UpdateWellniteArtInfoResponse,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const generateWellniteArtImage = createAction<
  GenerateImageFromPostSessionQuestionnaireArgs & {
    onSuccess?: (
      data: GenerateImageFromPostSessionQuestionnaireResponse,
    ) => void;
  }
>('GenerateWellniteArtImage');

export const generateWellniteArtImageSuccess =
  createAction<GenerateImageFromPostSessionQuestionnaireResponse>(
    'GenerateWellniteArtImageSuccess',
  );

export const generateWellniteArtImageFailure = createAction<string>(
  'GenerateWellniteArtImageFailure',
);

export const generateWellniteArtQuestionnaire = createAction<
  GeneratePostSessionQuestionnaireArgs & {
    onSuccess?: (data: GeneratePostSessionQuestionnaireResponse) => void;
  }
>('generateWellniteArtQuestionnaire');

export const generateWellniteArtQuestionnaireSuccess =
  createAction<GeneratePostSessionQuestionnaireResponse>(
    'GenerateWellniteArtQuestionnaireSuccess',
  );

export const generateWellniteArtQuestionnaireFailure = createAction<string>(
  'GenerateWellniteArtQuestionnaireFailure',
);

export const getPaginatedWellniteArtsGallery =
  createAction<GetPaginatedWellniteArtsPayload>(
    'GetPaginatedWellniteArtsGallery',
  );

export const getPaginatedWellniteArtsGallerySuccess =
  createAction<GetPaginatedWellniteArtGalleryResponse>(
    'GetPaginatedWellniteArtsGallerySuccess',
  );

export const getPaginatedWellniteArtsGalleryFailure = createAction<string>(
  'GetPaginatedWellniteArtsGalleryFailure',
);

export const getPatientWellniteArtsGallery = createAction(
  'GetPatientWellniteArtsGallery',
);

export const getPatientWellniteArtsGallerySuccess =
  createAction<GetPatientWellniteArtsResponse>(
    'GetPatientWellniteArtsGallerySuccess',
  );

export const getPatientWellniteArtsGalleryFailure = createAction<string>(
  'GetPatientWellniteArtsGalleryFailure',
);

export const getPatientLikedWellniteArts = createAction(
  'GetPatientLikedWellniteArtsGallery',
);

export const getPatientLikedWellniteArtsSuccess =
  createAction<GetPatientWellniteArtsResponse>(
    'GetPatientLikedWellniteArtsGallerySuccess',
  );

export const getPatientLikedWellniteArtsFailure = createAction<string>(
  'GetPatientLikedWellniteArtsGalleryFailure',
);

export const searchWellniteArtsGallery =
  createAction<SearchWellniteArtsGalleryPayload>('SearchWellniteArtsGallery');

export const searchWellniteArtsGallerySuccess =
  createAction<GetPaginatedWellniteArtGalleryResponse>(
    'SearchWellniteArtsGallerySuccess',
  );

export const searchWellniteArtsGalleryFailure = createAction<string>(
  'SearchWellniteArtsGalleryFailure',
);

export const getWellniteArtForYou = createAction('GetWellniteArtForYou');

export const getWellniteArtForYouSuccess =
  createAction<GetWellniteArtForYouResponse>('GetWellniteArtForYouSuccess');

export const getWellniteArtForYouFailure = createAction<string>(
  'GetWellniteArtForYouFailure',
);

export const recordWellniteArtReaction =
  createAction<RecordWellniteArtReactionPayload>('RecordWellniteArtReaction');

export const recordWellniteArtSuccess = createAction<MemberProfile>(
  'LikeOrDislikeWellniteArtYouSuccess',
);

export const likeOrDislikeWellniteArtFailure = createAction<string>(
  'LikeOrDislikeWellniteArtFailure',
);

export const generateIndividualQuestion = createAction<
  GenerateIndividualQuestionArgs & {
    onSuccess?: (data: GeneratePostSessionQuestionnaireResponse) => void;
  }
>('generateIndividualQuestion');

export const generateIndividualQuestionSuccess =
  createAction<GeneratePostSessionQuestionnaireResponse>(
    'GenerateIndividualQuestionSuccess',
  );

export const generateIndividualQuestionFailure = createAction<string>(
  'GenerateIndividualQuestionFailure',
);

export const regenerateWellniteArtCaption = createAction<
  RegenerateWellniteArtCaptionArgs & {
    onSuccess?: (
      data: GenerateImageFromPostSessionQuestionnaireResponse,
    ) => void;
  }
>('RegenerateWellniteArtCaption');

export const regenerateWellniteArtCaptionSuccess =
  createAction<GenerateImageFromPostSessionQuestionnaireResponse>(
    'RegenerateWellniteArtCaptionSuccess',
  );

export const regenerateWellniteArtCaptionFailure = createAction<string>(
  'RegenerateWellniteArtCaptionFailure',
);

export const generateWellniteArtTitle = createAction<
  GenerateWellniteArtTitleArgs & {
    onSuccess?: (data: GenerateWellniteArtTitleResponse) => void;
  }
>('GenerateWellniteArtTitle');

export const generateWellniteArtTitleSuccess =
  createAction<GenerateWellniteArtTitleResponse>(
    'GenerateWellniteArtTitleSuccess',
  );

export const generateWellniteArtTitleFailure = createAction<string>(
  'GenerateWellniteArtTitleFailure',
);

export const getWellniteArtById = createAction<
  GetWellniteArtByIdArgs & {
    onSuccess?: (data: GetWellniteArtByIdResponse) => void;
  }
>('GetWellniteArtById');

export const getWellniteArtByIdSuccess =
  createAction<GetWellniteArtByIdResponse>('GetWellniteArtByIdSuccess');

export const getWellniteArtByIdFailure = createAction<string>(
  'GetWellniteArtByIdFailure',
);

export const getWellniteArtConditionTags = createAction<{
  wellniteArtId?: string;
  onSuccess?: (data: GetWellniteArtByIdResponse) => void;
}>('GetWellniteArtConditionTags');

export const getWellniteArtConditionTagsSuccess =
  createAction<GetWellniteArtConditionTagsResponse>(
    'GetWellniteArtConditionTagsSuccess',
  );

export const getWellniteArtConditionTagsFailure = createAction<string>(
  'GetWellniteArtConditionTagsFailure',
);

export const updateWellniteArtInfo = createAction<
  UpdateWellniteArtInfoArgs & {
    onSuccess?: (data: UpdateWellniteArtInfoResponse) => void;
  }
>('UpdateWellniteArtInfo');

export const updateWellniteArtInfoSuccess =
  createAction<UpdateWellniteArtInfoResponse>('UpdateWellniteArtInfoSuccess');

export const updateWellniteArtInfoFailure = createAction<string>(
  'UpdateWellniteArtInfoFailure',
);
