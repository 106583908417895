import {RootState} from 'app/rootReducer';

import {createSelector} from '@reduxjs/toolkit';

import {IntakeState} from './intakeSlice';

function getIntake(state: RootState): IntakeState {
  return state.intake;
}

export const selectIntakeStatus = createSelector(
  getIntake,
  intake => intake?.status,
);

export const selectIsNewIntakeMember = createSelector(
  getIntake,
  intake => intake?.isNewIntakeMember,
);

export const selectCurrentStep = createSelector(
  getIntake,
  intake => intake?.currentStep,
);

export const selectIntakeQuestions = createSelector(
  getIntake,
  intake => intake?.questions,
);

export const selectIntakeTherapyFormStatus = createSelector(
  getIntake,
  intake => intake?.therapyIntakeStatus,
);

export const selectIntakePartiallyCompleted = createSelector(
  getIntake,
  intake => intake?.hasPartiallyCompletedIntake,
);

export const selectIntakeCompleted = createSelector(
  getIntake,
  intake => intake?.hasCompletedIntake,
);
