/* eslint-disable max-lines-per-function */
import {useCallback, useEffect, useMemo, useState} from 'react';
import Toast from 'components/Basic/Toast';
import {format, isSameDay} from 'date-fns';
import dayjs from 'dayjs';
import {BookAppointmentSchema} from 'definitions/Yup';
import {
  appointmentActions,
  selectAvailability,
  selectProviderAvailabilityStatus,
  selectSlotAvailabilityStatus,
} from 'features/Appointment';
import {
  bookAppointment,
  bookOneTimeAppointment,
} from 'features/Appointment/Booking/bookingActions';
import {selectUserProfile} from 'features/User';
import {
  AppointmentDurations,
  AppointmentTypes,
  Availability,
  BookAppointmentData,
  MemberProfile,
  SliceStatus,
  UserAccountType,
} from 'interfaces';
import {Modifier} from 'react-day-picker';
import {
  Control,
  FieldErrorsImpl,
  useForm,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {BookingService} from 'services';
import {
  dateTimeStringCompatibility,
  isMember,
  isPrescriber,
  isProvider,
  isTherapist,
} from 'utils';
import * as Yup from 'yup';

import {yupResolver} from '@hookform/resolvers/yup';

import {getDirectBookingSelectedProvider} from '../features/DirectBooking/directBookingSelectors';

import {useError} from './useError';
import {useRequesting} from './useRequesting';

type OptionType = {label: string; value: string};

const now = new Date();
const nextDay = new Date(now);
nextDay.setDate(now.getDate() + 1);

export function useBooking(
  appointmentType: AppointmentTypes,
  duration: AppointmentDurations,
  member?: MemberProfile,
  selectedDate?: dayjs.Dayjs,
): {
  zones: OptionType[];
  time: {value: string; rawTime: string; disabled: boolean}[];
  selectedTime: string;
  memberState: string;
  providerId: string;
  providerCalendar: {
    availabilityDate: string;
    availabilityTimes: string[];
  }[];
  register: UseFormRegister<BookAppointmentData>;
  watch: any;
  errors: FieldErrorsImpl<BookAppointmentData>;
  disabledDays: Modifier | Modifier[];
  apiErrorMsg: string;
  isLoading: SliceStatus;
  bookingCharge: {
    status: SliceStatus;
    showModal: boolean;
    amount: number;
  };
  onSubmit: any;
  control: Control<BookAppointmentData>;
  setValue: UseFormSetValue<BookAppointmentData>;
  onConfirmBooking: () => void;
  closeExtraChargeModal: () => void;
  getProviderCalendarData: (date: Date, fetchByDate: boolean) => void;
  availability: Availability;
  providerLoadingStatus: SliceStatus;
  slotLoadingStatus: SliceStatus;
} {
  const [bookingCharge, setBookingCharge] = useState({
    status: SliceStatus.idle,
    showModal: false,
    amount: 0,
  });
  const [zones, setZones] = useState<OptionType[]>([]);
  const [timeArr, setTimeArr] = useState<
    {value: string; rawTime: string; disabled: boolean}[]
  >([]);
  const dispatch = useDispatch();

  const availability = useSelector(selectAvailability);
  const providerLoadingStatus = useSelector(selectProviderAvailabilityStatus);
  const slotLoadingStatus = useSelector(selectSlotAvailabilityStatus);
  const directBookingProvider = useSelector(getDirectBookingSelectedProvider);

  const history = useHistory();
  const user = useSelector(selectUserProfile);
  const {
    handleSubmit,
    register,
    control,
    formState: {errors},
    watch,
    setError,
    setValue,
  } = useForm<BookAppointmentData>({
    defaultValues: {
      appointmentType,
      providerId: directBookingProvider?.providerId ?? '',
      timezone: BookingService.isCountryOfResidenceRegion(
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        user!.countryOfResidence.code,
      )
        ? Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase()
        : BookingService.getCountryDefaultTimeZone(
            user!.countryOfResidence.code,
          ),
      date: nextDay,
      time: '',
      client: member?.patientId ?? '',
      state: '',
    },
    resolver: yupResolver(BookAppointmentSchema),
  });

  const [date, timezone, time] = watch(['date', 'timezone', 'time']);

  const providerId = watch('providerId') as string;
  const memberState = watch('state') as string;

  const email = isMember(user)
    ? user?.email
    : member?.email
    ? member?.email
    : '';

  // set default state
  useEffect(() => {
    if (isMember(user) && user.stateOfResidence) {
      setValue('state', user.stateOfResidence);
    } else if (member && isMember(member) && member.stateOfResidence) {
      // this fill state when provider choose a client
      setValue('state', member.stateOfResidence);
    }
  }, [user, member]);

  // set default provider Id
  useEffect(() => {
    if (providerLoadingStatus === SliceStatus.resolved) {
      let selectedProviderId = '';
      if (directBookingProvider?.userId) {
        selectedProviderId = directBookingProvider?.userId || '';
      } else if (
        isMember(user) &&
        appointmentType === AppointmentTypes.video_call_with_therapist &&
        user.therapistDetails
      ) {
        selectedProviderId = user.therapistDetails.therapistId;
      } else if (
        isMember(user) &&
        appointmentType === AppointmentTypes.doctor_consultation &&
        user.prescriberDetails
      ) {
        selectedProviderId = user.prescriberDetails.prescriberId;
      } else if (isTherapist(user)) {
        selectedProviderId = user.therapistId;
      } else if (isPrescriber(user)) {
        selectedProviderId = user.prescriberId;
      }

      // check if assigned provider is available in the available providers list
      if (Array.isArray(availability.providers)) {
        const isProviderAvailable = availability.providers.some(
          p =>
            p?.therapistId === selectedProviderId ||
            p?.prescriberId === selectedProviderId,
        );
        if (isProviderAvailable) setValue('providerId', selectedProviderId);
      }
    }
  }, [user, member, appointmentType, providerLoadingStatus]);

  const calendarId = useMemo(() => {
    if (isMember(user) && Array.isArray(availability?.providers)) {
      return availability.providers.find(
        p =>
          p?.therapistId === (watch('providerId') as string) ||
          p?.prescriberId === (watch('providerId') as string),
      )?.acuity.calendarId;
    } else if (isProvider(user)) {
      return user.acuity.calendarId;
    } else return directBookingProvider?.userId ?? '';
  }, [user, member, directBookingProvider, availability, providerId]);

  const appointmentTypeID = String(availability?.appointmentTypeId);
  const isLoading = useRequesting('appointment');

  const {apiError, resetAsyncError} = useError('appointment');

  const providerCalendar = availability?.slots
    ? availability?.slots[providerId]
    : [];

  const onBookAppoinment = (
    values: Yup.InferType<typeof BookAppointmentSchema>,
  ) => {
    const {error, datetime} = BookingService.getAppointmentDateTimeString(
      {
        time: values.time,
        timezone: values.timezone,
        date: values.date as Date,
      },
      providerCalendar,
    );

    if (datetime && calendarId) {
      const appointmentData = {
        appointmentDateTimeString: datetime,
        providerId: values.providerId,
        appointmentType: values.appointmentType as AppointmentTypes,
        patientTimezone: values.timezone,
        calendarId: Number(calendarId),
        appointmentTypeID,
      };

      const scheduleAppointmentByProvider = () => {
        setBookingCharge({...bookingCharge, status: SliceStatus.resolved});
        dispatch(
          bookAppointment({
            ...appointmentData,
            patientEmail: member!.email,
            providerFullName: user?.fullName,
            memberFullName: member?.fullName,
          }),
        );
      };

      const scheduleAppointmentByMember = () => {
        if (
          user?.subscriptionStatus.includes('not-subscribed') &&
          appointmentType !== 'chat_with_coach' &&
          /(commercial|public)/.test(
            user?.insuranceDetails?.companyType ?? '',
          ) &&
          Array.isArray(user?.patientCards) &&
          user?.patientCards.length > 0
        ) {
          dispatch(bookOneTimeAppointment(appointmentData));
        } else if (
          (user?.socialOnboardingExtraFlow === 'zocdoc' &&
            !/(mindfulness|medicalcare_plus_therapy|uninsured|medicalcare)/.test(
              user?.paymentPlan,
            )) ||
          user?.accountType === UserAccountType.scale
        ) {
          dispatch(bookOneTimeAppointment(appointmentData));
        } else {
          if (
            user?.subscriptionStatus.includes('not-subscribed') &&
            appointmentType !== 'chat_with_coach'
          ) {
            history.push({
              pathname: '/update-plan',
              state: {
                appointmentData,
              },
            });
          } else {
            dispatch(bookAppointment(appointmentData));
          }
        }
      };

      if (!user?.governmentID && appointmentType === 'chat_with_coach') {
        history.push({
          pathname: '/onboarding-medical-intake',
          state: {
            appointmentData,
          },
        });
        return;
      }

      if (isProvider(user)) {
        scheduleAppointmentByProvider();
      } else {
        scheduleAppointmentByMember();
      }
    }
    if (error)
      setError('date', {
        type: 'manual',
        message: error,
      });
  };

  const disabledDaysFn = useCallback(() => {
    if (
      providerId &&
      availability?.slots &&
      Object.keys(availability?.slots).length &&
      availability.slots[providerId]
    ) {
      return BookingService.getDisabledDays(
        availability.slots[providerId],
        isProvider(user),
      );
    } else {
      return [];
    }
  }, [availability, providerId]);

  const disabledDays = disabledDaysFn();

  useEffect(() => {
    if (
      availability?.slots &&
      Array.isArray(availability?.slots?.[providerId]) &&
      availability.slots[providerId].length
    ) {
      const currentDay = availability.slots[providerId].find(day => {
        const formattedDate = dateTimeStringCompatibility(day.availabilityDate);
        return isSameDay(new Date(formattedDate), date as Date);
      });
      const hours = BookingService.getAvailableTimeData(
        timezone,
        currentDay?.availabilityTimes || [],
      );
      setTimeArr(hours);
    } else {
      setTimeArr([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, timezone, duration, providerId, availability]);

  const getProviders = useCallback(
    (typeofAppointment: AppointmentTypes): void => {
      dispatch(
        appointmentActions.getAvailableProviders({
          appointmentType: typeofAppointment,
          duration,
          email,
          role: user!.role,
        }),
      );
    },
    [dispatch, email, duration],
  );

  const getProviderCalendarData = useCallback(
    (date: Date, fetchByDate: boolean): void => {
      if (providerId && calendarId) {
        dispatch(
          appointmentActions.getAvailableSlots({
            appointmentType,
            duration,
            yearAndMonth: format(date, 'yyyy-MM'),
            providerId,
            calendarId: Number(calendarId),
            ...(appointmentType &&
              appointmentType === AppointmentTypes.doctor_consultation && {
                state: memberState,
              }),
            ...(selectedDate &&
              fetchByDate && {
                day: Number(format(selectedDate.toDate(), 'dd')),
              }),
          }),
        );
      }
    },
    [dispatch, duration, memberState, providerId, calendarId, selectedDate],
  );

  useEffect(() => {
    const isValidUser =
      isMember(user) ||
      isTherapist(user) ||
      (memberState && isPrescriber(user));

    if (providerId && calendarId && isValidUser) {
      if (selectedDate) {
        setValue('date', selectedDate.toDate());
      }
      getProviderCalendarData(
        selectedDate?.toDate() ?? new Date(),
        !!selectedDate,
      );
    }
  }, [providerId, calendarId, user]);

  useEffect(() => {
    const isValidUser =
      isMember(user) ||
      isTherapist(user) ||
      (memberState && isPrescriber(user));

    if (providerId && calendarId && isValidUser) {
      getProviderCalendarData(selectedDate?.toDate() ?? new Date(), false);
    }
  }, [duration, memberState]);

  useEffect(() => {
    if (user && isMember(user) && email) {
      getProviders(appointmentType);
    }
  }, [appointmentType, getProviders, email, user]);

  useEffect(() => {
    let isSubscribed = true;
    const timezoneOptions = BookingService.getTimezonesArray(
      user!.countryOfResidence.code,
    ).map(zone => ({
      label: zone.item.text,
      value: zone.id,
    }));

    if (isSubscribed) {
      setZones(timezoneOptions);
    }
    return (): void => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    return (): void => {
      if (apiError) dispatch(resetAsyncError('appointment'));
    };
  }, [apiError, dispatch, resetAsyncError]);

  /**
   *
   */
  const onSubmit = handleSubmit(async values => {
    try {
      setBookingCharge({...bookingCharge, status: SliceStatus.pending});
      const res = await BookingService.getBookingCharge({
        role: user!.role,
        patientEmail: email,
        appointmentType,
        appointmentTypeID,
      });

      if (res.data.message.charge > 0) {
        setBookingCharge({
          ...bookingCharge,
          status: SliceStatus.resolved,
          amount: res.data.message.charge,
          showModal: true,
        });
      } else {
        setBookingCharge({
          ...bookingCharge,
          status: SliceStatus.resolved,
          showModal: false,
        });
        onBookAppoinment(values);
      }
    } catch (error) {
      setBookingCharge({
        ...bookingCharge,
        status: SliceStatus.rejected,
        showModal: false,
      });
      Toast({
        type: 'error',
        message: 'oops! something went wrong, please try booking again.',
      });
    }
  });

  const closeExtraChargeModal = () => {
    setBookingCharge({
      ...bookingCharge,
      showModal: false,
    });
  };

  const onConfirmBooking = () => {
    onBookAppoinment(watch());
    setBookingCharge({...bookingCharge, showModal: false});
  };

  return {
    zones,
    time: timeArr,
    onSubmit,
    errors,
    register,
    control,
    memberState,
    providerId,
    providerCalendar,
    disabledDays,
    selectedTime: time,
    apiErrorMsg: apiError,
    isLoading,
    watch,
    setValue,
    bookingCharge,
    onConfirmBooking,
    closeExtraChargeModal,
    getProviderCalendarData,
    availability,
    providerLoadingStatus,
    slotLoadingStatus,
  };
}
