import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {SuperCoachChatMessageStreamed} from 'interfaces';
import {Epic} from 'redux-observable';
import {
  catchError,
  concat,
  concatMap,
  filter,
  from,
  ignoreElements,
  mergeMap,
  Observable,
  of,
  tap,
} from 'rxjs';
import {SuperCoachService} from 'services/api';

import {superCoachActions} from './superCoachSlice';

export const sendSuperCoachOnboardingDataEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(superCoachActions.sendSuperCoachOnboardingData.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(SuperCoachService.sendSuperCoachOnboardingData(payload)).pipe(
        concatMap(() => {
          if (onSuccess) {
            onSuccess();
          }

          return of(
            superCoachActions.sendSuperCoachOnboardingDataSuccess(true),
          );
        }),
        catchError((message: string) =>
          concat(
            of(superCoachActions.sendSuperCoachOnboardingDataFailure(message)),
          ),
        ),
      ),
    ),
  );

export const sendSuperCoachOnboardingFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(superCoachActions.sendSuperCoachOnboardingDataFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const getSuperCoachChatMessagesEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(superCoachActions.getSuperCoachChatMessages.match),
    mergeMap(({payload}) =>
      from(SuperCoachService.getSuperCoachChatMessages(payload)).pipe(
        concatMap(result => {
          return of(
            superCoachActions.getSuperCoachChatMessagesSuccess(
              result.data.message,
            ),
          );
        }),
        catchError((message: string) =>
          concat(
            of(superCoachActions.getSuperCoachChatMessagesFailure(message)),
          ),
        ),
      ),
    ),
  );

export const getSuperCoachChatMessagesFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(superCoachActions.getSuperCoachChatMessagesFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const sendSuperCoachChatMessageEpic: Epic = action$ =>
  action$.pipe(
    filter(superCoachActions.sendSuperCoachChatMessage.match),
    mergeMap(
      ({payload}) =>
        new Observable(observer => {
          const eventSource =
            SuperCoachService.sendSuperCoachChatMessage(payload);
          let response = ``;

          eventSource.onmessage = event => {
            try {
              const parsedData: SuperCoachChatMessageStreamed = JSON.parse(
                event.data,
              );

              if (parsedData.done) {
                eventSource.close();

                // Close the connection immediately, but delay calling the action to let the animations finish
                setTimeout(() => {
                  observer.next(
                    superCoachActions.endSuperCoachChatMessageStreamChunk(),
                  );
                  observer.complete();
                }, response.length * 10);
                return;
              }

              if (parsedData.text) {
                response += parsedData.text;
                observer.next(
                  superCoachActions.receiveSuperCoachChatMessageStreamChunk({
                    text: parsedData.text,
                  }),
                );
              }
            } catch (error) {
              observer.next(
                superCoachActions.sendSuperCoachChatMessageFailure(
                  'Error parsing event data',
                ),
              );
            }
          };

          eventSource.onerror = () => {
            observer.next(
              superCoachActions.sendSuperCoachChatMessageFailure('API error'),
            );
            observer.complete();
            eventSource?.close();
          };

          return () => {
            eventSource?.close();
          };
        }),
    ),
  );

export const sendSuperCoachChatMessageFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(superCoachActions.sendSuperCoachChatMessageFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const superCoachEpics = [
  sendSuperCoachOnboardingDataEpic,
  sendSuperCoachOnboardingFailureEpic,
  getSuperCoachChatMessagesEpic,
  getSuperCoachChatMessagesFailureEpic,
  sendSuperCoachChatMessageEpic,
  sendSuperCoachChatMessageFailureEpic,
];
