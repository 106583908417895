import React, {FC, ReactNode} from 'react';
import classNames from 'classnames';
import {DesktopHeader} from 'components';
import MemberBookingBtn from 'features/Appointment/Booking/MemberBookingBtn';
import {MobileTabletHeader} from 'features/Provider/Calendar2/Components/Headers/MobileTabletHeader';
import {selectUserProfile} from 'features/User';
import {AppointmentTypes} from 'interfaces';
import {Helmet} from 'react-helmet-async';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {isUserFromUnitedStates} from 'utils';

import {BookButton} from './BookButton';

/**
 *
 * @description this component also handles the browser title
 * @param {string} pageTitle
 * @param {string} title
 * @param {string} classes
 * @param {string} headerClasses
 * @param {string} participantId
 * @param {boolean} backButton
 * @param {() => void} backButtonFn
 */
export const MemberHeader: FC<{
  pageTitle: string;
  title: string;
  classes?: string;
  headerClasses?: string;
  mobileTitle?: string;
  participantId?: string;
  backButton?: boolean;
  backButtonFn?: () => void;
  actionButton?: ReactNode;
}> = ({
  pageTitle,
  title,
  mobileTitle,
  classes,
  headerClasses,
  participantId,
  backButton = false,
  backButtonFn,
  actionButton,
}) => {
  const history = useHistory();
  const user = useSelector(selectUserProfile);
  const handleShowBookingModal = (
    onSetSession: (sessionType: AppointmentTypes) => void,
  ) => {
    if (user?.hasCompletedTherapyIntake) {
      onSetSession(AppointmentTypes.video_call_with_therapist);
    } else {
      history.push('/onboarding-lobby/therapy');
    }
  };

  if (actionButton !== null) {
    actionButton = isUserFromUnitedStates(user) ? (
      <MemberBookingBtn />
    ) : (
      <MemberBookingBtn
        type="custom"
        cb={onSetSession => (
          <BookButton
            setShowBookingModal={() => handleShowBookingModal(onSetSession)}
          />
        )}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      {/* Mobile and Tablet */}
      <MobileTabletHeader
        headerTitle={mobileTitle ?? title}
        classes={classNames({hidden: participantId})}
        actionButton={actionButton}
        backButtonFn={backButtonFn}
      />

      {/* Desktop */}
      <DesktopHeader
        classes={`!pt-3 !pb-2 border-b shrink-0 !h-[88px] ${classes}`}
        headerTitle={title}
        headerClasses={`!text-[22px] !leading-tight font-semibold pl-6 font-inter ${headerClasses}`}
        backButton={backButton}
        backButtonFn={backButtonFn}
        actionButton={actionButton}
      />
    </>
  );
};
