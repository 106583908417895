import React, {FC, Fragment} from 'react';
import {LoadingSpinner} from 'components';

import {Dialog, Transition} from '@headlessui/react';

type Props = {
  className?: string;
  height?: number;
  type?: 'Hearts' | 'Oval' | 'ThreeDots';
  color?: string;
};

export const FullModalLoader: FC<Props> = ({
  color = '#315eff',
  className,
  height = 40,
  type = 'Oval',
}) => {
  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-999" onClose={() => {}}>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`${className} w-screen h-screen transform overflow-hidden bg-white p-6 md:p-10 text-left align-middle shadow-xl transition-all`}
              >
                <LoadingSpinner height={height} type={type} color={color} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
