import React, {FC, useEffect, useMemo, useState} from 'react';
import {Tooltip} from 'components';
import {detachedSearchBarStyles} from 'components/Basic/Form/Select/styles';
import {FormSelect} from 'components/Basic/Form/V2';
import {format} from 'date-fns';
import dayjs from 'dayjs';
import {useBooking} from 'hooks';
import {OptionType, SliceStatus} from 'interfaces';
import {useTranslation} from 'react-i18next';

type Props = {
  label?: string;
  loadingStatus: SliceStatus;
  selectedDate?: dayjs.Dayjs;
  isProvider: boolean;
} & Partial<ReturnType<typeof useBooking>>;

const now = new Date();

const twentyFourHours = new Date().getTime() + 24 * 60 * 60 * 1000;

export const TimeSelect: FC<Props> = ({
  time,
  control,
  watch,
  loadingStatus,
  isProvider,
  ...props
}: Props) => {
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const {t} = useTranslation();

  const options = useMemo(
    () =>
      isProvider && time?.length
        ? time.reduce(
            (total: OptionType[], current) =>
              !current.disabled
                ? [
                    ...total,
                    {
                      value: current.value,
                      label: current.value,
                    },
                  ]
                : total,
            [],
          )
        : time?.length && now.toDateString() !== watch('date')?.toDateString()
        ? time.reduce(
            (total: OptionType[], current) =>
              Date.parse(`${current.rawTime}`) >= twentyFourHours &&
              !current.disabled
                ? [
                    ...total,
                    {
                      value: current.value,
                      label: current.value,
                    },
                  ]
                : total,
            [],
          )
        : [],
    [time, watch('date')?.toDateString()],
  );

  useEffect(() => {
    if (loadingStatus === SliceStatus.pending) {
      setOptionsLoaded(true);
    }
  }, [loadingStatus]);

  useEffect(() => {
    if (!props.selectedDate || !options.length || watch('time')) {
      return;
    }

    const date = format(props.selectedDate.toDate(), 'h:mm aaaa');
    const selectedHour = date.split(':')[0];
    const selectedAMPM = date.split(' ')[1];

    const value = time?.find(
      t => t.value.startsWith(selectedHour) && t.value.endsWith(selectedAMPM),
    )?.value;

    props.setValue!('time', value ?? '');
  }, [options]);

  if (!time) {
    return null;
  }

  return (
    <Tooltip
      content={t('noAvailableSlotsForDay')}
      placement="bottom"
      disabled={!!options.length || !optionsLoaded}
    >
      <div className="w-full overflow-hidden">
        <FormSelect
          control={control!}
          id="time"
          label={null}
          isLoading={loadingStatus === SliceStatus.pending}
          loadingMessage={() => 'Loading'}
          placeholder={
            optionsLoaded && !options.length
              ? t('noAvailableSlots')
              : t('availableSlots')
          }
          onChange={(value, setValue) => setValue(value?.value)}
          detachedSearchBar={true}
          isMulti={false}
          styles={{
            ...detachedSearchBarStyles,
            detachedButton: (isOpen: boolean, hasValue: boolean) => {
              const styles = detachedSearchBarStyles.detachedButton!(
                isOpen,
                hasValue,
              );
              return {
                ...styles,
                borderColor: isOpen ? '#2E62EC' : 'rgb(204 204 204)',
                color: hasValue ? 'black' : '#6B6B6B',
              };
            },
          }}
          extractValue={true}
          options={options}
        />
      </div>
    </Tooltip>
  );
};
