import React from 'react';
import classNames from 'classnames';
import {FieldValues} from 'react-hook-form';

import {
  AbstractFormItem,
  AbstractFormItemProps,
} from '../Base/AbstractFormItem';

type TextAreaProps<
  TFieldValues extends FieldValues,
  TContext extends object,
> = AbstractFormItemProps<TFieldValues, TContext> & {
  containerClasses?: string;
  classes?: string;
  bgColor?: string;
  rows?: number;
  id: string;
  placeholder?: string;
  maxChars?: number;
  activeBorder?: string;
  inputErrClasses?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
};

export function FormTextArea<
  TFieldValues extends FieldValues,
  TContext extends object,
>({
  id,
  placeholder,
  rows = 5,
  bgColor = 'white',
  activeBorder = 'primary',
  classes,
  inputErrClasses,
  maxChars,
  ...rest
}: TextAreaProps<TFieldValues, TContext>) {
  return (
    <AbstractFormItem
      id={id}
      {...rest}
      render={({field: {value, onChange}, fieldState: {error}}) => (
        <textarea
          placeholder={placeholder}
          id={id}
          aria-label={id}
          rows={rows}
          value={value}
          onChange={e => {
            onChange(e);
            if (typeof rest.onChange === 'function') {
              rest.onChange(e);
            }
          }}
          maxLength={maxChars}
          className={classNames(
            `appearance-none border border-gray-400 w-full p-3 leading-tight focus:outline-none rounded-md bg-${bgColor}`,
            {
              [`${inputErrClasses}`]: error,
              [`focus:border-${activeBorder}`]: !error,
            },
            classes,
          )}
        />
      )}
    />
  );
}
