import React, {FC, useCallback, useState} from 'react';
import {RootState} from 'app/rootReducer';
import {RouterPrompt, Tab} from 'components/Basic';
import {BookAppointment} from 'features/Appointment/Booking/BookAppointment';
import {selectMemberById} from 'features/Provider/Members/membersSelectors';
import PatientNotes from 'features/Provider/Notes';
import CreateNote from 'features/Provider/Notes/CreateNote';
import GenerateNote from 'features/Provider/Notes/GenerateNote';
import {getNotesList} from 'features/Provider/Notes/notesSelectors';
import UpdateMedication from 'features/Provider/Notes/updateMedications';
import {selectUserProfile} from 'features/User';
import {useTabs, useToggle} from 'hooks';
import {
  AppointmentTypes,
  NotesType,
  OpenAIResponse,
  StripePlanNames,
  UserRoles,
} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import NavigationPrompt from 'react-router-navigation-prompt';
import styled from 'styled-components';
import {isTherapist} from 'utils';

const BookingContainer = styled.div`
  width: 100%;
  height: calc(100vh - 8rem);
  overflow-y: auto;
`;

enum SlidePage {
  listNotes = 'listNotes',
  createAppointment = 'createAppointment',
  updateMedication = 'updateMedication',
}

enum CurrentNotesPage {
  viewNotes = 'viewNotes',
  createNotes = 'createNotes',
  generateNotes = 'generateNotes',
}

type Props = {
  patientId: string;
};

type NotesProps = {
  patientId: string;
  patientEmail: string;
};

const NotesTab: FC<NotesProps> = ({patientEmail, patientId}) => {
  const notes = useSelector(getNotesList);
  const user = useSelector(selectUserProfile);
  const {pathname} = useLocation();

  const [currentView, setCurrentView] = useState<CurrentNotesPage>(
    CurrentNotesPage.viewNotes,
  );
  const [selectedNote, setSelectNoteToEdit] = useState<NotesType | null>(null);

  // to handle the note create & CMS1500 Form submission
  const [isNoteCreated, setIsNoteCreated] = useState(false);
  const [noteError, setNoteError] = useState(
    '*Attention! Please complete the required fields on the notes section and click the done button before leaving the call',
  );

  const onNoteSelect = (note: NotesType) => {
    setSelectNoteToEdit(note);
    setCurrentView(CurrentNotesPage.createNotes);
  };

  const toggleSlidePage = useCallback(() => {
    if (currentView === CurrentNotesPage.viewNotes) {
      setCurrentView(CurrentNotesPage.createNotes);
      setSelectNoteToEdit(null);
    } else {
      setCurrentView(CurrentNotesPage.viewNotes);
    }
  }, [currentView]);

  const toggleGenerateNotePage = () => {
    if (currentView === CurrentNotesPage.generateNotes) {
      setCurrentView(CurrentNotesPage.viewNotes);
      setSelectNoteToEdit(null);
    } else setCurrentView(CurrentNotesPage.generateNotes);
  };

  const goToCreateNotePage = (content: OpenAIResponse) => {
    const generatedNote: NotesType = {
      wellniteNote: true,
      chiefComplaint: content.chiefComplaint,
      patientNotes: content.patientNotes,
      plan: content.plan,
      noteCreator: '',
      noteCreatorFullName: '',
      medications: [],
      noteId: '',
      prescribeMedication: false,
      pharmacyService: '',
      mentalHealthConditions: selectedNote?.mentalHealthConditions ?? [],
      icdCodes: [],
      roomMode: 'normal',
    };
    setSelectNoteToEdit(generatedNote);
    setCurrentView(CurrentNotesPage.createNotes);
  };

  const member = useSelector((state: RootState) => {
    return selectMemberById(state, patientId || '');
  });

  const {toggleOn, onToggleClick} = useToggle();

  const noteCreationDate = new Date(
    String(notes[0]?.createdAt || notes[0]?.date),
  ).toDateString();

  const currentDate = new Date(Date.now()).toDateString();

  const memberHasInsurance =
    member.socialOnboardingExtraFlow === 'zocdoc' &&
    member.paymentPlan === StripePlanNames.together;
  return (
    <>
      {(memberHasInsurance && isTherapist(user)) ||
      noteError.includes('ICD Codes') ? (
        <>
          <NavigationPrompt
            when={
              isNoteCreated && noteCreationDate === currentDate && !!noteError
            }
          >
            {({onCancel, onConfirm, isActive}) => {
              // if (isActive && currentView !== CurrentNotesPage.createNotes) {
              //     setCurrentView(CurrentNotesPage.createNotes)
              // }
              return (
                <RouterPrompt
                  show={isActive && pathname === '/video-call/embed/wb'}
                  cancel={onCancel}
                  confirm={onConfirm}
                  message={noteError}
                />
              );
            }}
          </NavigationPrompt>

          {toggleOn ? (
            <RouterPrompt
              show={isNoteCreated && pathname === '/video-call/embed/wb'}
              cancel={onToggleClick}
              confirm={onToggleClick}
              message={noteError}
            />
          ) : null}
        </>
      ) : null}

      {currentView === CurrentNotesPage.viewNotes ? (
        <PatientNotes
          togglePage={toggleSlidePage}
          toggleGenerateNotePage={toggleGenerateNotePage}
          patientEmail={patientEmail}
          isInVideoCall={true}
          onNoteSelect={onNoteSelect}
        />
      ) : currentView === CurrentNotesPage.createNotes ? (
        <CreateNote
          togglePage={toggleSlidePage}
          patientId={patientId}
          memberEmail={patientEmail}
          selectedNote={selectedNote}
          isInVideoCall={true}
          setIsNoteCreated={setIsNoteCreated}
          isNoteCreated={isNoteCreated}
          setNoteError={setNoteError}
          onToggleClick={onToggleClick}
          setSelectNoteToEdit={setSelectNoteToEdit}
          memberHasInsurance={memberHasInsurance}
        />
      ) : (
        currentView === CurrentNotesPage.generateNotes && (
          <GenerateNote
            goToCreatedNote={goToCreateNotePage}
            setSelectedNote={setSelectNoteToEdit}
            member={member}
          />
        )
      )}
    </>
  );
};

export const ProviderVideoRightNavigation: FC<Props> = ({patientId}) => {
  const {tab, onTabClick} = useTabs<SlidePage>(SlidePage.listNotes);
  const {t} = useTranslation();

  const user = useSelector(selectUserProfile);
  const handleAppointmentType = () => {
    if (isTherapist(user)) {
      return AppointmentTypes.video_call_with_therapist;
    } else {
      return AppointmentTypes.doctor_consultation;
    }
  };
  const member = useSelector((state: RootState) => {
    return selectMemberById(state, patientId);
  });

  return (
    <section className="relative h-full">
      <section className="border-b border-t">
        <nav className="-mb-px flex">
          <Tab
            onClick={() => {
              onTabClick(SlidePage.listNotes);
            }}
            width="1/3"
            current={tab === SlidePage.listNotes}
          >
            {t('notes')}
          </Tab>
          <Tab
            onClick={() => {
              onTabClick(SlidePage.createAppointment);
            }}
            width="1/3"
            current={tab === SlidePage.createAppointment}
          >
            {t('appointments')}
          </Tab>
          {user?.role === UserRoles.prescriber ? (
            <Tab
              onClick={() => {
                onTabClick(SlidePage.updateMedication);
              }}
              width="1/3"
              current={tab === SlidePage.updateMedication}
            >
              {t('medications')}
            </Tab>
          ) : null}
        </nav>
      </section>
      <section>
        {tab === SlidePage.listNotes ? (
          <NotesTab patientEmail={member?.email ?? ''} patientId={patientId} />
        ) : tab === SlidePage.updateMedication ? (
          <UpdateMedication patientId={patientId} />
        ) : (
          <section className="overflow-y-auto h-full">
            <BookingContainer>
              <BookAppointment
                appointmentType={handleAppointmentType()}
                member={member}
              />
            </BookingContainer>
          </section>
        )}
      </section>
    </section>
  );
};
