import {EndPoints, HttpMethods} from 'interfaces';
import {
  GetMinorConsentRequest,
  GetMinorConsentResponse,
  SetMinorConsentDataRequest,
  SetMinorConsentResponse,
} from 'interfaces/MinorConsent.types';
import {Response} from 'redaxios';
import {requestHandler} from 'services/api';
import {unwrapAPIError} from 'utils';

const getMinorConsentData = async ({
  patientId,
}: GetMinorConsentRequest): Promise<
  Response<{message: GetMinorConsentResponse}>
> => {
  try {
    const res = await requestHandler<{
      message: GetMinorConsentResponse;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.MinorConsentData}/${patientId}` as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const setMinorConsentData = async ({
  patientId,
  formData,
}: SetMinorConsentDataRequest): Promise<
  Response<{message: SetMinorConsentResponse}>
> => {
  try {
    const res = await requestHandler<
      {
        message: SetMinorConsentResponse;
      },
      typeof formData
    >({
      method: HttpMethods.POST,
      url: `${EndPoints.MinorConsentData}/${patientId}` as unknown as EndPoints,
      data: formData,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const MinorConsentService = {
  getMinorConsentData,
  setMinorConsentData,
};
