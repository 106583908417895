import {useCallback, useEffect, useState} from 'react';
import {checkIsSameDate, getDifferenceInMinutes} from 'utils';

export const useCountdown = (
  targetDate: string,
  countdownDuration: number = 5,
) => {
  const [countdownDate, setCountdownDate] = useState<number | null>(null);
  const [timeLeft, setTimeLeft] = useState<number>(0);

  // time in between the pointed time
  const isCountdownTime = useCallback(
    (targetDate: string, currentDate: Date, duration: number) => {
      const getTargetDate = new Date(targetDate);
      const isSameDate = checkIsSameDate(
        getTargetDate.toISOString(),
        currentDate.toISOString(),
      );
      const differenceInMinutes = getDifferenceInMinutes(getTargetDate);
      const isTimeInBetween =
        differenceInMinutes >= 0 && differenceInMinutes <= duration;
      return isSameDate && isTimeInBetween;
    },
    [],
  );

  // Calculate the time left
  const calculateTimeLeft = useCallback(() => {
    if (!countdownDate) return 0;
    return Math.max(countdownDate - new Date().getTime(), 0);
  }, [countdownDate]);

  // responsible for setting the countdown date within the time-range
  useEffect(() => {
    if (!targetDate) return;

    const committedDate = new Date(targetDate);
    committedDate.setMinutes(committedDate.getMinutes() + countdownDuration);

    const timeintervalId = setInterval(() => {
      const currentDate = new Date();
      if (isCountdownTime(targetDate, currentDate, countdownDuration)) {
        setCountdownDate(committedDate.getTime());
      }
    }, 1000);

    return () => clearInterval(timeintervalId);
  }, [targetDate, countdownDuration, isCountdownTime]);

  // responsible for counting the time
  useEffect(() => {
    if (!countdownDate) return;
    setTimeLeft(calculateTimeLeft());

    if (calculateTimeLeft() <= 0) return;
    const timeInterval = setInterval(
      () => setTimeLeft(calculateTimeLeft()),
      1000,
    );

    return () => clearInterval(timeInterval);
  }, [countdownDate, calculateTimeLeft]);

  // Convert milliseconds to days, hours, minutes, and seconds
  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
  const seconds = Math.floor((timeLeft / 1000) % 60);

  // Ensure two-digit formatting
  const formatTime = (time: number) => time.toString().padStart(2, '0');

  return {
    days: formatTime(days),
    hours: formatTime(hours),
    minutes: formatTime(minutes),
    seconds: formatTime(seconds),
    isCountingDown: !!countdownDate && timeLeft > 0,
  };
};
