import {RootState} from 'app/rootReducer';
import {SliceStatus} from 'interfaces';

import {createSelector} from '@reduxjs/toolkit';

import {SuperCoachState} from './superCoachSlice';

function getSuperCoach(state: RootState): SuperCoachState {
  return state.superCoach;
}

export const selectSuperCoachMessages = createSelector(
  getSuperCoach,
  superCoach => superCoach.messages,
);

export const selectSuperCoachPagination = createSelector(
  getSuperCoach,
  superCoach => superCoach.pagination,
);

export const selectSuperCoachMessagesStatusIsLoading = createSelector(
  getSuperCoach,
  superCoach => superCoach.getMessagesStatus === SliceStatus.pending,
);

export const selectSendSuperCoachSendChatMessageIsLoading = createSelector(
  getSuperCoach,
  superCoach => superCoach.sendChatMessageStatus === SliceStatus.pending,
);

export const selectSuperCoachOnboarded = createSelector(
  getSuperCoach,
  superCoach => superCoach.onboarded,
);

export const selectSendSuperCoachOnboardingDataIsLoading = createSelector(
  getSuperCoach,
  superCoach => superCoach.sendSuperCoachDataStatus === SliceStatus.pending,
);
