import {wellniteArtEpics} from './Experiences/WellniteArt/wellniteArtEpics';
import {SocialOnboardingEpics} from './Onboarding/Insurance/SocialOnboardingEpics';
import {minorConsentEpics} from './Onboarding/MinorConsent/minorConsentEpics';
import {phoneVerificationEpics} from './Onboarding/PhoneVerification/phoneVerificationEpics';
import {memberProfileEpics} from './Profile/profileEpics';
import {memberMarketingChannelEpic} from './Thanks/marketingChannelEpic';

export const memberEpics = [
  wellniteArtEpics,
  memberProfileEpics,
  memberMarketingChannelEpic,
  phoneVerificationEpics,
  SocialOnboardingEpics,
  minorConsentEpics,
].flatMap(epic => epic);
