import React from 'react';

import {SvgIcon} from './SvgIcon';

export const SmilingFaceIcon: SvgIcon = ({
  width = 85,
  height = 85,
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 85 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
  >
    <path
      d="M0 42.4341C0 18.9984 18.9984 0 42.4341 0C65.8697 0 84.8681 18.9984 84.8681 42.4341C84.8681 65.8697 65.8697 84.8681 42.4341 84.8681C18.9984 84.8681 0 65.8697 0 42.4341Z"
      fill="#FFBF32"
    />
    <mask
      id="mask0_9704_712"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="85"
      height="85"
    >
      <path
        d="M0 42.4341C0 18.9984 18.9984 0 42.4341 0C65.8697 0 84.8681 18.9984 84.8681 42.4341C84.8681 65.8697 65.8697 84.8681 42.4341 84.8681C18.9984 84.8681 0 65.8697 0 42.4341Z"
        fill="#FFEBBE"
      />
    </mask>
    <g mask="url(#mask0_9704_712)">
      <g filter="url(#filter0_f_9704_712)">
        <circle
          cx="8.99413"
          cy="48.6592"
          r="16.374"
          fill="#ED82B3"
          fillOpacity="0.8"
        />
      </g>
      <g filter="url(#filter1_f_9704_712)">
        <circle
          cx="75.874"
          cy="48.6592"
          r="16.374"
          fill="#ED82B3"
          fillOpacity="0.8"
        />
      </g>
    </g>
    <path
      d="M38.881 37.6602C38.881 32.6551 34.8235 28.5977 29.8184 28.5977C24.8133 28.5977 20.7559 32.6551 20.7559 37.6602"
      stroke="#2F2F2F"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M63.7872 37.6602C63.7872 32.6551 59.7298 28.5977 54.7247 28.5977C49.7196 28.5977 45.6621 32.6551 45.6621 37.6602"
      stroke="#2F2F2F"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M12.4531 40.5898C12.4531 40.5898 16.7826 56.7332 41.9725 56.7332C67.1623 56.7332 71.4918 40.5898 71.4918 40.5898"
      stroke="#2F2F2F"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <defs>
      <filter
        id="filter0_f_9704_712"
        x="-41.3799"
        y="-1.71484"
        width="100.748"
        height="100.746"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="17"
          result="effect1_foregroundBlur_9704_712"
        />
      </filter>
      <filter
        id="filter1_f_9704_712"
        x="25.5"
        y="-1.71484"
        width="100.748"
        height="100.746"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="17"
          result="effect1_foregroundBlur_9704_712"
        />
      </filter>
    </defs>
  </svg>
);
