import React, {FC, ReactNode, Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import {Dialog} from '@headlessui/react';

import {CloseIcon} from '../Basic';

import {DashboardLayoutV2} from './DashboardLayoutV2';

export const ExperiencesLayout: FC<{
  children: ReactNode[] | ReactNode;
}> = ({children}) => {
  const {t} = useTranslation();

  const history = useHistory();
  const goToDashboard = () => {
    history.push('/dashboard');
  };

  const goBack = () => {
    history.push('/experiences');
  };

  const isHome = history.location.pathname === '/experiences';

  return (
    <DashboardLayoutV2>
      <Dialog open={true} onClose={() => {}}>
        <div
          className="fixed inset-0 flex md:items-center justify-center z-50 w-full  bg-white overflow-scroll"
          id="scrollableDiv"
        >
          <Dialog.Panel className="mx-auto w-full h-full bg-white relative flex flex-col items-start justify-start max-w-[1500px]">
            <div className="fixed top-0 p-10 max-w-[1500px] w-full flex flex-row justify-between">
              {isHome ? null : (
                <button
                  className="left-0 cursor-pointer text-lg flex items-center outline-none"
                  onClick={goBack}
                >
                  {t('experiences.back')}
                </button>
              )}
              {isHome ? null : (
                <span className="right-0 hidden xs:flex text-lg items-center ml-auto">
                  {t('experiences.wellniteExperiences')}
                </span>
              )}
              <button
                className="right-0 hidden xs:flex underline cursor-pointer text-lg items-center ml-auto"
                onClick={() => goToDashboard()}
              >
                {t('experiences.exit')}
              </button>
              <button
                onClick={() => goToDashboard()}
                className="ml-auto flex xs:hidden justify-center items-center w-10 group z-999 outline-none"
              >
                <CloseIcon
                  strokeColor="text-black group-hover:text-gray-500 -mr-3"
                  strokeWidth={40}
                  classes="w-8 h-8"
                />
              </button>
            </div>
            <div className="w-full max-w-[1280px] mx-auto py-8 px-4">
              <Suspense>{children}</Suspense>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </DashboardLayoutV2>
  );
};
