import {UserRoles} from 'interfaces';
import {Response} from 'redaxios';
import {unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

export interface SendReferralLinkToContactsData {
  role: UserRoles;
  emails: string[];
  phones: string[];
}

const getReferralData = async (
  email: string,
): Promise<
  Response<{
    message: {
      referralCode: string;
      referralCount: string;
      referralCoinsTogether: string;
    };
  }>
> => {
  try {
    const res = await requestHandler<{
      message: {
        referralCode: string;
        referralCount: string;
        referralCoinsTogether: string;
      };
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetReferralData}/${email}` as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const postMarketingChannel = async (data: {
  whereFrom: string;
}): Promise<Response<{message: string}>> => {
  try {
    const res = await requestHandler<
      {
        message: string;
      },
      typeof data
    >({
      method: HttpMethods.POST,
      url: EndPoints.SaveMarketingChannel,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const sendReferralLinkToContacts = async ({
  role,
  ...data
}: SendReferralLinkToContactsData): Promise<
  Response<{
    message: string;
  }>
> => {
  try {
    const apiData = {
      ...data,
      isReferralLink: true,
    };
    const res = await requestHandler<{
      message: string;
    }>({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.SendInviteLinkToContacts}` as unknown as EndPoints,
      data: apiData,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    throw errorValue;
  }
};

export const ReferralService = {
  getReferralData,
  postMarketingChannel,
  sendReferralLinkToContacts,
};
