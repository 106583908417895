import {updateInsuranceDetails} from 'features/Payment/CurrentPlan/currentPlanActions';
import {generateExpertSummarySuccess} from 'features/Provider/Marketing/WellniteContent/wellniteContentActions';
import {
  setDigitalPracticeSessionRate,
  setDigitalPracticeSessionRateFailure,
  setDigitalPracticeSessionRateSuccess,
} from 'features/Provider/Payments/paymentActions';
import {completeOnboardingSuccess} from 'features/Provider/Register/registerActions';
import {
  Appointment,
  Availability,
  AvailableProvider,
  CurrentUser,
  MemberProfile,
  OptionType,
  PrescriberProfile,
  ProviderProfile,
  ProviderRole,
  ReleaseNotesType,
  SliceStatus,
  TherapistProfile,
  TNotification,
  UserRoles,
} from 'interfaces';

import {
  ActionReducerMapBuilder,
  createAction,
  createSlice,
} from '@reduxjs/toolkit';

import {
  payLaterRequestSuccess,
  standardCheckoutChargeSuccess,
} from '../Appointment/Charge/chargeActions';
import {recordWellniteArtReaction} from '../Member/Experiences/WellniteArt/wellniteArtActions';
import {
  goToPreviousMinorConsentStep,
  setMinorConsentStep,
  updateMinorConsent,
  updateMinorConsentFailure,
  updateMinorConsentStep,
  updateMinorConsentStepFailure,
  updateMinorConsentStepSuccess,
  updateMinorConsentSuccess,
} from '../Member/Onboarding/MinorConsent/minorConsentActions';
import {
  requestOneTimePassword,
  requestOneTimePasswordFailure,
  requestOneTimePasswordSuccess,
  requestPhoneOTP,
  resetVerifyOneTimePasswordStatus,
  updatePhoneAndrequestOneTimePassword,
  updatePhoneAndrequestOneTimePasswordFailure,
  updatePhoneAndrequestOneTimePasswordSuccess,
  verifyOneTimePassword,
  verifyOneTimePasswordFailure,
  verifyOneTimePasswordSuccess,
} from '../Member/Onboarding/PhoneVerification/phoneVerificationActions';
import {
  createPaystackSubaccount,
  createPaystackSubaccountFailure,
  createPaystackSubaccountSuccess,
  updatePaystackSubaccount,
  updatePaystackSubaccountFailure,
  updatePaystackSubaccountSuccess,
} from '../Provider/DigitalPractice/Revenue/revenueActions';

import {
  login,
  loginFailure,
  loginSuccess,
  logoutOtherRole,
} from './Login/loginActions';
import {
  getEmployers,
  getEmployersFailure,
  getEmployersSuccess,
  outSideWellniteRegister,
  outSideWellniteRegisterFailure,
  outsideWellniteRegisterSuccess,
  resetSearchEmployers,
  searchEmployers,
  searchEmployersFailure,
  searchEmployersSuccess,
} from './Register/OutsideWellniteRegister/outsideWellniteRegisterActions';
import {
  register,
  registerFailure,
  registerSuccess,
} from './Register/registerActions';
import {
  changePassword,
  changePasswordFailure,
  changePasswordSuccess,
} from './ResetPassword/ChangePassword/changePasswordActions';
import {
  requestPasswordToken,
  requestPasswordTokenFailure,
  requestPasswordTokenSuccess,
} from './ResetPassword/RequestToken/requestPasswordTokenActions';
import {
  validatePasswordToken,
  validatePasswordTokenFailure,
  validatePasswordTokenSuccess,
} from './ResetPassword/ValidateToken/validatePasswordTokenActions';
import {
  deactivateChatFeature,
  deactivateChatFeatureFailure,
  deactivateChatFeatureSuccess,
  getReleaseNotes,
  getReleaseNotesFailure,
  getReleaseNotesSuccess,
  reactivateChatFeature,
  reactivateChatFeatureFailure,
  reactivateChatFeatureSuccess,
  setPreferredLanguage,
  updateProfileImage,
  updateReleaseNotes,
  updateReleaseNotesFailure,
  updateReleaseNotesSuccess,
} from './userActions';

const stateLoadingDone = createAction<{
  state: {
    user: {
      current: MemberProfile | TherapistProfile | PrescriberProfile | undefined;
      status: SliceStatus;
      logoutStatus: SliceStatus;
      checkSessionStatus: SliceStatus;
      paystackSubaccountStatus: SliceStatus;
      oneTimePasswordVerificationStatus: SliceStatus;
      error: string;
      pwaInstallEvt: null;
      notification: {
        messageType: 'success' | 'warning' | 'error' | 'none';
        message: string;
      };
      bootstrap: boolean;
      socialOnboardStage: number;
      releaseNotes: {
        status: SliceStatus;
        data: ReleaseNotesType;
      };
      employers: {
        data: {label: string; value: string}[];
        offset?: string;
        hasMore: boolean;
        status: SliceStatus;
      };

      searchedEmployers: {
        data: {label: string; value: string}[];
        offset?: string;
        hasMore: boolean;
        status: SliceStatus;
      };
      minorConsentStatus: SliceStatus;
      minorConsentStep: number;
    };
    notification: {
      entities: Record<string, TNotification>;
      ids: string[];
      error: string;
      status: SliceStatus;
      unreadCount: number;
      hasMore: boolean;
      nextPage: number | null;
      total: number;
    };
    appointment: {
      availabilitySlots: {
        slots: string[];
        loading: SliceStatus;
      };

      appointments: Appointment[];
      loading: SliceStatus;
      status: SliceStatus;
      error: string;

      availability: Availability;
      providersLoadingStatus: SliceStatus;
      slotsLoadingStatus: SliceStatus;

      // provider
      provider: {
        data: AvailableProvider;
        status: SliceStatus;
      };
    };
  };
  isCacheAvailable: boolean;
}>('StateLoadingDone');
const checkSession = createAction<UserRoles>('CheckSession');
const checkSessionSuccess = createAction('CheckSessionSuccess');
const logData = createAction<any>('logData');

const logout = createAction('Logout');
const logoutSuccess = createAction('LogoutSuccess');
const logoutFailure = createAction<string>('LogoutFailure');

const handleCountryMismatch = createAction<{
  patientEmail: string;
  providerEmail: string;
  providerRole: ProviderRole;
  actionAttempted: 'booking' | 'video call';
  role: UserRoles;
  cb: () => void;
}>('HandleCountryMismatch');
const handleCountryMismatchDetected = createAction(
  'handleCountryMismatchDetected',
);

export const setUser = createAction<
  MemberProfile | TherapistProfile | PrescriberProfile
>('SetUser');
export const resetUser = createAction<null>('ResetUser');

const setPwaInstallEvt = createAction<Event>('SetPwaInstallEvt');
const resetPwaInstallEvt = createAction<null>('ResetPwaInstallEvt');
const setAsyncError = createAction<{filter: string; message: string}>(
  'SetAsyncError',
);
const resetAsyncError = createAction<string>('ResetAsyncError');
const setNotification = createAction<{
  messageType: 'success' | 'warning' | 'error';
  message: string;
  navigateTo?: string;
}>('SetNotification');
const resetNotification = createAction<{
  messageType: 'none';
  message: '';
  navigateTo?: string;
}>('ResetNotification');

const setSocialOnboardStage = createAction<number>('setSocialOnboardStage');
export const updateSocialOnboardingData = createAction<MemberProfile>(
  'updateSocialOnboardingData',
);

const setPaypalDefaultCard = createAction<string>('setPaypalDefaultCard');
const setPlatformFeePercentage = createAction<number>(
  '_setPlatformFeePercentage',
);

const updateDpProvidersSessionRateForMember = createAction<{
  sessionRate: {min: number; max: number};
  providerRole: ProviderRole;
  providerId: string;
}>('updateDpProvidersSessionRateForMember');

type UserSliceState = {
  current: CurrentUser;
  pwaInstallEvt: Event | null;
  status: SliceStatus;
  logoutStatus: SliceStatus;
  checkSessionStatus: SliceStatus;
  paystackSubaccountStatus: SliceStatus;
  bootstrap: boolean;
  error: string;
  isCacheAvailable: boolean;
  notification: {
    messageType: 'success' | 'warning' | 'error' | 'none';
    message: string;
    navigateTo?: string;
  };
  socialOnboardStage: number;
  chatSettingsStatus: SliceStatus;
  releaseNotes: {
    status: SliceStatus;
    data: ReleaseNotesType;
  };

  employers: {
    data: OptionType[];
    offset?: string;
    hasMore: boolean;
    status: SliceStatus;
  };
  searchedEmployers: {
    data: OptionType[];
    offset?: string;
    hasMore: boolean;
    status: SliceStatus;
  };
  minorConsentStatus: SliceStatus;
  minorConsentStep: number;
};
const userSlice = createSlice({
  name: 'user',
  initialState: {
    current: null as CurrentUser,
    pwaInstallEvt: null,
    status: SliceStatus.idle,
    logoutStatus: SliceStatus.idle,
    checkSessionStatus: SliceStatus.idle,
    paystackSubaccountStatus: SliceStatus.idle,
    oneTimePasswordVerificationStatus: SliceStatus.idle,
    error: '',
    notification: {
      messageType: 'none',
      message: '',
      // buttonFn: () => {},
      navigateTo: '',
    } as {
      messageType: 'success' | 'warning' | 'error' | 'none';
      message: string;
      navigateTo?: string;
    },
    chatSettingsStatus: SliceStatus.idle,
    bootstrap: true,
    isCacheAvailable: false,
    socialOnboardStage: 1,
    releaseNotes: {
      status: SliceStatus.idle,
      data: [] as ReleaseNotesType,
    },

    employers: {
      data: [] as OptionType[],
      offset: '',
      hasMore: false,
      status: SliceStatus.idle,
    },
    searchedEmployers: {
      data: [] as OptionType[],
      offset: '',
      hasMore: false,
      status: SliceStatus.idle,
    },
    minorConsentStatus: SliceStatus.idle,
    minorConsentStep: 1,
  },
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<UserSliceState>) =>
    builder
      .addCase(stateLoadingDone, (state, action) => ({
        ...state,
        isCacheAvailable: action.payload.isCacheAvailable,
      }))
      .addCase(login, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(loginFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(loginSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(register, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(registerFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(registerSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(outSideWellniteRegister, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(outSideWellniteRegisterFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(outsideWellniteRegisterSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(requestPasswordToken, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(requestPasswordTokenFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(requestPasswordTokenSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(validatePasswordToken, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(validatePasswordTokenFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(validatePasswordTokenSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(changePassword, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(changePasswordFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(changePasswordSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(requestOneTimePassword, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(requestOneTimePasswordFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(requestOneTimePasswordSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(requestPhoneOTP, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(updatePhoneAndrequestOneTimePassword, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(updatePhoneAndrequestOneTimePasswordFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(updatePhoneAndrequestOneTimePasswordSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(updateInsuranceDetails, state => {
        if (typeof state.socialOnboardStage === 'number') {
          state.current!.insuranceDetails!.onboardStage = 3;
          state.socialOnboardStage = 3;
        }
      })
      .addCase(updateSocialOnboardingData, (state, action) => {
        if (state.current) {
          state.current = {
            ...state.current,
            ...action.payload,
          };
        }
      })
      .addCase(standardCheckoutChargeSuccess, (state, action) => {
        if (state.current) {
          state.current = {
            ...state.current,
            dueAppointments: state.current.dueAppointments.filter(
              (a: number) => a !== action.payload.message.appointmentID,
            ),
          };
        }
      })
      .addCase(recordWellniteArtReaction, (state, action) => {
        if (state.current) {
          const likedMagicArts = state.current.likedMagicArts ?? [];
          const liked = action.payload.reaction === 'like';
          state.current = {
            ...state.current,
            likedMagicArts: liked
              ? likedMagicArts.concat(action.payload.wellniteArtId)
              : likedMagicArts.filter(
                  (id: string) => id !== action.payload.wellniteArtId,
                ),
          };
        }
      })
      .addCase(payLaterRequestSuccess, (state, action) => {
        if (state.current) {
          state.current = {
            ...state.current,
            dueAppointments: action.payload.message.dueAppointments,
          };
        }
      })
      .addCase(verifyOneTimePassword, state => ({
        ...state,
        status: SliceStatus.pending,
        oneTimePasswordVerificationStatus: SliceStatus.idle,
      }))
      .addCase(verifyOneTimePasswordFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
        oneTimePasswordVerificationStatus: SliceStatus.rejected,
      }))
      .addCase(verifyOneTimePasswordSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
        oneTimePasswordVerificationStatus: SliceStatus.resolved,
      }))
      .addCase(resetVerifyOneTimePasswordStatus, state => ({
        ...state,
        oneTimePasswordVerificationStatus: SliceStatus.idle,
      }))
      .addCase(checkSession, state => ({
        ...state,
        checkSessionStatus: SliceStatus.pending,
      }))
      .addCase(setUser, (state, action) => ({
        ...state,
        current: action.payload,
        checkSessionStatus: SliceStatus.resolved,
        paystackSubaccountStatus: SliceStatus.resolved,
        minorConsentStep: action.payload.minorConsent?.step,
        socialOnboardStage: action.payload?.isPhoneVerified
          ? (action.payload?.insuranceDetails?.onboardStage > 4
              ? 4
              : action.payload?.insuranceDetails?.onboardStage) ?? 2
          : 1,
      }))
      .addCase(resetUser, (state, action) => ({
        ...state,
        current: action.payload,
      }))
      .addCase(setSocialOnboardStage, (state, action) => ({
        ...state,
        socialOnboardStage: action.payload,
      }))
      .addCase(setPaypalDefaultCard, (state, action) => ({
        ...state,
        current: {
          ...state.current!,
          paypalDefaultCard: action.payload,
        },
      }))
      .addCase(setPwaInstallEvt, (state, action) => ({
        ...state,
        pwaInstallEvt: action.payload,
      }))
      .addCase(resetPwaInstallEvt, (state, action) => ({
        ...state,
        pwaInstallEvt: action.payload,
      }))
      .addCase(setNotification, (state, action) => ({
        ...state,
        notification: action.payload,
      }))
      .addCase(resetNotification, (state, action) => ({
        ...state,
        notification: action.payload,
      }))
      .addCase(setAsyncError, (state, action) => ({
        ...state,
        error:
          action.payload.filter === 'user'
            ? action.payload?.message
            : state.error,
      }))
      .addCase(resetAsyncError, (state, action) => ({
        ...state,
        error: action.payload === 'user' ? '' : state.error,
      }))
      .addCase(reactivateChatFeature, state => ({
        ...state,
        chatSettingsStatus: SliceStatus.pending,
      }))
      .addCase(reactivateChatFeatureSuccess, (state, action) => ({
        ...state,
        current: action.payload,
        chatSettingsStatus: SliceStatus.resolved,
      }))
      .addCase(reactivateChatFeatureFailure, state => ({
        ...state,
        chatSettingsStatus: SliceStatus.rejected,
      }))
      .addCase(deactivateChatFeature, state => ({
        ...state,
        chatSettingsStatus: SliceStatus.pending,
      }))
      .addCase(deactivateChatFeatureSuccess, (state, action) => ({
        ...state,
        current: action.payload,
        chatSettingsStatus: SliceStatus.resolved,
      }))
      .addCase(deactivateChatFeatureFailure, state => ({
        ...state,
        chatSettingsStatus: SliceStatus.rejected,
      }))
      .addCase(logout, state => ({
        ...state,
        logoutStatus: SliceStatus.pending,
        checkSessionStatus: SliceStatus.rejected,
        paystackSubaccountStatus: SliceStatus.idle,
      }))
      .addCase(logoutSuccess, state => ({
        ...state,
        logoutStatus: SliceStatus.resolved,
      }))
      .addCase(getReleaseNotes, state => ({
        ...state,
        releaseNotes: {
          ...state.releaseNotes,
          status: SliceStatus.pending,
        },
      }))
      .addCase(getReleaseNotesSuccess, (state, action) => ({
        ...state,
        releaseNotes: {
          ...state.releaseNotes,
          data: action.payload,
          status: SliceStatus.resolved,
        },
      }))
      .addCase(getReleaseNotesFailure, state => ({
        ...state,
        releaseNotes: {
          ...state.releaseNotes,
          status: SliceStatus.rejected,
        },
      }))
      .addCase(updateReleaseNotes, state => ({
        ...state,
        releaseNotes: {
          ...state.releaseNotes,
          data: [],
          status: SliceStatus.resolved,
        },
      }))
      .addCase(getEmployers, state => {
        return {
          ...state,
          employers: {
            ...state.employers,
            status: SliceStatus.pending,
          },
        };
      })
      .addCase(getEmployersFailure, state => {
        return {
          ...state,
          employers: {
            ...state.employers,
            status: SliceStatus.rejected,
          },
        };
      })
      .addCase(getEmployersSuccess, (state, action) => {
        return {
          ...state,
          employers: {
            ...state.employers,
            hasMore: action.payload.data.hasMore,
            offset: action.payload.data.offset,
            data: [...state.employers.data, ...action.payload.data.result],
            status: SliceStatus.resolved,
          },
        };
      })
      .addCase(searchEmployers, state => {
        return {
          ...state,
          searchedEmployers: {
            ...state.searchedEmployers,
            status: SliceStatus.pending,
          },
        };
      })
      .addCase(searchEmployersFailure, state => {
        return {
          ...state,
          searchedEmployers: {
            ...state.searchedEmployers,
            status: SliceStatus.rejected,
          },
        };
      })
      .addCase(searchEmployersSuccess, (state, action) => {
        return {
          ...state,
          searchedEmployers: {
            ...state.searchedEmployers,
            hasMore: action.payload.data.hasMore,
            offset: action.payload.data.offset,
            data: [
              ...state.searchedEmployers.data,
              ...action.payload.data.result,
            ],
            status: SliceStatus.resolved,
          },
        };
      })
      .addCase(resetSearchEmployers, state => ({
        ...state,
        searchedEmployers: {
          hasMore: false,
          offset: '',
          data: [],
          status: SliceStatus.idle,
        },
        employers: {
          hasMore: false,
          offset: '',
          data: [],
          status: SliceStatus.idle,
        },
      }))
      .addCase(updateProfileImage, (state, action) => {
        if (state.current) state.current.image = action.payload;
      })
      .addCase(generateExpertSummarySuccess, (state, action) => {
        (state.current as ProviderProfile).profileInfo = {
          ...((state.current as ProviderProfile)?.profileInfo || {}),
          expertSummary: action.payload,
        };
      })
      .addCase(setPlatformFeePercentage, (state, action) => {
        return {
          ...state,
          current: {
            ...state.current,
            feePercentage: action.payload,
          } as TherapistProfile,
        };
      })
      .addCase(updateDpProvidersSessionRateForMember, (state, action) => {
        const {providerRole, providerId, ...rest} = action.payload;

        const field =
          providerRole === UserRoles.therapist
            ? 'therapistDetails'
            : 'prescriberDetails';

        const isProviderAssigned =
          providerRole === UserRoles.therapist
            ? state.current?.therapist_Id === providerId
            : state.current?.doctorId === providerId;

        if (isProviderAssigned && state.current && state.current[field]) {
          state.current[field] = {
            ...state.current[field],
            sessionRate: {
              ...state.current[field].sessionRate,
              digitalPractice: rest.sessionRate,
            },
          };
        }
      })
      .addCase(createPaystackSubaccount, state => ({
        ...state,
        paystackSubaccountStatus: SliceStatus.pending,
      }))
      .addCase(createPaystackSubaccountSuccess, (state, action) => ({
        ...state,
        paystackSubaccountStatus: SliceStatus.resolved,
        current: {
          ...state.current,
          paystackSubaccountDetails: action.payload,
        } as TherapistProfile,
      }))
      .addCase(createPaystackSubaccountFailure, state => ({
        ...state,
        paystackSubaccountStatus: SliceStatus.resolved,
      }))
      .addCase(updatePaystackSubaccount, state => ({
        ...state,
        paystackSubaccountStatus: SliceStatus.pending,
      }))
      .addCase(updatePaystackSubaccountSuccess, (state, action) => ({
        ...state,
        paystackSubaccountStatus: SliceStatus.resolved,
        current: {
          ...state.current,
          paystackSubaccountDetails: action.payload,
        } as TherapistProfile,
      }))
      .addCase(updatePaystackSubaccountFailure, state => ({
        ...state,
        paystackSubaccountStatus: SliceStatus.resolved,
      }))
      .addCase(setDigitalPracticeSessionRate, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(setDigitalPracticeSessionRateFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(setDigitalPracticeSessionRateSuccess, (state, action) => {
        return {
          ...state,
          status: SliceStatus.resolved,
          current: {
            ...state.current,
            sessionRate: {
              digitalPractice: action.payload,
            },
          } as ProviderProfile,
        };
      })
      .addCase(completeOnboardingSuccess, (state, action) => ({
        ...state,
        current: {
          ...state.current,
          onboarding: {
            ...((state.current as ProviderProfile).onboarding ?? {}),
            onboardingStage: action.payload.onboarding?.onboardingStage,
          },
        } as ProviderProfile,
        status: SliceStatus.resolved,
      }))
      .addCase(updateMinorConsent, state => ({
        ...state,
        minorConsentStatus: SliceStatus.pending,
      }))
      .addCase(updateMinorConsentFailure, state => ({
        ...state,
        minorConsentStatus: SliceStatus.rejected,
      }))
      .addCase(updateMinorConsentSuccess, (state, action) => ({
        ...state,
        minorConsentStatus: SliceStatus.resolved,
        minorConsentStep:
          action.payload.message.minorConsent?.step ||
          state.current?.minorConsent.step,
      }))
      .addCase(updateMinorConsentStep, state => ({
        ...state,
        minorConsentStatus: SliceStatus.pending,
      }))
      .addCase(updateMinorConsentStepFailure, state => ({
        ...state,
        minorConsentStatus: SliceStatus.rejected,
      }))
      .addCase(updateMinorConsentStepSuccess, (state, action) => ({
        ...state,
        minorConsentStatus: SliceStatus.resolved,
        minorConsentStep:
          action.payload.message.minorConsent?.step ||
          state.current?.minorConsent.step,
      }))
      .addCase(goToPreviousMinorConsentStep, state => ({
        ...state,
        minorConsentStep: state.minorConsentStep - 1,
      }))
      .addCase(setMinorConsentStep, (state, action) => ({
        ...state,
        minorConsentStep: action.payload,
      }))
      .addDefaultCase(state => state),
});

export const {reducer: userReducer, name: userReducerName} = userSlice;

export type TUserActions =
  | ReturnType<typeof setUser>
  | ReturnType<typeof resetUser>
  | ReturnType<typeof setSocialOnboardStage>
  | ReturnType<typeof setPaypalDefaultCard>
  | ReturnType<typeof setPwaInstallEvt>
  | ReturnType<typeof resetPwaInstallEvt>
  | ReturnType<typeof setAsyncError>
  | ReturnType<typeof resetAsyncError>
  | ReturnType<typeof setNotification>
  | ReturnType<typeof resetNotification>
  | ReturnType<typeof login>
  | ReturnType<typeof loginSuccess>
  | ReturnType<typeof loginFailure>
  | ReturnType<typeof logout>
  | ReturnType<typeof logoutOtherRole>
  | ReturnType<typeof logoutFailure>
  | ReturnType<typeof register>
  | ReturnType<typeof registerSuccess>
  | ReturnType<typeof registerFailure>
  | ReturnType<typeof outSideWellniteRegister>
  | ReturnType<typeof outsideWellniteRegisterSuccess>
  | ReturnType<typeof outSideWellniteRegisterFailure>
  | ReturnType<typeof requestPasswordToken>
  | ReturnType<typeof requestPasswordTokenSuccess>
  | ReturnType<typeof requestPasswordTokenFailure>
  | ReturnType<typeof requestOneTimePassword>
  | ReturnType<typeof requestOneTimePasswordSuccess>
  | ReturnType<typeof requestOneTimePasswordFailure>
  | ReturnType<typeof requestPhoneOTP>
  | ReturnType<typeof updatePhoneAndrequestOneTimePassword>
  | ReturnType<typeof updatePhoneAndrequestOneTimePasswordSuccess>
  | ReturnType<typeof updatePhoneAndrequestOneTimePasswordFailure>
  | ReturnType<typeof updateInsuranceDetails>
  | ReturnType<typeof updateSocialOnboardingData>
  | ReturnType<typeof verifyOneTimePassword>
  | ReturnType<typeof verifyOneTimePasswordSuccess>
  | ReturnType<typeof verifyOneTimePasswordFailure>
  | ReturnType<typeof resetVerifyOneTimePasswordStatus>
  | ReturnType<typeof validatePasswordToken>
  | ReturnType<typeof validatePasswordTokenSuccess>
  | ReturnType<typeof validatePasswordTokenFailure>
  | ReturnType<typeof changePassword>
  | ReturnType<typeof changePasswordSuccess>
  | ReturnType<typeof changePasswordFailure>
  | ReturnType<typeof stateLoadingDone>
  | ReturnType<typeof checkSession>
  | ReturnType<typeof checkSessionSuccess>
  | ReturnType<typeof logData>
  | ReturnType<typeof logoutSuccess>
  | ReturnType<typeof getReleaseNotes>
  | ReturnType<typeof getReleaseNotesSuccess>
  | ReturnType<typeof getReleaseNotesFailure>
  | ReturnType<typeof updateReleaseNotes>
  | ReturnType<typeof updateReleaseNotesSuccess>
  | ReturnType<typeof updateReleaseNotesFailure>
  | ReturnType<typeof getEmployers>
  | ReturnType<typeof getEmployersSuccess>
  | ReturnType<typeof getEmployersFailure>
  | ReturnType<typeof searchEmployers>
  | ReturnType<typeof searchEmployersSuccess>
  | ReturnType<typeof searchEmployersFailure>
  | ReturnType<typeof setPreferredLanguage>
  | ReturnType<typeof setPlatformFeePercentage>
  | ReturnType<typeof setDigitalPracticeSessionRate>
  | ReturnType<typeof setDigitalPracticeSessionRateFailure>
  | ReturnType<typeof setDigitalPracticeSessionRateSuccess>
  | ReturnType<typeof updateDpProvidersSessionRateForMember>
  | ReturnType<typeof handleCountryMismatch>
  | ReturnType<typeof handleCountryMismatchDetected>
  | ReturnType<typeof updateMinorConsent>
  | ReturnType<typeof updateMinorConsentSuccess>
  | ReturnType<typeof updateMinorConsentFailure>
  | ReturnType<typeof goToPreviousMinorConsentStep>
  | ReturnType<typeof setMinorConsentStep>
  | ReturnType<typeof updateMinorConsentStep>
  | ReturnType<typeof updateMinorConsentStepSuccess>
  | ReturnType<typeof updateMinorConsentStepFailure>;

export const userActions = {
  setUser,
  resetUser,
  setPwaInstallEvt,
  resetPwaInstallEvt,
  setAsyncError,
  resetAsyncError,
  setNotification,
  resetNotification,
  login,
  loginSuccess,
  loginFailure,
  logout,
  logoutSuccess,
  logoutOtherRole,
  logoutFailure,
  register,
  registerSuccess,
  registerFailure,
  outSideWellniteRegister,
  outsideWellniteRegisterSuccess,
  outSideWellniteRegisterFailure,
  stateLoadingDone,
  checkSession,
  checkSessionSuccess,
  logData,
  requestPasswordToken,
  requestPasswordTokenFailure,
  requestPasswordTokenSuccess,
  validatePasswordToken,
  validatePasswordTokenSuccess,
  validatePasswordTokenFailure,
  changePassword,
  changePasswordSuccess,
  changePasswordFailure,
  requestOneTimePassword,
  requestOneTimePasswordSuccess,
  requestOneTimePasswordFailure,
  requestPhoneOTP,
  updatePhoneAndrequestOneTimePassword,
  updatePhoneAndrequestOneTimePasswordFailure,
  updatePhoneAndrequestOneTimePasswordSuccess,
  verifyOneTimePassword,
  verifyOneTimePasswordSuccess,
  verifyOneTimePasswordFailure,
  resetVerifyOneTimePasswordStatus,
  updateInsuranceDetails,
  updateSocialOnboardingData,
  setSocialOnboardStage,
  setPaypalDefaultCard,
  getReleaseNotes,
  getReleaseNotesFailure,
  getReleaseNotesSuccess,
  updateReleaseNotes,
  updateReleaseNotesFailure,
  updateReleaseNotesSuccess,
  getEmployers,
  getEmployersSuccess,
  getEmployersFailure,
  searchEmployers,
  searchEmployersSuccess,
  searchEmployersFailure,
  resetSearchEmployers,
  updateProfileImage,
  setPreferredLanguage,
  setPlatformFeePercentage,
  setDigitalPracticeSessionRate,
  setDigitalPracticeSessionRateFailure,
  setDigitalPracticeSessionRateSuccess,
  updateDpProvidersSessionRateForMember,
  handleCountryMismatch,
  handleCountryMismatchDetected,
  updateMinorConsent,
  updateMinorConsentSuccess,
  updateMinorConsentFailure,
  goToPreviousMinorConsentStep,
  setMinorConsentStep,
  updateMinorConsentStep,
  updateMinorConsentStepFailure,
  updateMinorConsentStepSuccess,
};

export type UserState = ReturnType<typeof userReducer>;
