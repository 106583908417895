import React from 'react';
import styled, {css, keyframes} from 'styled-components';

import {SvgIconProps} from './SvgIcon';

export type SvgIcon = React.FC<SvgIconProps & {rotating?: boolean}>;

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
`;

const StyledSvg = styled.svg<{rotating: boolean}>`
  transform: rotate(90deg);
  ${({rotating}) =>
    rotating &&
    css`
      animation: ${spin} 1s linear infinite;
    `}
`;

export const RefreshIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  classes = '',
  title = 'Refresh',
  rotating = false,
}) => (
  <StyledSvg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={`stroke-current ${strokeColor} ${classes}`}
    rotating={rotating}
  >
    <title>{title}</title>
    <path stroke="none" d="M0 0h24v24H0z" />
    <path
      d="M20 11A8.1 8.1 0 004.5 9M4 5v4h4M4 13a8.1 8.1 0 0015.5 2m.5 4v-4h-4"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </StyledSvg>
);
