import React, {FC} from 'react';
import {RootState} from 'app/rootReducer';
import classNames from 'classnames';
import {isNonEmptyArrayStrings} from 'features/Video/Provider/MemberData';
import {useQuery} from 'hooks';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import styled from 'styled-components';

import {selectMemberById} from '../Members/membersSelectors';

const ActionContainer = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
`;
const QuestionnaireContainer = styled.div`
  width: 100%;
  height: calc(100vh - 4rem);
  overflow-y: auto;
`;

const Ul = styled.ul`
  list-style: decimal;
  padding-left: 2rem;
  padding-bottom: 2rem;
  font-size: 0.9rem;

  li {
    margin-bottom: 1.5rem;
  }

  li h4 {
    font-weight: 500;
  }

  li p {
    color: #6b6b6b;
    margin-top: 0.5rem;
  }

  li table {
    margin-top: 0.7rem;
  }

  li ul {
    padding-top: 0.3rem;
  }

  li h5 {
    font-size: 0.9rem;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    border: 1px solid #eee;
    padding: 8px;
  }
`;
type QuestionnaireDetailsProps = {
  isOnLeft?: boolean;
};
const QuestionnaireDetails: FC<QuestionnaireDetailsProps> = ({isOnLeft}) => {
  const patientIdFromVideoCall = useQuery().get('patientId');

  const {patientId} = useParams<{patientId: string}>();
  const {t} = useTranslation();

  const member = useSelector((state: RootState) => {
    return selectMemberById(
      state,
      isOnLeft ? patientIdFromVideoCall! : patientId,
    );
  });

  const regStage = member?.regStage ? Number(member.regStage) : undefined;

  return (
    <section
      className={classNames('p-7 text-left mb-20', {
        'pl-1': isOnLeft,
        'mb-0': isOnLeft,
      })}
    >
      {regStage && regStage < 19 ? (
        <section className="mb-5 p-2 m-auto">
          <h4 className="font-small text-red-500 text-center">
            <Trans i18nKey="medicalQuestionnaire.incomplete" t={t}>
              Questionnaire is Incomplete
            </Trans>
          </h4>
        </section>
      ) : null}

      <Ul>
        <li>
          <h4>
            <Trans i18nKey="medicalQuestionnaire.whatStateDoYouLiveIn" t={t}>
              What state do you live in ?
            </Trans>
          </h4>
          <p>
            {member?.stateOfResidence
              ? t(member.stateOfResidence, member.stateOfResidence)
              : t('medicalQuestionnaire.notApplicable')}
          </p>
        </li>

        {isOnLeft ? null : (
          <li>
            <h4>
              <Trans i18nKey="medicalQuestionnaire.fullName" t={t}>
                Full Name:
              </Trans>
            </h4>
            <p>{member?.fullName || t('medicalQuestionnaire.notApplicable')}</p>
          </li>
        )}

        {isOnLeft ? null : (
          <li>
            <h4>
              <Trans i18nKey="medicalQuestionnaire.dateOfBirthTitle" t={t}>
                Date of Birth:
              </Trans>
            </h4>
            <p>
              {member?.dateOfBirth ? (
                <span className="font-normal">
                  {typeof member?.dateOfBirth === 'string' &&
                  member?.dateOfBirth === 'N/A'
                    ? t('medicalQuestionnaire.notApplicable')
                    : t('medicalQuestionnaire.dateOfBirth', {
                        date: new Date(member!.dateOfBirth),
                      })}
                </span>
              ) : (
                'N/A'
              )}
            </p>
          </li>
        )}

        {isOnLeft ? null : (
          <li>
            <h4>
              <Trans i18nKey="medicalQuestionnaire.genderTitle" t={t}>
                Gender:
              </Trans>
            </h4>
            <p>
              {member?.gender
                ? t(`medicalQuestionnaire.${member.gender}`, member.gender)
                : t('medicalQuestionnaire.notApplicable')}
            </p>
          </li>
        )}

        <li>
          <h4>
            <Trans i18nKey="medicalQuestionnaire.areYouPregnantQuestion" t={t}>
              Are you pregnant, breastfeeding or postnatal? (Check all that
              apply)
            </Trans>
          </h4>
          <p>
            {member?.pregnancyInfo?.length > 0
              ? member.pregnancyInfo
                  .map(text => t(`medicalQuestionnaire.${text}`, text))
                  .join(', ')
              : t('medicalQuestionnaire.notApplicable')}
          </p>
        </li>

        <li>
          <h4>
            <Trans i18nKey="medicalQuestionnaire.allergyQuestion" t={t}>
              Do you have any drug allergies?
            </Trans>
          </h4>
          <p>
            {member?.drugAllergies
              ? t(
                  `medicalQuestionnaire.${member.drugAllergies}`,
                  member?.drugAllergies,
                )
              : t('medicalQuestionnaire.notApplicable')}
          </p>
        </li>

        <li>
          <h4>
            <Trans i18nKey="medicalQuestionnaire.tellShippingAddress" t={t}>
              Please tell us your shipping address
            </Trans>
          </h4>

          <table>
            <tr>
              <td>
                <Trans i18nKey="medicalQuestionnaire.streetAddress" t={t}>
                  Street Address
                </Trans>
              </td>
              <td>
                {member?.shippingAddress?.streetAddress
                  ? t(
                      `medicalQuestionnaire.${member?.shippingAddress?.streetAddress}`,
                      member?.shippingAddress?.streetAddress,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
            <tr>
              <td>
                <Trans i18nKey="medicalQuestionnaire.city" t={t}>
                  City
                </Trans>
              </td>
              <td>
                {member?.shippingAddress?.city
                  ? t(
                      `medicalQuestionnaire.${member?.shippingAddress?.city}`,
                      member?.shippingAddress?.city,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
            <tr>
              <td>
                <Trans i18nKey="medicalQuestionnaire.state" t={t}>
                  State
                </Trans>
              </td>
              <td>
                {member?.shippingAddress?.state
                  ? t(
                      `medicalQuestionnaire.${member?.shippingAddress?.state}`,
                      member?.shippingAddress?.state,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
            <tr>
              <td>
                <Trans i18nKey="medicalQuestionnaire.postalCode" t={t}>
                  Postal Code
                </Trans>
              </td>
              <td>
                {member?.shippingAddress?.postalCode
                  ? t(
                      `medicalQuestionnaire.${member?.shippingAddress?.postalCode}`,
                      member?.shippingAddress?.postalCode,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
            <tr>
              <td>
                <Trans i18nKey="medicalQuestionnaire.country" t={t}>
                  Country
                </Trans>
              </td>
              <td>
                {member?.shippingAddress?.country
                  ? t(
                      `medicalQuestionnaire.${member?.shippingAddress?.country}`,
                      member?.shippingAddress?.country,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
          </table>
        </li>

        <li>
          <h4>
            <Trans
              i18nKey="medicalQuestionnaire.haveYouSeenAPsychiatristOrDoctorForDepressionOrAnxiety"
              t={t}
            >
              Have you seen a Psychiatrist or Doctor for depression/anxiety?
            </Trans>
          </h4>
          <p>
            {member?.visADocForAnxDep
              ? t(
                  `medicalQuestionnaire.${member?.visADocForAnxDep}`,
                  member?.visADocForAnxDep,
                )
              : t('medicalQuestionnaire.notApplicable')}
          </p>
        </li>
        <li>
          <h4>
            <Trans i18nKey="medicalQuestionnaire.diagnosisQuestion" t={t}>
              Have you been diagnosed with any of the following conditions?
              (Choose all that apply)
            </Trans>
          </h4>
          <p>
            {isNonEmptyArrayStrings(member?.diagnosis)
              ? t(member?.diagnosis)
              : t('medicalQuestionnaire.notApplicable')}
          </p>
        </li>
        <li>
          <h4>
            <Trans
              i18nKey="medicalQuestionnaire.are you currently taking medication for depression and/or anxiety?"
              t={t}
            >
              Are you currently taking medication for depression and/or anxiety?
            </Trans>
          </h4>
          <p>
            {member?.medicationsForAnxDep
              ? t(
                  `medicalQuestionnaire.${member?.medicationsForAnxDep}`,
                  member?.medicationsForAnxDep,
                )
              : t('medicalQuestionnaire.notApplicable')}
          </p>
        </li>
        <li>
          <h4>
            <Trans i18nKey="medicalQuestionnaire.listYourMedications" t={t}>
              Please list all of your current medications, including
              prescriptions and over-the-counter supplements.
            </Trans>
          </h4>
          <p>
            {isNonEmptyArrayStrings(member?.currentMedications)
              ? member.currentMedications.map(medication =>
                  t(`medicalQuestionnaire.${medication}`, medication),
                )
              : t('medicalQuestionnaire.notApplicable')}
          </p>
        </li>

        <li>
          <h4>
            <Trans i18nKey="medicalQuestionnaire.botheredByFollowing" t={t}>
              How often have you been bothered by the following , over the past
              2 weeks?
            </Trans>
          </h4>
          <table>
            <tr>
              <td>
                <Trans
                  i18nKey="medicalQuestionnaire.feelingNervousAnxiousOrOnEdge"
                  t={t}
                >
                  Feeling nervous, anxious, or on edge:
                </Trans>
              </td>
              <td>
                {member?.anxiety?.nervous
                  ? t(
                      `medicalQuestionnaire.${member?.anxiety?.nervous}`,
                      member?.anxiety?.nervous,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
            <tr>
              <td>
                <Trans
                  i18nKey="medicalQuestionnaire.notbeingabletostoporcontrolworrying"
                  t={t}
                >
                  Not being able to stop or control worrying:
                </Trans>
              </td>
              <td>
                {member?.anxiety?.controlWorrying
                  ? t(
                      `medicalQuestionnaire.${member?.anxiety?.controlWorrying}`,
                      member?.anxiety?.controlWorrying,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
            <tr>
              <td>
                <Trans
                  i18nKey="medicalQuestionnaire.worryingTooMuchAboutDifferentThings"
                  t={t}
                >
                  Worrying too much about different things:
                </Trans>
              </td>
              <td>
                {member?.anxiety?.worryingTooMuch
                  ? t(
                      `medicalQuestionnaire.${member?.anxiety?.worryingTooMuch}`,
                      member?.anxiety?.worryingTooMuch,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
            <tr>
              <td>
                <Trans
                  i18nKey="medicalQuestionnaire.beingSoRestlessSoItIsHardToSitStill"
                  t={t}
                >
                  Being so restless that it is hard to sit still:
                </Trans>
              </td>
              <td>
                {member?.anxiety?.restlessness
                  ? t(
                      `medicalQuestionnaire.${member?.anxiety?.restlessness}`,
                      member?.anxiety?.restlessness,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
            <tr>
              <td>
                <Trans
                  i18nKey="medicalQuestionnaire.becomingEasilyAnnoyedOrIrritable"
                  t={t}
                >
                  Becoming easily annoyed or irritable:
                </Trans>
              </td>
              <td>
                {member?.anxiety?.easilyAnnoyed
                  ? t(
                      `medicalQuestionnaire.${member?.anxiety?.easilyAnnoyed}`,
                      member?.anxiety?.easilyAnnoyed,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
            <tr>
              <td>
                <Trans
                  i18nKey="medicalQuestionnaire.feelingAfraidAsIfSomethingAwfulMightHappen"
                  t={t}
                >
                  Feeling afraid, as if something awful might happen:
                </Trans>
              </td>
              <td>
                {member?.anxiety?.fear
                  ? t(
                      `medicalQuestionnaire.${member?.anxiety?.fear}`,
                      member?.anxiety?.fear,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
          </table>
        </li>

        <li>
          <h4>
            <Trans
              i18nKey="medicalQuestionnaire.howOftenHaveYouBeenBotheredByFollowing"
              t={t}
            >
              How often have you been bothered by the following , over the past
              2 weeks?
            </Trans>
          </h4>

          <table>
            <tr>
              <td>
                <Trans
                  i18nKey="medicalQuestionnaire.littleInterestOrPleasureInDoingThings"
                  t={t}
                >
                  Little interest or pleasure in doing things:
                </Trans>
              </td>
              <td>
                {member?.depression?.littleInterest
                  ? t(
                      `medicalQuestionnaire.${member?.depression?.littleInterest}`,
                      member?.depression?.littleInterest,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
            <tr>
              <td>
                <Trans
                  i18nKey="medicalQuestionnaire.feelingDownDepressedOrHopeless"
                  t={t}
                >
                  Feeling down, depressed, or hopeless:
                </Trans>
              </td>
              <td>
                {member?.depression?.feelingDown
                  ? t(
                      `medicalQuestionnaire.${member?.depression?.feelingDown}`,
                      member?.depression?.feelingDown,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
            <tr>
              <td>
                <Trans
                  i18nKey="medicalQuestionnaire.troubleFallingOrStayingAsleep"
                  t={t}
                >
                  Trouble falling or staying asleep, or sleeping too much:
                </Trans>
              </td>
              <td>
                {member?.depression?.sleepingTroubles
                  ? t(
                      `medicalQuestionnaire.${member?.depression?.sleepingTroubles}`,
                      member?.depression?.sleepingTroubles,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
            <tr>
              <td>
                <Trans
                  i18nKey="medicalQuestionnaire.feelingTiredOrHavingLittleEnergy"
                  t={t}
                >
                  Feeling tired or having little energy:
                </Trans>
              </td>
              <td>
                {member?.depression?.feelingTired
                  ? t(
                      `medicalQuestionnaire.${member?.depression?.feelingTired}`,
                      member?.depression?.feelingTired,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
            <tr>
              <td>
                <Trans
                  i18nKey="medicalQuestionnaire.poorAppetiteOrOvereating"
                  t={t}
                >
                  Poor appetite or overeating:
                </Trans>
              </td>
              <td>
                {member?.depression?.underOrOverEating
                  ? t(
                      `medicalQuestionnaire.${member?.depression?.underOrOverEating}`,
                      member?.depression?.underOrOverEating,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
            <tr>
              <td>
                <Trans
                  i18nKey="medicalQuestionnaire.feelingBadAboutYourself"
                  t={t}
                >
                  Feeling bad about yourself - or that you are a failure or have
                  let yourself or your family down:
                </Trans>
              </td>
              <td>
                {member?.depression?.feelBadAboutSelf
                  ? t(
                      `medicalQuestionnaire.${member?.depression?.feelBadAboutSelf}`,
                      member?.depression?.feelBadAboutSelf,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
            <tr>
              <td>
                <Trans
                  i18nKey="medicalQuestionnaire.troubleConcentratingOnThings"
                  t={t}
                >
                  Trouble concentrating on things, such as reading the newspaper
                  or watching television:
                </Trans>
              </td>
              <td>
                {member?.depression?.troubleConcentrating
                  ? t(
                      `medicalQuestionnaire.${member?.depression?.troubleConcentrating}`,
                      member?.depression?.troubleConcentrating,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
            <tr>
              <td>
                <Trans
                  i18nKey="medicalQuestionnaire.movingOrSpeakingSoSlowly"
                  t={t}
                >
                  Moving or speaking so slowly that other people could have
                  noticed? Or so fidgety or restless that you have been moving a
                  lot more than usual:
                </Trans>
              </td>
              <td>
                {member?.depression?.slowMovtOrFidgety
                  ? t(
                      `medicalQuestionnaire.${member?.depression?.slowMovtOrFidgety}`,
                      member?.depression?.slowMovtOrFidgety,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
            <tr>
              <td>
                <Trans
                  i18nKey="medicalQuestionnaire.thoughtsThatYouWouldBeBetterOffDead"
                  t={t}
                >
                  Thoughts that you would be better off dead, or thoughts of
                  hurting yourself in some way:
                </Trans>
              </td>
              <td>
                {member?.depression?.thoughtsOfHurtingSelf
                  ? t(
                      `medicalQuestionnaire.${member?.depression?.thoughtsOfHurtingSelf}`,
                      member?.depression?.thoughtsOfHurtingSelf,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </td>
            </tr>
          </table>
        </li>

        <li>
          <h4>
            <Trans i18nKey="clinicalIntakes.everTriedToTakeOwnLife" t={t}>
              Have you ever tried to take your own life?
            </Trans>
          </h4>
          <p>
            {member?.suicidalThoughts
              ? t(
                  `medicalQuestionnaire.${member?.suicidalThoughts}`,
                  member?.suicidalThoughts,
                )
              : t('medicalQuestionnaire.notApplicable')}
            {member?.suicidalThoughts === 'Yes' &&
              member?.suicidalThoughtsInDetails &&
              `, ${member?.suicidalThoughtsInDetails}`}
          </p>
        </li>

        <li>
          <h4>
            <Trans i18nKey="clinicalIntakes.everTriedToTakeSomeoneLife" t={t}>
              Have you ever tried to take someone else's life?
            </Trans>
          </h4>
          <p>
            {member?.suicidalThoughtsOthers
              ? t(
                  `medicalQuestionnaire.${member?.suicidalThoughtsOthers}`,
                  member?.suicidalThoughtsOthers,
                )
              : t('medicalQuestionnaire.notApplicable')}
            {member?.suicidalThoughtsOthers === 'Yes' &&
              member?.suicidalThoughtsOthersInDetails &&
              `, ${member?.suicidalThoughtsOthersInDetails}`}
          </p>
        </li>

        <li>
          <h4>
            <Trans
              i18nKey="clinicalIntakes.currentlyThoughtOfTakingOwnLife"
              t={t}
            >
              Do you currently have or have you ever had thoughts of take your
              own life in the past 12 months?
            </Trans>
          </h4>
          <p>
            {member?.currentlySuicidal
              ? t(
                  `medicalQuestionnaire.${member?.currentlySuicidal}`,
                  member?.currentlySuicidal,
                )
              : t('medicalQuestionnaire.notApplicable')}
            {member?.currentlySuicidal === 'Yes' &&
              member?.currentlySuicidalInDetails &&
              `, ${member?.currentlySuicidalInDetails}`}
          </p>
        </li>

        <li>
          <h4>
            <Trans
              i18nKey="clinicalIntakes.currentlyThoughtOfTakingSomeoneLife"
              t={t}
            >
              Do you currently have or have you ever had thoughts of take
              someone else's life in the past 12 months?
            </Trans>
          </h4>
          <p>
            {member?.currentlySuicidalOthers
              ? t(
                  `medicalQuestionnaire.${member?.currentlySuicidalOthers}`,
                  member?.currentlySuicidalOthers,
                )
              : t('medicalQuestionnaire.notApplicable')}
            {member?.currentlySuicidalOthers === 'Yes' &&
              member?.currentlySuicidalOthersInDetails &&
              `, ${member?.currentlySuicidalOthersInDetails}`}
          </p>
        </li>

        <li>
          <h4>
            <Trans
              i18nKey="medicalQuestionnaire.haveYouSufferedFromSeizures"
              t={t}
            >
              Have you ever suffered from seizures or have you been treated with
              anti-seizure medication even as a child? Have you ever suffered a
              head injury like in a vehicle accident or a fall or other such
              event where you lost consciousness even for a few seconds?
            </Trans>
          </h4>
          <p>
            {member?.seizure
              ? t(`medicalQuestionnaire.${member?.seizure}`, member?.seizure)
              : t('medicalQuestionnaire.notApplicable')}
          </p>
        </li>

        {member?.insuranceImageFront ? (
          <li>
            <h4>
              <Trans
                i18nKey="medicalQuestionnaire.frontCoverOfInsuranceImage"
                t={t}
              >
                Front cover of insurance image
              </Trans>
            </h4>
            <img
              src={
                member?.insuranceImageFront ||
                t('medicalQuestionnaire.notApplicable')
              }
              className="w-full h-full object-contain"
              alt={t(
                'medicalQuestionnaire.frontOfTheCard',
                'Front of the card',
              )}
            />
          </li>
        ) : null}

        {member?.insuranceImageBack ? (
          <li>
            <h4>
              <Trans
                i18nKey="medicalQuestionnaire.backCoverOfInsuranceImage"
                t={t}
              >
                Back cover of insurance image
              </Trans>
            </h4>
            <img
              src={
                member?.insuranceImageBack ||
                t('medicalQuestionnaire.notApplicable')
              }
              className="w-full h-full object-contain"
              alt={t('medicalQuestionnaire.backOfTheCard', 'Back of the card')}
            />
          </li>
        ) : null}

        {member?.insuranceDetails ? (
          <>
            <li>
              <h4>
                <Trans
                  i18nKey="medicalQuestionnaire.insuranceCompanyTypeDetailsByPatient"
                  t={t}
                >
                  Insurance company type details by patient
                </Trans>
              </h4>
              <p>
                {member?.insuranceDetails?.companyType
                  ? t(member?.insuranceDetails?.companyType)
                  : t(
                      'medicalQuestionnaire.patientHasNotProvidedTheCompanyType',
                      'This patient has not provided the type of insurance',
                    )}
              </p>
            </li>
            <li>
              <h4>
                <Trans
                  i18nKey="medicalQuestionnaire.insuranceMemberIDForPatient"
                  t={t}
                >
                  Insurance Member ID for patient
                </Trans>
              </h4>
              <p>
                {member?.insuranceDetails?.memberId ||
                  t(
                    'medicalQuestionnaire.patientHasNotProvidedMemberID',
                    'This patient has not provided his Member ID',
                  )}
              </p>
            </li>
            <li>
              <h4>
                <Trans
                  i18nKey="medicalQuestionnaire.insuranceCompanyNameDetailsByPatient"
                  t={t}
                >
                  Insurance company name details by patient
                </Trans>
              </h4>
              <p>
                {member?.insuranceDetails?.companyName
                  ? t(member?.insuranceDetails?.companyName)
                  : t(
                      'medicalQuestionnaire.patientHasNoInsuranceCompanyName',
                      'This patient has not provided the name of insurance company',
                    )}
              </p>
            </li>
            <li>
              <h4>
                <Trans
                  i18nKey="medicalQuestionnaire.insuranceCompanyTypeDetailsByPatient"
                  t={t}
                >
                  Insurance company type details by patient
                </Trans>
              </h4>
              <p>
                {member?.insuranceDetails?.companyId ||
                  t(
                    'medicalQuestionnaire.patientHasNoIdentityNumberOfInsurance',
                    'This patient has not provided the identity number of insurance',
                  )}
              </p>
            </li>

            <li>
              <h4>
                <Trans
                  i18nKey="medicalQuestionnaire.insuranceDependencyStatusDetailsByPatient"
                  t={t}
                >
                  Insurance dependency status details by patient
                </Trans>
              </h4>
              <p>
                {member?.insuranceDetails?.dependencyStatus
                  ? t(member?.insuranceDetails?.dependencyStatus)
                  : t(
                      'medicalQuestionnaire.patientHasNoDependencyStatusOfInsurance',
                      'This patient has not provided his/her dependency status of insurance',
                    )}
              </p>
            </li>
          </>
        ) : null}

        {member?.paymentPlan ? (
          <li>
            <h4>
              <Trans
                i18nKey="medicalQuestionnaire.paymentPlanChosenByPatient"
                t={t}
              >
                Payment plan chosen by patient
              </Trans>
            </h4>
            <p>
              {member?.paymentPlan.length
                ? t(member?.paymentPlan)
                : t(
                    'medicalQuestionnaire.patientSkippedPayment',
                    'This patient skipped payment',
                  )}
            </p>
          </li>
        ) : null}

        <li>
          <h4>
            <Trans i18nKey="medicalQuestionnaire.localPharmacyData" t={t}>
              Local Pharmacy Data
            </Trans>
          </h4>
          <ul>
            <li>
              <h5>
                <Trans i18nKey="medicalQuestionnaire.pharmaName" t={t}>
                  Pharma Name:
                </Trans>
              </h5>
              <p>
                {member?.localPharmData?.pharmName
                  ? t(
                      `medicalQuestionnaire.${member?.localPharmData?.pharmName}`,
                      member?.localPharmData?.pharmName,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </p>
            </li>
            <li>
              <h5>
                <Trans i18nKey="medicalQuestionnaire.pharmaAddress" t={t}>
                  Pharma Address:
                </Trans>
              </h5>
              <p>
                {member?.localPharmData?.pharmAddress
                  ? t(
                      `medicalQuestionnaire.${member?.localPharmData?.pharmAddress}`,
                      member?.localPharmData?.pharmAddress,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </p>
            </li>
          </ul>
        </li>

        <li>
          <h4>
            <Trans i18nKey="medicalQuestionnaire.wherePatientCameFrom" t={t}>
              Where patient came from:
            </Trans>
          </h4>
          <p>
            {member?.whereFrom
              ? t(
                  `medicalQuestionnaire.${member?.whereFrom}`,
                  member?.whereFrom,
                )
              : t('medicalQuestionnaire.notApplicable')}
          </p>
        </li>

        <li>
          <h4>
            <Trans i18nKey="medicalQuestionnaire.jobInformation" t={t}>
              Job Information:
            </Trans>
          </h4>
          <ul>
            <li>
              <h5>
                <Trans i18nKey="medicalQuestionnaire.companyName" t={t}>
                  Company Name:
                </Trans>
              </h5>
              <p>
                {member?.companyName
                  ? t(
                      `medicalQuestionnaire.${member?.companyName}`,
                      member?.companyName,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </p>
            </li>
            <li>
              <h5>
                <Trans i18nKey="medicalQuestionnaire.jobTitle" t={t}>
                  Job Title:
                </Trans>
              </h5>
              <p>
                {member?.jobTitle
                  ? t(
                      `medicalQuestionnaire.${member?.jobTitle}`,
                      member?.jobTitle,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </p>
            </li>
            <li>
              <h5>
                <Trans i18nKey="medicalQuestionnaire.companyShouldPay" t={t}>
                  Company Should Pay:
                </Trans>
              </h5>
              <p>
                {member?.companyShouldPay
                  ? t(
                      `medicalQuestionnaire.${member?.companyShouldPay}`,
                      member?.companyShouldPay,
                    )
                  : t('medicalQuestionnaire.notApplicable')}
              </p>
            </li>
          </ul>
        </li>
      </Ul>
    </section>
  );
};

type Props = {
  isOnLeft?: boolean;
};

const MedicalQuestionnaire: FC<Props> = ({isOnLeft = false}) => {
  const {t} = useTranslation();

  return isOnLeft ? (
    <>
      <QuestionnaireDetails isOnLeft={isOnLeft} />
    </>
  ) : (
    <>
      <section className="h-full">
        <ActionContainer className="border-b">
          <p className="font-bold text-lg pl-3">
            <Trans i18nKey="medicalQuestionnaire.questionnaire" t={t}>
              Questionnaire
            </Trans>
            {/* Member's name would most likely replace this */}
          </p>
        </ActionContainer>
        <QuestionnaireContainer>
          <QuestionnaireDetails />
        </QuestionnaireContainer>
      </section>
    </>
  );
};

export default MedicalQuestionnaire;
