import {
  EndPoints,
  GenerateImageFromPostSessionQuestionnaireArgs,
  GenerateImageFromPostSessionQuestionnaireResponse,
  GenerateIndividualQuestionArgs,
  GenerateIndividualQuestionResponse,
  GeneratePostSessionQuestionnaireArgs,
  GeneratePostSessionQuestionnaireResponse,
  GenerateWellniteArtTitleArgs,
  GenerateWellniteArtTitleResponse,
  GetPaginatedWellniteArtGalleryResponse,
  GetPaginatedWellniteArtsPayload,
  GetPatientWellniteArtsResponse,
  GetWellniteArtByIdArgs,
  GetWellniteArtByIdResponse,
  GetWellniteArtConditionTagsArgs,
  GetWellniteArtConditionTagsResponse,
  GetWellniteArtForYouResponse,
  HttpMethods,
  MemberProfile,
  RecordWellniteArtReactionPayload,
  RegenerateWellniteArtCaptionArgs,
  SearchWellniteArtsGalleryPayload,
  SearchWellniteArtsResponse,
  UpdateWellniteArtInfoArgs,
  UpdateWellniteArtInfoResponse,
} from 'interfaces';
import {Response} from 'redaxios';
import {requestHandler} from 'services/api';
import {unwrapAPIError} from 'utils';

const generatePostSessionQuestionnaire = async (
  data: GeneratePostSessionQuestionnaireArgs,
): Promise<
  Response<{
    message: GeneratePostSessionQuestionnaireResponse;
  }>
> => {
  try {
    const result = await requestHandler<
      {
        status: number;
        message: GeneratePostSessionQuestionnaireResponse;
      },
      typeof data
    >({
      method: HttpMethods.GET,
      url: `${EndPoints.GeneratePostSessionQuestionnaire}?appointmentID=${data.appointmentId}` as unknown as EndPoints,
      data,
    });

    if (result.data.status !== 200) {
      return Promise.reject(result.data.message);
    }

    return result;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const generateIndividualQuestion = async (
  data: GenerateIndividualQuestionArgs,
): Promise<
  Response<{
    message: GeneratePostSessionQuestionnaireResponse;
  }>
> => {
  try {
    return await requestHandler<
      {
        status: number;
        message: GenerateIndividualQuestionResponse;
      },
      typeof data
    >({
      method: HttpMethods.POST,
      url: `${EndPoints.RegenerateQuestion}?questionID=${data.questionId}` as unknown as EndPoints,
      data,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const regenerateWellniteArtTitle = async (
  data: GenerateWellniteArtTitleArgs,
): Promise<
  Response<{
    message: GenerateWellniteArtTitleResponse;
  }>
> => {
  try {
    return await requestHandler<
      {
        status: number;
        message: GenerateWellniteArtTitleResponse;
      },
      typeof data
    >({
      method: HttpMethods.GET,
      url: `${EndPoints.RegenerateWellniteArtTitle}?magicArtId=${data.wellniteArtId}` as unknown as EndPoints,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getWellniteArtById = async (
  args: GetWellniteArtByIdArgs,
): Promise<
  Response<{
    message: GetWellniteArtByIdResponse;
  }>
> => {
  try {
    return await requestHandler<{
      status: number;
      message: GetWellniteArtByIdResponse;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetWellniteArtById}?magicArtId=${args.wellniteArtId}` as unknown as EndPoints,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getWellniteArtConditionTags = async (
  args: GetWellniteArtConditionTagsArgs,
): Promise<
  Response<{
    message: GetWellniteArtConditionTagsResponse;
  }>
> => {
  try {
    return await requestHandler<{
      status: number;
      message: GetWellniteArtConditionTagsResponse;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetWellniteArtConditionTags}?${
        args.wellniteArtId ? 'magicArtId=' + args.wellniteArtId : ''
      }` as unknown as EndPoints,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const updateWellniteArtInfo = async (
  data: UpdateWellniteArtInfoArgs,
): Promise<
  Response<{
    message: UpdateWellniteArtInfoResponse;
  }>
> => {
  try {
    return await requestHandler<
      {
        status: number;
        message: UpdateWellniteArtInfoResponse;
      },
      typeof data
    >({
      method: HttpMethods.PATCH,
      url: `${EndPoints.UpdateWellniteArtInfo}` as unknown as EndPoints,
      data,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const generateImageFromPostSessionQuestionnaire = async (
  data: GenerateImageFromPostSessionQuestionnaireArgs,
): Promise<
  Response<{
    message: GenerateImageFromPostSessionQuestionnaireResponse;
  }>
> => {
  try {
    const result = await requestHandler<
      {
        status: number;
        message: GenerateImageFromPostSessionQuestionnaireResponse;
      },
      typeof data
    >({
      method: HttpMethods.POST,
      url: EndPoints.GenerateImageFromPostSessionQuestionnaire,
      data,
    });

    if (result.data.status !== 200) {
      return Promise.reject(result.data.message);
    }

    return result;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getPaginatedWellniteArtsGallery = async ({
  maxResults,
  page = 1,
}: GetPaginatedWellniteArtsPayload): Promise<
  Response<{
    message: GetPaginatedWellniteArtGalleryResponse;
  }>
> => {
  try {
    return await requestHandler<{
      status: number;
      message: GetPaginatedWellniteArtGalleryResponse;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetPaginatedWellniteArtsGallery}?page=${page}&maxResults=${maxResults}` as unknown as EndPoints,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const searchWellniteArtsGallery = async ({
  maxResults,
  searchTerm,
  galleryType,
  selectedConditionTags,
  page = 1,
}: SearchWellniteArtsGalleryPayload): Promise<
  Response<{
    message: SearchWellniteArtsResponse;
  }>
> => {
  try {
    return await requestHandler<{
      status: number;
      message: SearchWellniteArtsResponse;
    }>({
      method: HttpMethods.GET,
      url: `${
        EndPoints.SearchWellniteArtsGallery
      }/?searchTerm=${searchTerm}&page=${page}&maxResults=${maxResults}&galleryType=${galleryType}&selectedConditionTags=${selectedConditionTags.join(
        ',',
      )}` as unknown as EndPoints,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getPatientWellniteArts = async (): Promise<
  Response<{
    message: GetPatientWellniteArtsResponse;
  }>
> => {
  try {
    return await requestHandler<{
      status: number;
      message: GetPatientWellniteArtsResponse;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetPatientWellniteArts}` as unknown as EndPoints,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const recordWellniteArtReaction = async (
  payload: RecordWellniteArtReactionPayload,
): Promise<
  Response<{
    message: MemberProfile;
  }>
> => {
  try {
    return await requestHandler<{
      status: number;
      message: MemberProfile;
    }>({
      method: HttpMethods.POST,
      url: `${EndPoints.RecordWellniteArtReaction}` as unknown as EndPoints,
      data: {
        magicArtId: payload.wellniteArtId,
        reaction: payload.reaction,
      },
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getWellniteArtForYou = async (): Promise<
  Response<{message: GetWellniteArtForYouResponse}>
> => {
  try {
    return await requestHandler<{
      status: number;
      message: GetWellniteArtForYouResponse;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetWellniteArtForYou}` as unknown as EndPoints,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const regenerateWellniteArtCaption = async (
  args: RegenerateWellniteArtCaptionArgs,
): Promise<
  Response<{
    message: GenerateImageFromPostSessionQuestionnaireResponse;
  }>
> => {
  try {
    return await requestHandler<{
      status: number;
      message: GenerateImageFromPostSessionQuestionnaireResponse;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.RegenerateWellniteArtCaption}?magicArtId=${args.wellniteArtId}` as unknown as EndPoints,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getPatientLikedWellniteArts = async (): Promise<
  Response<{
    message: GetPatientWellniteArtsResponse;
  }>
> => {
  try {
    return await requestHandler<{
      status: number;
      message: GetPatientWellniteArtsResponse;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetPatientLikedWellniteArts}` as unknown as EndPoints,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const WellniteArtService = {
  generatePostSessionQuestionnaire,
  generateImageFromPostSessionQuestionnaire,
  getPaginatedWellniteArtsGallery,
  searchWellniteArtsGallery,
  getPatientWellniteArts,
  getWellniteArtForYou,
  recordWellniteArtReaction,
  generateIndividualQuestion,
  regenerateWellniteArtCaption,
  getPatientLikedWellniteArts,
  getWellniteArtById,
  getWellniteArtConditionTags,
  regenerateWellniteArtTitle,
  updateWellniteArtInfo,
};
