import React, {Fragment} from 'react';
import ImportantInfo from 'assets/images/important_info.png';
import {Button} from 'components';
import {IntakePath} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {Dialog, Transition} from '@headlessui/react';

interface PopUpScreenPartiallyFilledProps {
  intakePath: IntakePath;
  onClose: () => void;
}

const PopUpScreenPartiallyFilled: React.FC<PopUpScreenPartiallyFilledProps> = ({
  intakePath,
  onClose,
}) => {
  const {t} = useTranslation();

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-999" onClose={onClose}>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-screen h-screen transform overflow-hidden bg-primary p-6 md:p-10 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-center items-center h-full pt-20">
                  <div className="flex flex-col justify-center items-center">
                    <img
                      src={ImportantInfo}
                      className="w-[32px] h-[32px]"
                      alt="wellnite important info"
                    />
                    <div className="text-center w-full md:w-2/5 pt-10">
                      <p className="text-white mb-6 text-[24px]">
                        {t(
                          'popUpScreenPartiallyFilled.pleaseFinishFullIntakeForm',
                        )}
                      </p>
                      <p className="text-white mb-4">
                        {t(
                          'popUpScreenPartiallyFilled.theNextQuestionsProvide',
                        )}
                      </p>
                      <p className="text-white">
                        {t('popUpScreenPartiallyFilled.youMaySkip')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fixed left-0 right-0 bottom-0 bg-primary w-full px-5 md:px-10">
                  <div className="flex flex-row border-t border-1 w-full py-5 justify-between bottom-0 bg-primary px-5 md:px-10">
                    <Link to={intakePath}>
                      <Button
                        className="px-4 xs:px-6 py-4 space-x-0 xs:space-x-2 flex justify-center items-center min-w-[48px] sm:min-w-[140px] min-h-[48px] sm:min-h-[56px] !font-semibold group"
                        borderRadius="full"
                        fontSize="base"
                        btnType="custom"
                        bgColor="bg-primary"
                        borderColor="white"
                        loaderType="Oval"
                        outline
                      >
                        {t('popUpScreenPartiallyFilled.finishIntake')}
                      </Button>
                    </Link>

                    <Button
                      className="px-4 xs:px-6 py-4 space-x-0 xs:space-x-2 flex justify-center items-center min-w-[48px] sm:min-w-[140px] min-h-[48px] sm:min-h-[56px] !font-semibold group"
                      borderRadius="full"
                      fontSize="base"
                      btnType="custom"
                      bgColor="pink-400"
                      borderWidth="border-0"
                      loaderType="Oval"
                      onClick={onClose}
                    >
                      <span className="text-black">
                        {t('popUpScreenPartiallyFilled.continue')}
                      </span>
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PopUpScreenPartiallyFilled;
