import React, {useEffect, useRef} from 'react';
import classNames from 'classnames';
import {FormLabel} from 'components';
import {Controller, useFormContext} from 'react-hook-form';

interface TextareaInputProps {
  name: string;
  label: string;
  focused?: boolean;
  maxCharacters?: number;
  textareaClassName?: string;
  placeholder?: string;
  rows?: number;
  children?: React.ReactNode;
}

export function QuestionInput(props: TextareaInputProps): React.ReactElement {
  const {name, label, children, maxCharacters} = props;
  const {watch, formState} = useFormContext();
  const value = watch(name);
  const valueLength = value?.length ?? 0;
  const error = formState.errors[name];

  return (
    <div
      className={classNames(
        'w-full px-4 pt-9 pb-12 border border-gray-300 bg-white rounded-xl relative',
        {
          'focus-within:border-gray-300': !error,
          'border-red-500': error,
        },
      )}
    >
      <div className="flex justify-between w-full -mt-6">
        <FormLabel
          htmlFor={name}
          className="!text-[11px] text-black w-full flex-nowrap flex font-normal font-inter"
        >
          {label}
        </FormLabel>
        {maxCharacters ? (
          <div className="text-sm w-[50px] text-gray-600">
            {valueLength}/{maxCharacters}
          </div>
        ) : null}
      </div>
      <section className="flex flex-col">
        <TextareaComponent {...props} />
      </section>
      {children}
    </div>
  );
}

export function TextareaComponent({
  name,
  maxCharacters,
  textareaClassName,
  rows,
  focused,
  placeholder,
}: TextareaInputProps): React.ReactElement {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const {control} = useFormContext();

  useEffect(() => {
    if (textareaRef.current && focused) {
      textareaRef.current.focus();
    }
  }, [focused]);

  return (
    <Controller
      control={control}
      name={name}
      render={({field}) => (
        <textarea
          ref={textareaRef}
          value={field.value}
          onChange={field.onChange}
          maxLength={maxCharacters}
          placeholder={placeholder}
          rows={rows}
          className={classNames(
            'w-full appearance-none border-none outline-none box-border resize-none bg-transparent text-gray-600 overflow-y-hidden',
            textareaClassName,
          )}
        />
      )}
    />
  );
}
