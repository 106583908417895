import React from 'react';
import classNames from 'classnames';
import {CloseIcon} from 'components';
import {useTranslation} from 'react-i18next';

interface Props {
  stepsCount: number;
  currentStep: number;
  onStepClick: (step: number) => void;
  onCloseClick: () => void;
  onBackClick?: () => void;
}

export function ProgressHeader({
  stepsCount,
  currentStep,
  onStepClick,
  onBackClick,
  onCloseClick,
}: Props): React.ReactElement {
  const stepsMap = Array.from({length: stepsCount});
  const {t} = useTranslation();

  return (
    <div className="flex flex-row w-full mx-auto space-x-1 py-6 items-center max-w-[1500px] justify-between font-montserrat font-semibold px-3">
      {currentStep >= stepsCount && onBackClick ? (
        <span
          className="whitespace-nowrap cursor-pointer"
          onClick={onBackClick}
        >
          {t('wellniteArt.back')}
        </span>
      ) : (
        <span className="whitespace-nowrap">
          {t('wellniteArt.wellniteArt')}
        </span>
      )}

      {currentStep >= stepsCount ? null : (
        <div className="flex flex-row w-full mx-auto space-x-1 items-center max-w-[1000px] absolute top-20 xs:top-auto pr-8 xs:pr-auto xs:relative">
          {stepsMap?.map((_, index) => (
            <div
              key={index}
              onClick={() => onStepClick(index)}
              className={classNames(
                'font-bold w-full h-1 text-xl text-primary rounded-full cursor-pointer',
                {
                  'bg-primary': index <= currentStep,
                  'bg-[#D9D9D9]': index > currentStep,
                },
              )}
            ></div>
          ))}
        </div>
      )}

      <button
        onClick={onCloseClick}
        className="ml-auto flex justify-center items-center w-10 group z-999"
      >
        <CloseIcon
          strokeColor="text-black group-hover:text-gray-500 -mr-3"
          strokeWidth={40}
          classes="w-8 h-8"
        />
      </button>
    </div>
  );
}
