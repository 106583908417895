import React, {FC, useEffect, useState} from 'react';
import {FullModalLoader} from 'components/Basic/Loader/FullModalLoader';
import {IntakeType} from 'definitions/Intake';
import {selectAppointment} from 'features/Appointment';
import PopUpScreenPartiallyFilled from 'features/Intake/IntakeSections/PopUpScreenPartiallyFilled';
import {
  selectIntakeCompleted,
  selectIntakePartiallyCompleted,
  selectIntakeStatus,
} from 'features/Intake/intakeSelectors';
import {intakeActions} from 'features/Intake/intakeSlice';
import {
  selectCheckUserStatus,
  selectUserProfile,
  userActions,
} from 'features/User';
import {useQuery} from 'hooks';
import {
  IntakeLocationState,
  IntakePath,
  MinorConsentStatus,
  SliceStatus,
  WherebyRoomModeTypes,
} from 'interfaces';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, useLocation} from 'react-router-dom';
import {calculateAge, isMember} from 'utils';

export function withTherapyIntake(Component: FC): () => React.ReactElement {
  const WithTherapyIntakeWrapper = () => {
    const user = useSelector(selectUserProfile);
    const intakeLoadingStatus = useSelector(selectIntakeStatus);
    const userLoadingStatus = useSelector(selectCheckUserStatus);
    const urlParams = useQuery();
    const appointmentID = urlParams.get('appointmentID') ?? '';
    const roomMode = (urlParams.get('roomMode') ?? '') as WherebyRoomModeTypes;
    const appointmentDetails = useSelector(
      selectAppointment(roomMode, Number(appointmentID)),
    );
    const dispatch = useDispatch();

    const hasPartiallyCompletedIntake = useSelector(
      selectIntakePartiallyCompleted,
    );
    const hasCompletedIntake = useSelector(selectIntakeCompleted);

    const currentLocation = useLocation<IntakeLocationState>();
    const videoCallUrl = `${currentLocation.pathname}${currentLocation.search}`;

    const {showModal = true} = currentLocation.state || {};
    const [modalDismissed, setModalDismissed] = useState(!showModal);
    const legalAge = calculateAge(user?.dateOfBirth);

    const intakePath: IntakePath = {
      pathname: '/onboarding-therapy-intake',
      state: {from: videoCallUrl},
    };

    useEffect(() => {
      if (user && user?.patientId) {
        dispatch(
          intakeActions.getMemberIntakeData({
            patientId: user?.patientId,
            intakeType: IntakeType.therapy,
          }),
        );
      }
    }, []);

    useEffect(() => {
      if (user) {
        dispatch(userActions.checkSession(user.role));
      }
    }, []);

    const isTherapyIntakeNotCompleted = !hasCompletedIntake;
    const isTherapyIntakeNotPartiallyCompleted = !hasPartiallyCompletedIntake;

    if (
      isMember(user) &&
      showModal &&
      intakeLoadingStatus === SliceStatus.pending &&
      userLoadingStatus === SliceStatus.pending
    ) {
      return <FullModalLoader className="flex justify-center items-center" />;
    }

    if (
      isMember(user) &&
      appointmentDetails?.category === 'Mental Health Counseling Video-Call' &&
      isTherapyIntakeNotPartiallyCompleted &&
      isTherapyIntakeNotCompleted &&
      intakeLoadingStatus === SliceStatus.resolved &&
      userLoadingStatus === SliceStatus.resolved
    ) {
      return <Redirect to={intakePath} />;
    }

    if (
      isMember(user) &&
      isTherapyIntakeNotCompleted &&
      appointmentDetails?.category === 'Mental Health Counseling Video-Call' &&
      intakeLoadingStatus === SliceStatus.resolved &&
      userLoadingStatus === SliceStatus.resolved &&
      !modalDismissed
    ) {
      return (
        <PopUpScreenPartiallyFilled
          intakePath={intakePath}
          onClose={() => setModalDismissed(true)}
        />
      );
    }

    if (
      isMember(user) &&
      legalAge < 18 &&
      user.minorConsent?.status !== MinorConsentStatus.Signed &&
      userLoadingStatus === SliceStatus.resolved
    ) {
      return (
        <Redirect
          to={{
            pathname: `/minor-consent/${user.patientId}`,
            state: {from: videoCallUrl},
          }}
        />
      );
    }
    return <Component />;
  };

  return WithTherapyIntakeWrapper;
}
