import {IntakeStatus, IntakeType} from 'definitions/Intake';
import {SliceStatus} from 'interfaces';
import {IntakeQuestionResponseType} from 'interfaces/Intake.types';

import {ActionReducerMapBuilder, createSlice, Slice} from '@reduxjs/toolkit';

import {
  changeIntakeStatus,
  changeIntakeStep,
  checkUserExists,
  checkUserExistsFailure,
  checkUserExistsSuccess,
  getMemberIntakeData,
  getMemberIntakeDataFailure,
  getMemberIntakeDataSuccess,
  saveIntakeData,
  saveIntakeDataFailure,
  saveIntakeDataSuccess,
} from './intakeActions';

export type IntakeSliceState = {
  status: SliceStatus;
  currentStep: number;
  therapyIntakeStatus: IntakeStatus;
  questions: IntakeQuestionResponseType[];
  hasPartiallyCompletedIntake?: boolean;
  hasCompletedIntake?: boolean;
  isNewIntakeMember?: boolean;
};
export const initialState: IntakeSliceState = {
  status: SliceStatus.idle,
  currentStep: 1,
  therapyIntakeStatus: IntakeStatus.notStarted,
  questions: [],
  hasPartiallyCompletedIntake: false,
  hasCompletedIntake: false,
};

const intakeSlice: Slice = createSlice({
  name: 'intake',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<IntakeSliceState>) =>
    builder
      .addCase(checkUserExists, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(checkUserExistsSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(checkUserExistsFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(saveIntakeData, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(saveIntakeDataSuccess, (state, action) => {
        const {
          intakeResponse: {currentStep},
        } = action.payload;
        return {
          ...state,
          status: SliceStatus.resolved,
          currentStep: currentStep,
          therapyIntakeStatus:
            action.payload.intakeResponse.intakeType === IntakeType.therapy
              ? action.payload.intakeResponse.intakeStatus
              : state.therapyIntakeStatus,
          questions: action.payload.intakeResponse.questions,
          hasPartiallyCompletedIntake:
            action.payload.intakeResponse.hasPartiallyCompletedIntake,
          isNewIntakeMember: action.payload.intakeResponse.isNewIntakeMember,
        };
      })
      .addCase(saveIntakeDataFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(getMemberIntakeData, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(getMemberIntakeDataSuccess, (state, action) => {
        const {
          intakeResponse: {currentStep},
        } = action.payload;
        return {
          ...state,
          status: SliceStatus.resolved,
          currentStep: currentStep,
          therapyIntakeStatus:
            action.payload.intakeResponse.intakeType === IntakeType.therapy
              ? action.payload.intakeResponse.intakeStatus
              : state.therapyIntakeStatus,
          questions: action.payload.intakeResponse.questions,
          hasPartiallyCompletedIntake:
            action.payload.intakeResponse.hasPartiallyCompletedIntake,
          hasCompletedIntake: action.payload.intakeResponse.hasCompletedIntake,
          isNewIntakeMember: action.payload.intakeResponse.isNewIntakeMember,
        };
      })
      .addCase(getMemberIntakeDataFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(changeIntakeStep, (state, action) => {
        return {
          ...state,
          currentStep: action.payload,
        };
      })
      .addCase(changeIntakeStatus, (state, action) => {
        return {
          ...state,
          therapyIntakeStatus: action.payload,
        };
      })
      .addDefaultCase(state => state),
});

export const {reducer: intakeReducer, name: intakeReducerName} = intakeSlice;

export type TIntakeActions =
  | ReturnType<typeof checkUserExists>
  | ReturnType<typeof checkUserExistsSuccess>
  | ReturnType<typeof checkUserExistsFailure>
  | ReturnType<typeof saveIntakeData>
  | ReturnType<typeof saveIntakeDataSuccess>
  | ReturnType<typeof saveIntakeDataFailure>
  | ReturnType<typeof getMemberIntakeData>
  | ReturnType<typeof getMemberIntakeDataSuccess>
  | ReturnType<typeof getMemberIntakeDataFailure>
  | ReturnType<typeof changeIntakeStep>
  | ReturnType<typeof changeIntakeStatus>;

export const intakeActions = {
  checkUserExists,
  checkUserExistsFailure,
  checkUserExistsSuccess,
  saveIntakeData,
  saveIntakeDataFailure,
  saveIntakeDataSuccess,
  getMemberIntakeData,
  getMemberIntakeDataSuccess,
  getMemberIntakeDataFailure,
  changeIntakeStep,
  changeIntakeStatus,
};

export type IntakeState = ReturnType<typeof intakeReducer>;
