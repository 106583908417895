import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {userActions} from 'features/User';
import i18next from 'i18next';
import {Epic} from 'redux-observable';
import {
  catchError,
  concat,
  filter,
  from,
  ignoreElements,
  mergeMap,
  of,
  tap,
} from 'rxjs';
import {WellniteExerciseService} from 'services/WellniteExercise';

import {wellniteExerciseActions} from './wellniteExerciseSlice';

const generateExerciseEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(wellniteExerciseActions.generateExercise.match),
    mergeMap(({payload: {onSuccess, onError, ...rest}}) =>
      from(WellniteExerciseService.generateExercise(rest)).pipe(
        mergeMap(
          ({
            data: {
              message: {questions},
            },
          }) =>
            concat(
              of(
                wellniteExerciseActions.generateExerciseSuccess({
                  ...rest,
                  questions,
                }),
              ).pipe(tap(() => onSuccess?.())),
            ),
        ),
        catchError((message: string) =>
          concat(
            of(wellniteExerciseActions.generateExerciseFailure(message)).pipe(
              tap(() => onError?.()),
            ),
          ),
        ),
      ),
    ),
  );

const generateExerciseFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteExerciseActions.generateExerciseFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const regenerateExerciseQuestionEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteExerciseActions.regenerateExerciseQuestion.match),
    mergeMap(({payload: {onSuccess, onError, ...rest}}) =>
      from(WellniteExerciseService.regenerateExerciseQuestion(rest)).pipe(
        mergeMap(
          ({
            data: {
              message: {questions},
            },
          }) =>
            concat(
              of(
                wellniteExerciseActions.regenerateExerciseQuestionSuccess({
                  questions,
                }),
              ).pipe(tap(() => onSuccess?.(questions[0]))),
            ),
        ),
        catchError((message: string) =>
          concat(
            of(
              wellniteExerciseActions.regenerateExerciseQuestionFailure(
                message,
              ),
            ).pipe(tap(() => onError?.())),
          ),
        ),
      ),
    ),
  );

const regenerateExerciseQuestionFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteExerciseActions.regenerateExerciseQuestionFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const publishExerciseEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(wellniteExerciseActions.publishExercise.match),
    mergeMap(({payload: {onSuccess, onError, ...rest}}) =>
      from(WellniteExerciseService.publishExercise(rest)).pipe(
        mergeMap(({data: {message}}) =>
          concat(
            of(
              wellniteExerciseActions.publishExerciseSuccess(message.exercise),
            ).pipe(tap(() => onSuccess?.())),
          ),
        ),
        catchError((message: string) =>
          concat(
            of(wellniteExerciseActions.publishExerciseFailure(message)).pipe(
              tap(() => onError?.()),
            ),
          ),
        ),
      ),
    ),
  );

const publishExerciseFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteExerciseActions.publishExerciseFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const publishExerciseSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteExerciseActions.publishExerciseSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: i18next.t(
          'wellniteExercise.exerciseCreatedSuccess',
          'Exercise created successfully',
        ),
      });
    }),
    ignoreElements(),
  );

const getWellniteExcercisesEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteExerciseActions.getExercises.match),
    mergeMap(({payload}) =>
      from(WellniteExerciseService.getWellniteExcercises(payload)).pipe(
        mergeMap(({data: {message}}) => [
          wellniteExerciseActions.getExercisesSuccess(message),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteExercise',
                message,
              }),
            ),
            of(wellniteExerciseActions.getExercisesFailure(message)),
          ),
        ),
      ),
    ),
  );

const getWellniteExcercisesFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteExerciseActions.getExercisesFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const updateExerciseAnswersEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteExerciseActions.updateExerciseAnswers.match),
    mergeMap(({payload: {onSuccess, onError, ...rest}}) =>
      from(WellniteExerciseService.updateExerciseAnswers(rest)).pipe(
        mergeMap(({data: {message}}) =>
          concat(
            of(
              wellniteExerciseActions.updateExerciseAnswersSuccess(
                message.exercise,
              ),
            ).pipe(tap(() => onSuccess?.())),
          ),
        ),
        catchError((message: string) =>
          concat(
            of(
              wellniteExerciseActions.updateExerciseAnswersFailure(message),
            ).pipe(tap(() => onError?.())),
          ),
        ),
      ),
    ),
  );

const updateExerciseAnswersFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteExerciseActions.updateExerciseAnswersFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const updateExerciseAnswersSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteExerciseActions.updateExerciseAnswersSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: i18next.t(
          'wellniteExercise.answersSavedSuccess',
          'Answers saved successfully',
        ),
      });
    }),
    ignoreElements(),
  );

const sendInitialExerciseRequestEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteExerciseActions.sendInitialExerciseRequest.match),
    mergeMap(() =>
      from(WellniteExerciseService.sendInitialExerciseRequest()).pipe(
        mergeMap(() =>
          concat(
            of(wellniteExerciseActions.sendInitialExerciseRequestSuccess()),
          ),
        ),
        catchError((message: string) =>
          concat(
            of(
              wellniteExerciseActions.sendInitialExerciseRequestFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

const sendInitialExerciseRequestFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteExerciseActions.sendInitialExerciseRequestFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

export const wellniteExerciseEpics = [
  generateExerciseEpic,
  generateExerciseFailureEpic,
  regenerateExerciseQuestionEpic,
  regenerateExerciseQuestionFailureEpic,
  publishExerciseEpic,
  publishExerciseFailureEpic,
  publishExerciseSuccessEpic,
  getWellniteExcercisesEpic,
  getWellniteExcercisesFailureEpic,
  updateExerciseAnswersEpic,
  updateExerciseAnswersFailureEpic,
  updateExerciseAnswersSuccessEpic,
  sendInitialExerciseRequestEpic,
  sendInitialExerciseRequestFailureEpic,
].flatMap(epic => epic);
