import React, {useEffect, useState} from 'react';
import {dispatch} from 'app/store';
import {Button, FormLabel, RefreshIcon} from 'components';
import {SliceStatus} from 'interfaces';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {yupResolver} from '@hookform/resolvers/yup';

import {QuestionInput} from '../Components/QuestionInput';
import {artValidationSchema} from '../validation';
import {updateWellniteArtInfo} from '../wellniteArtActions';
import {
  selectWellniteArt,
  selectWellniteArtStatus,
} from '../wellniteArtSelectors';

interface Props {
  onNext: VoidFunction;
}

export function WellniteArtPreview(props: Props): React.ReactElement {
  const {t} = useTranslation();
  const sliceStatus = useSelector(selectWellniteArtStatus);
  const wellniteArt = useSelector(selectWellniteArt);
  const [regeneratingTitle, setRegeneratingTitle] = useState<boolean>(false);
  const [regeneratingCaption, setRegeneratingCaption] =
    useState<boolean>(false);

  const wellniteArtUrl = wellniteArt?.generatedImageUrl;

  const form = useForm({
    resolver: yupResolver(artValidationSchema),
    reValidateMode: 'onChange',
    defaultValues: {
      caption: '',
      title: '',
    },
  });

  const onGenerateNewTitle = () => {
    setRegeneratingTitle(true);
  };

  const onGenerateNewCaption = () => {
    setRegeneratingCaption(true);
  };

  const handleNext = () => {
    props.onNext();
    dispatch(
      updateWellniteArtInfo({
        magicArtId: wellniteArt!._id,
        title: form.getValues('title'),
        caption: form.getValues('caption'),
      }),
    );
  };

  useEffect(() => {
    if (wellniteArt) {
      form.setValue('caption', wellniteArt.generatedImageCaption);
      form.setValue('title', wellniteArt.generatedImageTitle);
    }
  }, [wellniteArt]);

  return (
    <div className="text-center flex flex-col justify-center items-center space-y-2 self-center h-full w-full">
      {sliceStatus === SliceStatus.resolved ? (
        <div className="pb-20 md:pb-12 pt-6 w-full flex flex-col h-full overflow-scroll md:overflow-hidden">
          <div className="flex flex-col md:flex-row w-full justify-center">
            <div className="font-inter text-xl md:text-sm text-gray-900 flex flex-col md:text-left md:my-4 md:mx-8 m-auto">
              <span>{t('wellniteArt.yourWellniteArt')}:</span>
              <img
                src={wellniteArtUrl}
                className="rounded-[40px] max-h-[60vh] max-w-[80vw] w-auto md:mr-4 my-2 m-auto"
                alt="Generated Image"
              />
            </div>
            <div className="flex flex-col w-full max-w-[600px] gap-4 justify-center p-3 m-auto md:m-0">
              <FormProvider {...form}>
                <QuestionInput
                  name="title"
                  label="Name this artwork"
                  textareaClassName="text-sm h-25 md:h-25"
                  rows={2}
                >
                  <Button
                    className="text-primary text-sm p-1 absolute bg-gray-200 rounded-lg flex items-center justify-center right-[15px] bottom-[15px]"
                    disabled={regeneratingTitle}
                    onClick={() => onGenerateNewTitle()}
                  >
                    <RefreshIcon
                      strokeColor="text-black"
                      rotating={regeneratingTitle}
                    />
                  </Button>
                </QuestionInput>
                <QuestionInput
                  name="caption"
                  label="Add description to this artwork"
                  textareaClassName="text-sm h-25 md:h-25"
                  rows={5}
                >
                  <Button
                    className="text-primary text-sm p-1 absolute bg-gray-200 rounded-lg flex items-center justify-center right-[15px] bottom-[15px]"
                    disabled={regeneratingCaption}
                    onClick={() => onGenerateNewCaption()}
                  >
                    <RefreshIcon
                      strokeColor="text-black"
                      rotating={regeneratingCaption}
                    />
                  </Button>
                </QuestionInput>
              </FormProvider>
              <FormLabel htmlFor="" className="!text-[16px] text-gray-800">
                {t('wellniteArt.hashtagsForThisArtwork')}:
              </FormLabel>
              <div className="items-left flex flex-wrap gap-4">
                {wellniteArt?.tags.map(tag => (
                  <span
                    key={tag._id}
                    className="text-gray-500 text-sm float-left bg-gray-200 rounded-full px-4 py-2"
                  >
                    #{tag.name}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="p-3 h-full content-end flex-grow w-full">
            <Button
              className="h-12 px-4 sm:px-8 flex items-center py-2.5 rounded-full text-base text-white mx-auto bg-primary justify-center bg-opacity-100 rounded-full disabled:opacity-50 disabled:bg-gray-500 cursor-pointer xs:min-w-[150px] min-w-full"
              disabled={false}
              onClick={handleNext}
            >
              <p className="pr-1">{t('wellniteArt.next')}</p>
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
