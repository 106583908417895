import {RootState} from 'app/rootReducer';

import {createSelector} from '@reduxjs/toolkit';

import {UserState} from './userSlice';

function getUser(state: RootState): UserState {
  return state.user;
}

export const selectUserProfile = createSelector(getUser, user => user.current);
export const selectUserChatSettingStatus = createSelector(
  getUser,
  user => user.chatSettingsStatus,
);

export const selectUserNotification = createSelector(
  getUser,
  user => user.notification,
);

export const selectPwaInstallEvt = createSelector(
  getUser,
  user => user.pwaInstallEvt,
);

export const selectUserBootstrap = createSelector(
  getUser,
  user => user.bootstrap,
);

export const selectSocialOnboardStage = createSelector(
  getUser,
  user => user.socialOnboardStage,
);

export const selectReleaseNotes = createSelector(
  getUser,
  user => user.releaseNotes,
);

export const selectOneTimePasswordVerificationStatus = createSelector(
  getUser,
  user => user.oneTimePasswordVerificationStatus,
);

export const selectEmployers = createSelector(getUser, user => user.employers);
export const selectSearchedEmployers = createSelector(
  getUser,
  user => user.searchedEmployers,
);

export const selectUserLanguage = createSelector(
  getUser,
  user => user?.current?.preferredLanguage,
);
export const selectUserCountry = createSelector(
  getUser,
  user => user?.current?.countryOfResidence,
);

export const selectMinorConsentStep = createSelector(
  getUser,
  user => user?.minorConsentStep,
);

export const selectMinorConsentStatus = createSelector(
  getUser,
  user => user?.minorConsentStatus,
);

export const selectUserStatus = createSelector(getUser, user => user?.status);

export const selectUserLogoutStatus = createSelector(
  getUser,
  user => user?.logoutStatus,
);

export const selectCheckUserStatus = createSelector(
  getUser,
  user => user?.checkSessionStatus,
);

export const selectPaystackSubaccountStatus = createSelector(
  getUser,
  user => user?.paystackSubaccountStatus,
);
