import {TFunction} from 'i18next';

const promptTranslationPrefix = 'wellniteExercise.promptOptions';

/**
 * Prompt options for creating a new Wellnite Exercise.
 */
export const PromptsOptions = (t: TFunction) => [
  {
    label: t(
      `${promptTranslationPrefix}.emotionalRegulation`,
      'Emotional Regulation',
    ),
    value: 'Emotional Regulation',
  },
  {
    label: t(`${promptTranslationPrefix}.selfAwareness`, 'Self-Awareness'),
    value: 'Self-Awareness',
  },
  {
    label: t(`${promptTranslationPrefix}.empathy`, 'Empathy'),
    value: 'Empathy',
  },
  {
    label: t(`${promptTranslationPrefix}.gratitude`, 'Gratitude'),
    value: 'Gratitude',
  },
  {
    label: t(`${promptTranslationPrefix}.angerManagement`, 'Anger Management'),
    value: 'Anger Management',
  },
  {
    label: t(`${promptTranslationPrefix}.selfCompassion`, 'Self-Compassion'),
    value: 'Self-Compassion',
  },
  {
    label: t(`${promptTranslationPrefix}.forgiveness`, 'Forgiveness'),
    value: 'Forgiveness',
  },
  {
    label: t(
      `${promptTranslationPrefix}.conflictResolution`,
      'Conflict Resolution',
    ),
    value: 'Conflict Resolution',
  },
  {
    label: t(`${promptTranslationPrefix}.boundarySetting`, 'Boundary-Setting'),
    value: 'Boundary-Setting',
  },
  {
    label: t(`${promptTranslationPrefix}.affirmations`, 'Affirmations'),
    value: 'Affirmations',
  },
  {
    label: t(
      `${promptTranslationPrefix}.valuesExploration`,
      'Values Exploration',
    ),
    value: 'Values Exploration',
  },
  {
    label: t(
      `${promptTranslationPrefix}.triggerIdentification`,
      'Trigger Identification',
    ),
    value: 'Trigger Identification',
  },
  {
    label: t(
      `${promptTranslationPrefix}.selfCarePlanning`,
      'Self-Care Planning',
    ),
    value: 'Self-Care Planning',
  },
];
