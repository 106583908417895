export const customSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 48,
    padding: '4px 2px',
    borderRadius: '12px',
    borderColor: state.isFocused ? '#2e62ec' : '#ccc',
    boxShadow: 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: 'white',
    zIndex: '9999 !important',
  }),
  option: (provided: any) => ({
    ...provided,
    padding: 10,
  }),
  multiValue: (provided: any) => ({
    ...provided,
    borderRadius: '16px',
    padding: '4px 6px',
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: 'black',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: 'black',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    cursor: 'pointer',
    paddingLeft: 0,
    paddingRight: 0,
    '& svg': {
      width: '20px',
      height: '20px',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
};
