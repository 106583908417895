import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {RootState} from 'app/rootReducer';
import {
  Button,
  CheckmarkIcon,
  FormError,
  FormLabel,
  GoBackIcon,
  LoadingSpinner,
  RichTextArea,
  TAndCIcon,
} from 'components';
import Toast from 'components/Basic/Toast';
import {icdCodes} from 'definitions/ICD';
import {GroupedOptions} from 'definitions/OpenAI';
import {NoteFormProps, NoteSchema, UpdateNoteSchema} from 'definitions/Yup';
import {selectUserProfile} from 'features/User';
import {useQuery, useRequesting} from 'hooks';
import {NotesType, SliceStatus, UserRoles} from 'interfaces';
import debounce from 'lodash.debounce';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Select, {OnChangeValue} from 'react-select';
import {emptyishProperties, isTherapist, isUserFromUnitedStates} from 'utils';

import {yupResolver} from '@hookform/resolvers/yup';

import {FormSelect} from '../../../components/Basic/Form/V2';
import {selectMedications, selectProvider} from '../providerSelectors';
import {providerActions} from '../providerSlice';

import AddMedications from './AddMedications';
import {
  getNotesList,
  selectNoteById,
  selectNotesStatus,
} from './notesSelectors';
import {ActionContainer, NotesContainer, selectStyle} from './styled';

type Props = {
  togglePage: () => void;
  patientId?: string;
  memberEmail: string;
  selectedNote?: NotesType | null;
  isInVideoCall: boolean;
  setIsNoteCreated?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectNoteToEdit?: React.Dispatch<React.SetStateAction<NotesType | null>>;
  setNoteError?: React.Dispatch<React.SetStateAction<string>>;
  isNoteCreated?: boolean;
  memberHasInsurance?: boolean;
  onToggleClick?: () => void;
};

type OptionType = {value: string; label: string};
type DurationOptionType = {value: number; label: string};

const newMedicationData = {
  medicationName: [],
  medicationInstruction: '',
  quantity: 0,
  daySupply: 0,
  refillNumber: 0,
  paymentType: '',
  cardHolderId: '',
  rxGroup: '',
  rxBin: '',
  pcn: '',
};

const icdCodesOptions = icdCodes.map(code => ({
  label: `${code.value} - ${code.label}`,
  value: code.value,
}));

const CreateNote: FC<Props> = ({
  togglePage,
  memberEmail,
  selectedNote,
  isInVideoCall,
  setIsNoteCreated,
  setNoteError,
  memberHasInsurance,
  isNoteCreated,
  onToggleClick,
}) => {
  const [noteCreationDelay, setNoteCreationDelay] = useState(false);
  const {t} = useTranslation();
  const medications = useSelector(selectMedications);
  const user = useSelector(selectUserProfile);
  const urlParams = useQuery();
  const notes = useSelector(getNotesList);
  const notesSliceStatus = useSelector(selectNotesStatus);

  const dispatch = useDispatch();
  const appointmentIDFromParam = urlParams.get('appointmentID') ?? '';

  const appointmentID =
    appointmentIDFromParam || selectedNote?.appointmentID || '';
  const roomMode = urlParams.get('roomMode') || '';

  const status = useRequesting('provider');
  const isLoading = status === SliceStatus.pending;
  const {noteUpdateStatus} = useSelector(selectProvider);

  const autosaveNoteCreationStatus = useRef(SliceStatus.idle);
  const manuallyNoteCreationStatus = useRef(SliceStatus.idle);
  const newlyCreatedNoteId = useRef('');
  const defaultMedications =
    selectedNote?.medications.map(med => {
      return {
        ...newMedicationData,
        ...med,
        medicationName: med.medicationName,
      };
    }) || [];

  const noteId = useMemo(
    () => selectedNote?.noteId || newlyCreatedNoteId.current,
    [selectedNote?.noteId, newlyCreatedNoteId.current],
  );
  const noteIdExist = noteId !== '';

  /**
   * this will help to activate the cms1500 form claim submission button,
   * after appointment type, duration type & icdCodes is being saved to the server
   */
  const currNoteRdxData = useSelector((state: RootState) =>
    selectNoteById(state, noteId),
  );

  const useUpdateNoteSchema =
    (!isInVideoCall && noteIdExist) ||
    (!selectedNote?.duration && noteIdExist && isInVideoCall) ||
    !isInVideoCall;

  const getDefaultIcdCodes = (): {label: string; value: string}[] => {
    if (selectedNote?.icdCodes?.length) {
      return selectedNote.icdCodes;
    }

    const recentIcdCodes = notes.find(
      note => note.noteCreator === user?._id,
    )?.icdCodes;

    return recentIcdCodes ? recentIcdCodes : [];
  };

  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
    getValues,
    setError,
    reset,
  } = useForm<NoteFormProps>({
    mode: 'all',
    resolver: yupResolver(useUpdateNoteSchema ? UpdateNoteSchema : NoteSchema),
    defaultValues: {
      chiefComplaint: selectedNote?.chiefComplaint ?? '',
      patientNotes: selectedNote?.patientNotes ?? '',
      plan: selectedNote?.plan ?? '',
      prescribeMedication: selectedNote?.prescribeMedication ?? false,
      pharmacyService: selectedNote?.pharmacyService ?? 'curexa',
      medications: defaultMedications,
      mentalHealthConditions: selectedNote?.mentalHealthConditions ?? [],
      icdCodes: getDefaultIcdCodes(),
      appointmentType: selectedNote?.appointmentType ?? '',
      duration: selectedNote?.duration ?? null,
    },
  });

  const {fields, append, remove} = useFieldArray({
    name: 'medications',
    control,
  });
  const addMoreMedication = () => {
    append(newMedicationData);
  };

  const removeMedication = (idx: number) => {
    if (watch('medications')!.length === 1 && idx === 0) {
      setValue('prescribeMedication', false, {shouldValidate: true});
      remove(idx);
    } else {
      remove(idx);
    }
  };

  const medicationList = useMemo(() => {
    return medications?.result
      ? medications?.result.map(v => {
          return {
            medicationName: medications.entities.medications[v].medicationName,
            medicationId: medications.entities.medications[v].medicationId,
          };
        })
      : [];
  }, [medications.result]);

  const validateNote = () => {
    let error = '';
    if (memberHasInsurance) {
      if (watch('duration') === null && isTherapist(user))
        error += ' Duration of Appointment,';
      if (watch('appointmentType') === '' && isTherapist(user))
        error += ' Type of Appointment,';
    }
    if (
      isTherapist(user) &&
      Array.isArray(watch('mentalHealthConditions')) &&
      watch('mentalHealthConditions')!.length === 0
    )
      error += ' Mental Health Conditions,';
    if (
      isUserFromUnitedStates(user) &&
      Array.isArray(watch('icdCodes')) &&
      watch('icdCodes')!.length === 0
    )
      error += ' ICD Codes';

    if (error.length && setNoteError) {
      error = `*Attention! Please complete the required fields on the notes section i.e. ${error}.`;
    }

    return error;
  };

  const handleNoteSave = (values: NoteFormProps) => {
    if (noteIdExist) {
      const note = {
        ...values,
        userRole: user!.role,
        patientEmail: memberEmail,
        noteId,
        medicationList: medicationList,
        noteCreatorName: user!.fullName,
      };
      dispatch(
        providerActions.updateNote({
          note,
          goToNotesList: togglePage,
          isToast: true,
          toastMsg: selectedNote?.noteId
            ? t('noteUpdatedSuccessfully')
            : t('noteCreatedSuccessfully'),
        }),
      );
    }
  };
  const onSubmit = (values: NoteFormProps) => {
    let error = '';
    if (isInVideoCall) {
      error = validateNote();
      if (error.length && setNoteError) {
        setNoteError(error);
        onToggleClick && onToggleClick();
      } else {
        handleNoteSave(values);
      }
    } else {
      if (
        isUserFromUnitedStates(user) &&
        Array.isArray(watch('icdCodes')) &&
        watch('icdCodes')!.length === 0
      ) {
        setNoteError &&
          setNoteError(
            '*Attention! Please complete the required fields on the notes section i.e. ICD Codes.',
          );
        onToggleClick && onToggleClick();
      } else {
        handleNoteSave(values);
      }
    }
  };

  const autoSave = React.useCallback(
    debounce(() => {
      //if (autosaveNoteCreationStatus.current === SliceStatus.pending) return
      if (noteIdExist) {
        const note = {
          ...watch(),
          userRole: user!.role,
          patientEmail: memberEmail,
          noteId,
          medicationList: medicationList,
          noteCreatorName: user!.fullName,
        };

        dispatch(providerActions.updateNote({note, isToast: false}));
      }
    }, 500),
    [medicationList, noteIdExist],
  );

  const onNoteCreateSuccess = (noteId: string) => {
    if (noteId) {
      autosaveNoteCreationStatus.current = SliceStatus.resolved;
      newlyCreatedNoteId.current = noteId;

      if (manuallyNoteCreationStatus.current === SliceStatus.pending) {
        togglePage();
        Toast({
          type: 'success',
          message: t('noteCreatedSuccessfully'),
          position: 'bottom-left',
        });
      }
      setIsNoteCreated && setIsNoteCreated(true);
    } else togglePage();
  };
  const onNoteCreateFailed = () => {
    togglePage();
  };
  /**
   * create note auto
   */
  useEffect(() => {
    if (!selectedNote?.noteId) {
      const note = {
        ...watch(),
        userRole: user!.role,
        patientEmail: memberEmail,
        medicationList: medicationList,
        noteCreatorName: user!.fullName,
        wellniteNote: selectedNote?.wellniteNote,
        appointmentID,
        roomMode,
      };
      setIsNoteCreated && setIsNoteCreated(false);
      autosaveNoteCreationStatus.current = SliceStatus.pending;
      dispatch(
        providerActions.createNotes({
          note,
          onNoteCreateSuccess,
          onNoteCreateFailed,
        }),
      );
    }

    return () => {
      if (setIsNoteCreated) setIsNoteCreated(false);
    };
  }, [selectedNote]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setNoteCreationDelay(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (user?.role === UserRoles.prescriber) {
      dispatch(providerActions.getMedications());
    }
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (watch('prescribeMedication') && watch('medications')?.length === 0) {
      append(newMedicationData);
    }
    // remove all entries
    if (watch('prescribeMedication') === false) {
      remove();
    }
  }, [watch('prescribeMedication')]);

  useEffect(() => {
    if (
      setIsNoteCreated &&
      ((newlyCreatedNoteId.current !== '' &&
        watch('duration') !== null &&
        watch('appointmentType') !== '' &&
        watch('mentalHealthConditions')?.length &&
        watch('icdCodes')?.length) ||
        selectedNote?.noteId)
    ) {
      setIsNoteCreated(true);
    } else {
      if (watch('duration') === null && isTherapist(user))
        setError('duration', {
          type: 'manual',
          message: t('durationOfAppointmentRequired'),
        });
      if (watch('appointmentType') === '' && isTherapist(user))
        setError('appointmentType', {
          type: 'manual',
          message: t('typeOfAppointmentRequired'),
        });
      if (
        isTherapist(user) &&
        Array.isArray(watch('mentalHealthConditions')) &&
        watch('mentalHealthConditions')!.length === 0
      )
        setError('mentalHealthConditions', {
          type: 'manual',
          message: t('mentalHealthConditionsRequired'),
        });
      if (
        isUserFromUnitedStates(user) &&
        Array.isArray(watch('icdCodes')) &&
        watch('icdCodes')!.length === 0
      )
        setError('icdCodes', {
          type: 'manual',
          message: t('icdCodesRequired'),
        });
    }
  }, [
    newlyCreatedNoteId.current,
    watch('duration'),
    watch('appointmentType'),
    watch('mentalHealthConditions'),
    watch('icdCodes'),
    isNoteCreated,
  ]);

  useEffect(() => {
    const error = validateNote();
    if (error.length && setNoteError) {
      setNoteError(error);
    } else if (setNoteError) {
      setNoteError('');
    }
  }, [
    watch('duration'),
    watch('appointmentType'),
    watch('mentalHealthConditions'),
    watch('icdCodes'),
  ]);

  const durationOptions = [
    {
      value: 30,
      label: t('30minutes'),
    },
    {
      value: 45,
      label: t('45minutes'),
    },
    {
      value: 60,
      label: t('60minutes'),
    },
  ];

  const appointmentTypeOptions = [
    {
      value: 'first',
      label: t('This is the first appointment with patient'),
    },
    {
      value: 'subsequent',
      label: t('This is a subsequent appointment with patient'),
    },
  ];

  const propLabel = {
    appointmentType: t('typeOfAppointment'),
    duration: t('durationOfAppointment'),
    icdCodes: t('icdCodes'),
  };

  const cmsFormMissingField = currNoteRdxData
    ? emptyishProperties({
        appointmentType: currNoteRdxData['appointmentType'],
        duration: currNoteRdxData['duration'],
        icdCodes: currNoteRdxData['icdCodes'],
      })
    : [];

  const cmsFormFieldError = `* ${cmsFormMissingField
    .map(v => propLabel[v])
    .join(', ')} ${cmsFormMissingField.length > 1 ? t('are') : t('is')} ${t(
    'requiredToSubmitClaim',
  )}`;

  const handleBackButtonClick = () => {
    const error = validateNote();
    if (error.includes('ICD Codes') && setNoteError && onToggleClick) {
      setNoteError(error);
      onToggleClick();
    } else {
      if (
        isInVideoCall &&
        isNoteCreated &&
        memberHasInsurance &&
        error.trim() !== '' &&
        isTherapist(user)
      ) {
        setNoteError!(error);
        onToggleClick!();
      } else {
        togglePage();
      }
    }
  };

  useEffect(() => {
    if (
      notesSliceStatus === SliceStatus.resolved ||
      notesSliceStatus === SliceStatus.rejected
    ) {
      const currentIcdCodes = getValues()?.icdCodes;
      if (!currentIcdCodes?.length) {
        setValue('icdCodes', getDefaultIcdCodes());
      }
    }
  }, [notesSliceStatus]);

  return (
    <section className="relative">
      <ActionContainer className="border-b flex items-center justify-between px-3 ">
        <div className="flex items-center">
          {noteUpdateStatus === SliceStatus.pending ? (
            <LoadingSpinner height={20} type="Oval" color="#000" />
          ) : (
            <button
              onClick={handleBackButtonClick}
              className="focus:outline-none"
            >
              <GoBackIcon classes="mx-4" fillColor="#000" />
            </button>
          )}
          <p className="font-bold text-lg pl-3">
            {selectedNote?.noteId ? t('updateNote') : t('createNote')}
          </p>
        </div>
        {noteUpdateStatus === SliceStatus.resolved ? (
          <span className="flex items-center float-right h-10">
            <CheckmarkIcon
              strokeWidth={40}
              strokeColor="text-gray-500"
              width={16}
              height={16}
            />
            <p className="text-xs text-gray-600 ml-1">{t('noteAutosaved')}</p>
          </span>
        ) : noteUpdateStatus === SliceStatus.pending ? (
          <p className="text-xs text-gray-600">{t('noteAutosaving')}</p>
        ) : null}
      </ActionContainer>
      <NotesContainer className="px-6 pb-8" isInVideoCall={isInVideoCall}>
        <form className="pl-2" onSubmit={handleSubmit(onSubmit)}>
          <article className="pt-6 ">
            <FormLabel
              className="font-bold text-base pb-1"
              htmlFor="chiefComplaint"
            >
              {t('chiefComplaint')}
            </FormLabel>
            <section>
              <RichTextArea
                name="chiefComplaint"
                control={control}
                onAutoSave={autoSave}
                placeholder={t('chiefComplaint')}
                className="rounded bg-white"
                minHeight="5.625rem"
              />
              <FormError error={errors.chiefComplaint?.message} />
            </section>
          </article>
          <article className="pt-5 ">
            <FormLabel
              className="font-bold text-base pb-1"
              htmlFor="patientNotes"
            >
              {t('patientNotes')}
            </FormLabel>

            <section>
              <RichTextArea
                name="patientNotes"
                control={control}
                onAutoSave={autoSave}
                placeholder={t('writeNotes')}
                className="rounded bg-white"
                minHeight="7.5rem"
              />
              <FormError error={errors.patientNotes?.message} />
            </section>
          </article>
          <article className="pt-5">
            <FormLabel className="font-bold text-base pb-1" htmlFor="plan">
              {t('Plan')}
            </FormLabel>

            <section>
              <RichTextArea
                name="plan"
                control={control}
                onAutoSave={autoSave}
                placeholder={t('Plan')}
                className="rounded bg-white"
                minHeight="5.625rem"
              />
              <FormError error={errors.plan?.message} />
            </section>
          </article>
          {isInVideoCall && isTherapist(user) ? (
            <>
              <article className="mb-5 pt-5">
                <FormLabel
                  htmlFor="duration"
                  id="duration"
                  className="font-bold"
                >
                  {t('typeOfAppointment')}
                  <span className="text-red-500">&nbsp;*</span>
                </FormLabel>
                <section>
                  <Controller
                    name="appointmentType"
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <Select
                        onChange={(val: OnChangeValue<OptionType, boolean>) => {
                          onChange((val as OptionType).value);
                          autoSave();
                        }}
                        onBlur={onBlur}
                        value={
                          value
                            ? {
                                label: appointmentTypeOptions.find(
                                  v => value === v.value,
                                )!.label,
                                value,
                              }
                            : null
                        }
                        name="appointmentType"
                        id="appointmentType"
                        aria-labelledby="appointmentType"
                        options={appointmentTypeOptions}
                        placeholder={t('selectAppointmentType')}
                        styles={selectStyle}
                        className="bg-white border appearance-none w-full leading-tight focus:outline-none rounded-lg"
                      />
                    )}
                  />
                  <FormError error={errors.appointmentType?.message} />
                </section>
              </article>

              <article className="mb-5 pt-5">
                <FormLabel
                  htmlFor="duration"
                  id="duration"
                  className="font-bold"
                >
                  {t('durationOfAppointment')}
                  <span className="text-red-500">&nbsp;*</span>
                </FormLabel>
                <section>
                  <Controller
                    name="duration"
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <Select
                        onChange={(
                          val: OnChangeValue<DurationOptionType, boolean>,
                        ) => {
                          onChange((val as DurationOptionType).value);
                          autoSave();
                        }}
                        onBlur={onBlur}
                        value={
                          value
                            ? {
                                label: durationOptions?.find(
                                  v => value === v.value,
                                )!.label,
                                value,
                              }
                            : null
                        }
                        name="duration"
                        id="duration"
                        aria-labelledby="duration"
                        options={durationOptions}
                        placeholder={t('selectAppointmentDuration')}
                        styles={selectStyle}
                        className="bg-white border appearance-none w-full leading-tight focus:outline-none rounded-lg"
                      />
                    )}
                  />
                  <FormError error={errors.duration?.message} />
                </section>
              </article>
            </>
          ) : null}
          <FormSelect
            id="mentalHealthConditions"
            name="mentalHealthConditions"
            label={
              <label className="font-bold text-xs">
                {t('mentalHealthConditions')}
                <span className="text-red-500">&nbsp;*</span>
              </label>
            }
            control={control}
            isMulti
            isSearchable
            options={GroupedOptions(t)}
            creatable
            onChange={(newValue, setControlValue) => {
              setControlValue(newValue || []);
              autoSave();
            }}
            placeholder={t('mentalHealthConditions')}
            styles={selectStyle}
            inputClasses="bg-white border appearance-none w-full leading-tight focus:outline-none rounded-lg"
          />
          {isUserFromUnitedStates(user) ? (
            <FormSelect
              creatable={true}
              control={control}
              id="icdCodes"
              label={
                <label className="font-bold text-xs">
                  {t('ICD Codes')}
                  {isTherapist(user) ? (
                    <span className="text-red-500">&nbsp;*</span>
                  ) : null}
                </label>
              }
              isMulti={true}
              classes="mb-5 pt-5"
              onChange={(newValue, setControlValue) => {
                setControlValue(newValue || []);
                autoSave();
              }}
              isLoading={value =>
                !value?.length && notesSliceStatus === SliceStatus.pending
              }
              options={icdCodesOptions}
              defaultOptions={icdCodesOptions}
              placeholder={t('selectICDCodes')}
              cacheOptions={true}
              styles={selectStyle}
              inputClasses="bg-white border appearance-none w-full leading-tight focus:outline-none rounded-lg"
            />
          ) : null}

          {user?.role === UserRoles.prescriber ? (
            <article className="flex items-center pt-6">
              <input
                type="checkbox"
                id="prescribeMedication"
                {...register('prescribeMedication', {onChange: autoSave})}
                className="form-checkbox text-blue-600 transition duration-150 ease-in-out"
              />
              <label
                className="pl-2 text-gray-700 text-sm"
                htmlFor="prescribeMedication"
              >
                {t('prescribeMedicine')}
              </label>
            </article>
          ) : null}
          {watch('prescribeMedication') ? (
            <>
              <div className="flex items-center mt-3">
                <article className="flex items-center">
                  <input
                    type="radio"
                    value="curexa"
                    id="pharmacyService"
                    {...register('pharmacyService', {onChange: autoSave})}
                    className="form-radio text-blue-600 transition duration-150 ease-in-out"
                  />
                  <label
                    htmlFor="pharmacyService"
                    className="pl-1 md:pl-3 block text-xs md:text-base text-gray-700 text-left leading-snug"
                  >
                    Curexa
                  </label>
                </article>
                <article className="ml-4 flex items-center">
                  <input
                    type="radio"
                    value="other pharmacy"
                    {...register('pharmacyService', {onChange: autoSave})}
                    className="form-radio text-blue-600 transition duration-150 ease-in-out"
                  />
                  <label
                    htmlFor="pharmacyService"
                    className="pl-1 md:pl-3 block text-xs md:text-base text-gray-700 text-left leading-snug"
                  >
                    {t('otherPharmacy')}
                  </label>
                </article>

                <Button
                  borderRadius="full"
                  className="px-4 py-1 ml-3"
                  onClick={addMoreMedication}
                >
                  {t('addMore')}
                </Button>
              </div>

              <AddMedications
                control={control}
                register={register}
                errors={errors}
                fields={fields}
                remove={removeMedication}
                medicationList={medicationList}
                autoSave={autoSave}
              />
            </>
          ) : null}
          {cmsFormMissingField.length && memberHasInsurance ? (
            <p className="italic text-xs text-gray-600 mt-2 mb-4">
              {cmsFormFieldError}
            </p>
          ) : null}
          <article className="flex items-center space-x-3">
            <Button
              type="submit"
              borderColor="transparent"
              disabled={isLoading}
              className="px-5 py-2 mt-4 mb-8 flex items-center justify-center hover:bg-white hover:text-white border-blue-600 rounded-full"
            >
              {t('Save')}
              {isLoading ||
              manuallyNoteCreationStatus.current === SliceStatus.pending ? (
                <LoadingSpinner
                  width={12}
                  height={12}
                  type="Oval"
                  color="#fff"
                />
              ) : null}
            </Button>
          </article>
        </form>
      </NotesContainer>
      {autosaveNoteCreationStatus.current === SliceStatus.pending ? (
        <section className="w-full h-full bg-white bg-opacity-80 absolute top-0 left-0 z-999 flex flex-col justify-center items-center">
          <div className="w-2/3 flex flex-col justify-center items-center">
            <TAndCIcon
              width={70}
              height={70}
              fillColor="#315eff"
              strokeColor="text-white"
              classes="animate-bounce"
            />
            <p className="p-4 text-xl text-center text-gray-800 font-light">
              {t('Preparing note, Please wait...')}
            </p>

            {noteCreationDelay ? (
              <p className="text-base text-center text-gray-700 font-light">
                {t(
                  "It's taking a bit longer than expected, but we'll get there as fast as we can",
                )}
              </p>
            ) : null}
          </div>
        </section>
      ) : null}
    </section>
  );
};
export default React.memo(CreateNote);
