import {SliceStatus} from 'interfaces';
import {GetMinorConsentResponse} from 'interfaces/MinorConsent.types';

import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  getMinorConsentDataRequest,
  getMinorConsentDataRequestFailure,
  getMinorConsentDataRequestSuccess,
  setMinorConsentDataRequest,
  setMinorConsentDataRequestFailure,
  setMinorConsentDataRequestSuccess,
} from './minorConsentActions';

type MinorConsentSliceState = {
  data: GetMinorConsentResponse;
  status: SliceStatus;
  error: string;
};

export const defaultMinorConsentData = {
  signedParentFirstName: '',
  signedParentSurname: '',
  signedChildFirstName: '',
  signedChildSurname: '',
  signature: '',
};

export const minorConsentRequestData = {
  result: defaultMinorConsentData,
};
export const minorConsentInitialState: MinorConsentSliceState = {
  data: {...minorConsentRequestData},
  status: SliceStatus.idle,
  error: '',
};

const MinorConsentSlice = createSlice({
  name: 'minorConsent',
  initialState: minorConsentInitialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<MinorConsentSliceState>) =>
    builder
      .addCase(getMinorConsentDataRequest, (state, action) => ({
        ...state,
        status: SliceStatus.pending,
        data: {
          ...state.data,
          patientId: action.payload.patientId,
        },
      }))
      .addCase(getMinorConsentDataRequestSuccess, (state, action) => {
        let data: GetMinorConsentResponse = {...minorConsentRequestData};

        if (typeof action.payload === 'object') {
          data = {
            ...action.payload,
            result: {
              ...state.data.result,
              ...action.payload.result,
            },
          };
        }

        return {
          ...state,
          data: {
            ...state.data,
            status: SliceStatus.resolved,
            data,
          },
        };
      })
      .addCase(getMinorConsentDataRequestFailure, (state, action) => ({
        ...state,
        data: {
          ...state.data,
          status: SliceStatus.rejected,
        },
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(setMinorConsentDataRequest, (state, action) => ({
        ...state,
        status: SliceStatus.pending,
        data: {
          ...state.data,
          patientId: action.payload.patientId,
        },
      }))
      .addCase(setMinorConsentDataRequestSuccess, (state, action) => ({
        ...state,
        status: SliceStatus.resolved,
        data: {
          ...state.data,
          ...action.payload,
        },
      }))
      .addCase(setMinorConsentDataRequestFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        error: action.payload,
      })),
});

export const {reducer: minorConsentReducer, name: minorConsentReducerName} =
  MinorConsentSlice;

export type TMinorConsentActions =
  | ReturnType<typeof getMinorConsentDataRequest>
  | ReturnType<typeof getMinorConsentDataRequestSuccess>
  | ReturnType<typeof getMinorConsentDataRequestFailure>
  | ReturnType<typeof setMinorConsentDataRequest>
  | ReturnType<typeof setMinorConsentDataRequestSuccess>
  | ReturnType<typeof setMinorConsentDataRequestFailure>;

export const minorConsentActions = {
  getMinorConsentDataRequest,
  getMinorConsentDataRequestSuccess,
  getMinorConsentDataRequestFailure,
  setMinorConsentDataRequest,
  setMinorConsentDataRequestSuccess,
  setMinorConsentDataRequestFailure,
};

export type MinorConsentState = ReturnType<typeof minorConsentReducer>;
