import {useCallback, useEffect, useMemo, useState} from 'react';
import {format} from 'date-fns';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {selectHasShownMonthlyCompensationModalStatus} from 'features/Provider';
import {SliceStatus} from 'interfaces';
import {useSelector} from 'react-redux';
import {getFormattedMemberAppointmentDate} from 'services/Booking/helpers';

import {FormattedAppointmentType, useCalendar} from './useCalendar';

dayjs.extend(timezone);
dayjs.extend(utc);

export const useProviderSessionCheck = (
  appointmentID: number,
  appointmentTime: string,
) => {
  const {calendarAppointments, formatAppointment, calendarLoadingState} =
    useCalendar();
  const [isFirstAppointmentThisMonth, setIsFirstAppointmentThisMonth] =
    useState<boolean>(false);
  const hasShownMonthlyCompensationModal = useSelector(
    selectHasShownMonthlyCompensationModalStatus,
  );

  // Appointments Grouped by Month
  const appointmentsByMonth = useMemo(() => {
    return calendarAppointments.reduce<
      Record<string, FormattedAppointmentType[]>
    >((acc, curr) => {
      const localTime = getFormattedMemberAppointmentDate(curr);
      const formattedTime = format(localTime, 'yyyy-MM');

      if (!acc[formattedTime]) acc[formattedTime] = [];
      acc[formattedTime].push(formatAppointment(curr));

      return acc;
    }, {});
  }, [calendarAppointments, formatAppointment]);

  // Date-Time Formatting Function
  const formattedDatetime = useCallback(
    (date: Date, time: string, timezone: string) => {
      return dayjs.tz(
        `${format(date, 'yyyy-MM-dd')} ${time}`,
        'YYYY-MM-DD hh:mm A',
        timezone,
      );
    },
    [],
  );

  // Initial Appointment Filtering
  const getInitialAppointment = useCallback(
    (yearMonth: string) => {
      const yearMonthKey = format(new Date(yearMonth), 'yyyy-MM');
      const sortedAppointments = (appointmentsByMonth[yearMonthKey] || [])
        .filter(appt => !appt.canceled)
        .sort((a, b) => {
          const dateTimeA = formattedDatetime(
            a.localTime,
            a.startTime,
            a.timeZone,
          );
          const dateTimeB = formattedDatetime(
            b.localTime,
            b.startTime,
            b.timeZone,
          );
          return dateTimeA.unix() - dateTimeB.unix();
        });

      return sortedAppointments[0] ?? null;
    },
    [appointmentsByMonth, formattedDatetime],
  );

  // Effect to Check Initial Session
  useEffect(() => {
    if (calendarLoadingState !== SliceStatus.resolved || !appointmentID) return;

    setIsFirstAppointmentThisMonth(
      getInitialAppointment(appointmentTime)?.appointmentID === appointmentID,
    );
  }, [
    calendarLoadingState,
    appointmentID,
    getInitialAppointment,
    appointmentTime,
  ]);

  return {
    isFirstAppointmentThisMonth:
      isFirstAppointmentThisMonth && !hasShownMonthlyCompensationModal,
  };
};
