import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  ignoreElements,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import {ReferralService} from 'services/api/Referral';

import {providerActions} from '../providerSlice';

const sendReferralLinkToContactsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.sendReferralLinkToContacts.match),
    switchMap(({payload: {onSuccess, onError, ...rest}}) =>
      from(ReferralService.sendReferralLinkToContacts(rest.data)).pipe(
        concatMap(() => [onSuccess?.()]),
        mergeMap(() => [providerActions.sendReferralLinkToContactsSuccess()]),
        catchError((message: string) =>
          concat(
            of(providerActions.sendReferralLinkToContactsFailure(message)),
          ).pipe(tap(() => onError?.(message))),
        ),
      ),
    ),
  );

const sendReferralLinkToContactsFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.sendReferralLinkToContactsFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const ReferralEpics = [
  sendReferralLinkToContactsEpic,
  sendReferralLinkToContactsFailureEpic,
];
