import {RootState} from 'app/rootReducer';

import {createSelector} from '@reduxjs/toolkit';

import {WellniteArtState} from './wellniteArtSlice';

export function getWellniteArt(state: RootState): WellniteArtState {
  return state.wellniteArt;
}

export const selectWellniteArtId = createSelector(
  getWellniteArt,
  state => state.newWellniteArtId,
);

export const selectWellniteArt = createSelector(
  getWellniteArt,
  state => state.generatedWellniteArt,
);

export const selectWellniteArtConditionTags = createSelector(
  getWellniteArt,
  state => state.conditionTags,
);

export const selectWellniteArtQuestionnaire = createSelector(
  getWellniteArt,
  state => state.questionnaires,
);

export const selectWellniteArtStatus = createSelector(
  getWellniteArt,
  state => state.status,
);

export const selectWellniteArtImagesStatus = createSelector(
  getWellniteArt,
  state => state.imagesStatus,
);

export const selectWellniteArtForYou = createSelector(
  getWellniteArt,
  state => state.wellniteArtForYou,
);
