import React, {FC, useState} from 'react';
import {
  addMinutes,
  formatDistanceToNow,
  isAfter,
  isBefore,
  isWithinInterval,
} from 'date-fns';
import dayjs from 'dayjs';
import {chargeDigitalPracticeAppointment} from 'features/Appointment/Charge/chargeActions';
import {providerActions} from 'features/Provider';
import {userActions} from 'features/User';
import {
  AppointmentLabels,
  CountryOfResidenceType,
  CurrentUser,
  GroupCallAppointmentLabelsIDs,
  MemberProfile,
  PaypalStandardPaymentCountries,
  ProviderRole,
  SliceStatus,
} from 'interfaces';
import {ChargeStatus, Meeting} from 'interfaces/Meeting.types';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  convertDateObjToUTC,
  isDigitalPracticeOutOfPocketMember,
  isMember,
  isPaypalStandardPaymentCountries,
  isProvider,
  isTherapist,
} from 'utils';

import {
  selectAppointmentStatus,
  selectMeetingDetailsById,
} from '../appointmentSelectors';
import {appointmentActions} from '../appointmentSlice';

import {CalendarItemCardV1} from './CalendarItemCardV1';
import {CalendarItemCardV2} from './CalendarItemCardV2';
import {CalendarItemCardV3} from './CalendarItemCardV3';

import 'react-day-picker/lib/style.css';

export const isGroupCategory = (category: string) => {
  return [
    'Group-Call with Therapist',
    'Group-Call with Therapist-TEST',
  ].includes(category);
};

export const CalendarItem: FC<{
  startTime: string;
  patientId?: string;
  duration?: string;
  patientFirstName?: string;
  patientLastName?: string;
  appointmentID: number;
  classID?: number;
  notes?: string;
  labels: {id: number; name: string; color: string}[] | null;
  calendar: string;
  category: string;
  user: CurrentUser;
  providerId?: string;
  patientDetails?: MemberProfile;
  meeting?: Meeting;
  appointmentChargeCurrency?: string;
  localTime: Date;
  providerDetails?: {
    email: string;
    countryOfResidence: CountryOfResidenceType;
    role: ProviderRole;
  };
  participants?: {
    firstName: string;
    lastName: string;
    appointmentID: number;
    labels: AppointmentLabels[] | null;
  }[];
  canceled?: boolean;
  cancelAppointment(appointmentID: number): void;
  version: 'v1' | 'v2' | 'v3';
  isPastEvent?: (date: Date) => boolean | undefined;
}> = ({
  startTime,
  duration,
  patientFirstName,
  patientId,
  user,
  patientLastName,
  calendar,
  category,
  providerId,
  appointmentID,
  cancelAppointment,
  localTime,
  patientDetails,
  canceled,
  classID,
  participants,
  notes,
  labels,
  version,
  isPastEvent,
  providerDetails,
}) => {
  const handleCancel = () => {
    cancelAppointment(appointmentID);
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const meeting = useSelector(selectMeetingDetailsById(appointmentID));

  const appointmentStatus = useSelector(selectAppointmentStatus);

  const isLoading = appointmentStatus === SliceStatus.pending;
  const price = meeting?.appointmentCharge ?? 0;
  const chargeStatus = meeting?.chargeStatus;
  const appointmentChargeCurrency = meeting?.appointmentChargeCurrency;

  const [showChargeModal, setShowChargeModal] = useState(false);
  const [showPaymentRequestModal, setShowPaymentRequestModal] = useState(false);
  const [showSuccessPaymentModal, setShowSuccessPaymentModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [onCallPricingSkip, setOnCallPricingSkip] = useState(false);

  // set error
  const [showApptError, setShowApptError] = useState({
    message: '',
    show: false,
  });

  const [loadingState, setLoadingState] = useState({
    interest: false,
    joining: false,
  });

  // timeout ref
  const timeoutRef = React.useRef<NodeJS.Timeout>();

  const isPaypalStandardMember =
    user?.countryOfResidence?.code &&
    isPaypalStandardPaymentCountries(
      user.countryOfResidence.code as PaypalStandardPaymentCountries,
    );

  const callback = () => {
    setShowChargeModal(false);
    setShowSuccessModal(true);
  };

  const closeModal = () => {
    setShowChargeModal(false);
  };

  const onChargeClick = (updatedPrice?: number) => {
    const isDPOOPMember = isDigitalPracticeOutOfPocketMember(patientDetails);

    if (isTherapist(user) && isDPOOPMember && patientId) {
      dispatch(
        chargeDigitalPracticeAppointment({
          ...(updatedPrice !== price && {
            chargeAmount: updatedPrice,
            chargeCurrency: appointmentChargeCurrency,
          }),
          providerType: user.role,
          patientId,
          isDigitalPracticeOutOfPocketMember: isDPOOPMember,
          appointmentID: appointmentID.toString(),
          endDate: dayjs(localTime)
            .add(Number(duration), 'minute')
            .toISOString(),
          startDate: dayjs(localTime).toISOString(),
          callback,
          closeModal,
        }),
      );
    }
  };

  /* ./set price and charge logics */

  const isGroupCall = isGroupCategory(category);

  const getVideoLink = () => {
    let videoLink;

    if (isProvider(user)) {
      dispatch(providerActions.resetNotes());

      videoLink = isGroupCall
        ? `/video-call/embed/wb?provider=${
            user.role
          }&appointmentTime=${encodeURI(
            localTime.toUTCString(),
          )}&appointmentID=${classID}&duration=${duration}&roomMode=group`
        : `/video-call/embed/wb?provider=${
            user.role
          }&appointmentTime=${encodeURI(
            localTime.toUTCString(),
          )}&appointmentID=${appointmentID}&duration=${duration}&patientId=${patientId}&roomMode=normal`;
    } else {
      videoLink = isGroupCall
        ? `/video-call/embed/wb?provider=${
            category.toLowerCase() === 'doctor consultation'
              ? 'prescriber'
              : 'therapist'
          }&appointmentTime=${encodeURI(
            localTime.toUTCString(),
          )}&appointmentID=${classID}&providerId=${providerId}&roomMode=group`
        : `/video-call/embed/wb?provider=${
            category.toLowerCase() === 'doctor consultation'
              ? 'prescriber'
              : 'therapist'
          }&appointmentTime=${encodeURI(
            localTime.toUTCString(),
          )}&appointmentID=${appointmentID}&duration=${duration}&providerId=${providerId}&roomMode=normal`;
    }

    return videoLink;
  };

  function hasJoinedGroupCall(labels: AppointmentLabels[] | null) {
    if (!Array.isArray(labels)) {
      return false;
    }

    return labels.some(
      label => label.id === GroupCallAppointmentLabelsIDs.joined,
    );
  }

  const isNotInterestedInGroupCall =
    Array.isArray(labels) &&
    labels.length > 0 &&
    labels.some(
      label => label.id === GroupCallAppointmentLabelsIDs.notInterested,
    );

  const isInterestedInGroupCall =
    Array.isArray(labels) &&
    labels.length > 0 &&
    labels.length > 0 &&
    labels.some(label => label.id === GroupCallAppointmentLabelsIDs.interested);

  const isJoinedInGroupCall = hasJoinedGroupCall(labels);

  /**
   * @description group call not allowed conditions for member/patient
   * 1. isMember(user) && isGroupCall && labels is not an array
   * 2. isMember(user) && isGroupCall && member isn't interested in it
   */
  const isGroupCallNotAllowed =
    isMember(user) &&
    isGroupCall &&
    (!Array.isArray(labels) || isNotInterestedInGroupCall);

  const updateCallInterest = () => {
    const onSuccess = () => {
      setLoadingState(prevState => ({
        ...prevState,
        interest: false,
        joining: false,
      }));
    };

    setLoadingState(prevState => ({
      ...prevState,
      interest: true,
    }));

    if (labels === null || isNotInterestedInGroupCall) {
      dispatch(
        appointmentActions.updateGroupCallAppointmentLabel({
          labelID: GroupCallAppointmentLabelsIDs.interested,
          providerID: providerId!,
          appointmentID,
          onSuccess,
        }),
      );
    } else {
      dispatch(
        appointmentActions.updateGroupCallAppointmentLabel({
          labelID: GroupCallAppointmentLabelsIDs.notInterested,
          providerID: providerId!,
          appointmentID,
          onSuccess,
        }),
      );
    }
  };

  const onJoinClick = () => {
    const member = isMember(user) ? user : patientDetails;
    if (
      !isGroupCall &&
      providerDetails &&
      member &&
      providerDetails.countryOfResidence?.code !==
        member.countryOfResidence?.code
    ) {
      setLoadingState(prevState => ({
        ...prevState,
        joining: true,
      }));
      const callback = () => {
        setLoadingState(prevState => ({
          ...prevState,
          joining: false,
        }));
      };
      dispatch(
        userActions.handleCountryMismatch({
          patientEmail: member.email,
          providerEmail: providerDetails.email,
          providerRole: providerDetails.role,
          actionAttempted: 'video call',
          role: user!.role,
          cb: callback,
        }),
      );

      return;
    }

    const currTime = Date.now();

    // for group call
    const appointmentStartTime = convertDateObjToUTC(localTime);
    const appointmentEndTime = addMinutes(
      convertDateObjToUTC(localTime),
      Number(duration!),
    );

    const minTime = convertDateObjToUTC(localTime) - 120000;

    // Check if currTime is within the appointment time range
    const isDateInRange = isWithinInterval(currTime, {
      start: appointmentStartTime,
      end: appointmentEndTime,
    });

    // Check if currTime is before the appointment start time
    const isBeforeStartTime = isBefore(currTime, appointmentStartTime);

    // Check if currTime is after the appointment end time
    const isAfterEndTime = isAfter(currTime, appointmentEndTime);

    if (isGroupCall && isMember(user)) {
      if (!isDateInRange) {
        let message = '';

        if (isBeforeStartTime) {
          const sorryText = t(
            'calendar_item.join_ahead_of_schedule_text',
            `We're sorry, but you attempted to join the call ahead of the scheduled appointment time. Please try again closer to the appointment time.`,
          );
          const startDate = formatDistanceToNow(appointmentStartTime, {
            addSuffix: true,
            includeSeconds: true,
          });
          const appointmentStartsText = t(
            'calendar_item.appointment_starts_text',
            {
              startDate,
              defaultValue: `The Appointment starts {{ startDate }}`,
            },
          );

          message = `${sorryText}
          <br/>
          <p class="mt-5 font-light text-base text-gray-500">${appointmentStartsText}</p>`;
        } else if (isAfterEndTime) {
          const attemptToJoinAfterText = t(
            'calendar_item.attempt_to_join_after_text',
            'We apologize for the inconvenience, but it seems that your attempt to join the call occurred after the scheduled appointment time had passed. Please ensure you join calls on time to avoid any disruptions.',
          );
          const tookPlaceDate = formatDistanceToNow(appointmentEndTime, {
            addSuffix: true,
            includeSeconds: true,
          });
          const tookPlaceText = t('calendar_item.appointment_took_place_text', {
            date: tookPlaceDate,
            defaultValue: 'The appointment took place {{ date }}',
          });

          message = `${attemptToJoinAfterText}
            <br/>
            <p class="mt-5 font-light text-base text-gray-500">${tookPlaceText}</p>`;
        }

        if (message) {
          setShowApptError({
            message,
            show: true,
          });

          // remove error & close modal after 20s
          timeoutRef.current = setTimeout(() => {
            setShowApptError({
              message: '',
              show: false,
            });
            clearTimeout(timeoutRef.current);
          }, 20000);
        }
      } else if (!isJoinedInGroupCall) {
        setLoadingState(prevState => ({
          ...prevState,
          joining: true,
        }));

        const onSuccess = () => {
          setLoadingState(prevState => ({
            ...prevState,
            joining: false,
          }));
          history.push(getVideoLink());
        };

        dispatch(
          appointmentActions.updateGroupCallAppointmentLabel({
            labelID: GroupCallAppointmentLabelsIDs.joined,
            providerID: providerId!,
            appointmentID,
            onSuccess,
          }),
        );
      } else history.push(getVideoLink());
    } else if (isGroupCall && isTherapist(user)) {
      history.push(getVideoLink());
    } else {
      if (
        !price &&
        isTherapist(user) &&
        isDigitalPracticeOutOfPocketMember(patientDetails) &&
        onCallPricingSkip === false &&
        currTime < minTime &&
        !isPaypalStandardMember
      ) {
        setShowChargeModal(true);
      } else if (
        // Requesting payment logic for PayPal Standard Users i.e. South Africa, Chile and Columbia
        isMember(user) &&
        price &&
        chargeStatus !== ChargeStatus.charged &&
        user?.countryOfResidence?.code &&
        isPaypalStandardMember
      ) {
        setShowPaymentRequestModal(true);
      } else {
        history.push(getVideoLink());
      }
    }
  };

  const onCloseApptTooEarlyModal = () => {
    setShowApptError({
      message: '',
      show: false,
    });
    clearTimeout(timeoutRef.current);
  };

  const shouldShowCancelButton =
    !(isGroupCall && isProvider(user)) || (!isGroupCall && isProvider(user));

  let CalendarItemComponent;

  if (version === 'v1') {
    CalendarItemComponent = CalendarItemCardV1;
  } else if (version === 'v2') {
    CalendarItemComponent = CalendarItemCardV2;
  } else {
    CalendarItemComponent = CalendarItemCardV3;
  }

  return (
    <CalendarItemComponent
      startTime={startTime}
      appointmentID={appointmentID}
      labels={labels}
      calendar={calendar}
      category={category}
      user={user}
      localTime={localTime}
      cancelAppointment={cancelAppointment}
      price={price}
      shouldShowCancelButton={shouldShowCancelButton}
      handleCancel={handleCancel}
      isGroupCall={isGroupCall}
      updateCallInterest={updateCallInterest}
      isJoinedInGroupCall={isJoinedInGroupCall}
      loadingState={loadingState}
      isInterestedInGroupCall={isInterestedInGroupCall}
      isGroupCallNotAllowed={isGroupCallNotAllowed}
      onJoinClick={onJoinClick}
      chargeStatus={chargeStatus}
      onShowChargeModal={() => setShowChargeModal(true)}
      onChargeClick={onChargeClick}
      isLoading={isLoading}
      showApptError={showApptError}
      onCloseApptTooEarlyModal={onCloseApptTooEarlyModal}
      setOnCallPricingSkip={setOnCallPricingSkip}
      patientId={patientId}
      duration={duration}
      patientFirstName={patientFirstName}
      patientLastName={patientLastName}
      classID={classID}
      videoLink={getVideoLink()}
      notes={notes}
      providerId={providerId}
      patientDetails={patientDetails}
      meeting={meeting}
      appointmentChargeCurrency={appointmentChargeCurrency}
      participants={participants}
      canceled={canceled}
      setShowSuccessModal={setShowSuccessModal}
      setShowChargeModal={setShowChargeModal}
      showSuccessModal={showSuccessModal}
      showChargeModal={showChargeModal}
      isPastEvent={isPastEvent}
      onShowPaymentRequestModal={() => setShowPaymentRequestModal(true)}
      setShowPaymentRequestModal={setShowPaymentRequestModal}
      showPaymentRequestModal={showPaymentRequestModal}
      setShowSuccessPaymentModal={setShowSuccessPaymentModal}
      showSuccessPaymentModal={showSuccessPaymentModal}
    />
  );
};
