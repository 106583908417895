import {
  MoodTrackerData,
  MoodTrackerEntriesResponse,
  MoodTrackerEntry,
  MoodTrackerStatsResponse,
  StatsTimePeriod,
} from 'interfaces/MoodTracker.types';
import {
  ProgressAssessmentAllStatsResponse,
  ProgressAssessmentData,
  ProgressAssessmentStatsResponse,
} from 'interfaces/ProgressAssessment.types';
import {ProgressAssessmentService} from 'services/api';

import {AsyncThunk, createAction, createAsyncThunk} from '@reduxjs/toolkit';

export const getMoodTrackerEntries = createAction<{
  email: string;
  cursor?: string | null;
  reset?: boolean;
}>('getMoodTrackerEntries');
export const getMoodTrackerEntriesFailure = createAction<string>(
  'getMoodTrackerEntriesFailure',
);
export const getMoodTrackerEntriesSuccess =
  createAction<MoodTrackerEntriesResponse>('getMoodTrackerEntriesSuccess');

export const createMoodTrackerEntry = createAction<
  MoodTrackerData & {
    onSuccess?: VoidFunction;
  }
>('createMoodTrackerEntry');
export const createMoodTrackerEntryFailure = createAction<string>(
  'createMoodTrackerEntryFailure',
);
export const createMoodTrackerEntrySuccess = createAction<MoodTrackerEntry>(
  'createMoodTrackerEntrySuccess',
);

export const getMoodTrackerStats = createAction<{
  email: string;
  period: StatsTimePeriod;
}>('getMoodTrackerStats');
export const getMoodTrackerStatsFailure = createAction<{
  email: string;
  period: StatsTimePeriod;
  error: string;
}>('getMoodTrackerStatsFailure');
export const getMoodTrackerStatsSuccess = createAction<{
  email: string;
  period: StatsTimePeriod;
  data: MoodTrackerStatsResponse;
}>('getMoodTrackerStatsSuccess');

export const updateProgressAssessment: AsyncThunk<
  boolean,
  ProgressAssessmentData,
  {
    rejectValue: string;
  }
> = createAsyncThunk<
  boolean,
  ProgressAssessmentData,
  {
    rejectValue: string;
  }
>(
  'updateProgressAssessment',
  async (data: ProgressAssessmentData, {rejectWithValue}) => {
    try {
      return await ProgressAssessmentService.updateProgressAssessmentEntry(
        data,
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getProgressAssessmentStats = createAction<{
  email: string;
  period: StatsTimePeriod;
  type: string;
}>('getProgressAssessmentStats');
export const getProgressAssessmentStatsFailure = createAction<{
  email: string;
  type: string;
  period: StatsTimePeriod;
  error: string;
}>('getProgressAssessmentStatsFailure');
export const getProgressAssessmentStatsSuccess = createAction<{
  email: string;
  type: string;
  period: StatsTimePeriod;
  data: ProgressAssessmentStatsResponse;
}>('getProgressAssessmentStatsSuccess');

export const getAllProgressAssessmentStats = createAction<{
  email: string;
  period: StatsTimePeriod;
}>('getAllProgressAssessmentStats');
export const getAllProgressAssessmentStatsFailure = createAction<{
  email: string;
  period: StatsTimePeriod;
  error: string;
}>('getAllProgressAssessmentStatsFailure');
export const getAllProgressAssessmentStatsSuccess = createAction<{
  email: string;
  period: StatsTimePeriod;
  data: ProgressAssessmentAllStatsResponse;
}>('getAllProgressAssessmentStatsSuccess');
