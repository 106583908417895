import {MemberProfile} from 'interfaces';
import {
  GetMinorConsentRequest,
  GetMinorConsentResponse,
  SetMinorConsentDataRequest,
  SetMinorConsentResponse,
} from 'interfaces/MinorConsent.types';
import {MinorConsentUpdateData} from 'interfaces/Pages/MinorConsent.types';

import {createAction} from '@reduxjs/toolkit';

export const updateMinorConsent =
  createAction<MinorConsentUpdateData>('UpdateMinorConsent');

export const updateMinorConsentSuccess = createAction<{message: MemberProfile}>(
  'UpdateMinorConsentSuccess',
);
export const updateMinorConsentFailure = createAction(
  'UpdateMinorConsentFailure',
);

export const updateMinorConsentStep = createAction<{
  step: number;
  skip: boolean;
}>('UpdateMinorStepConsent');

export const updateMinorConsentStepSuccess = createAction<{
  message: MemberProfile;
}>('UpdateMinorConsentStepSuccess');
export const updateMinorConsentStepFailure = createAction(
  'UpdateMinorConsentStepFailure',
);
export const goToPreviousMinorConsentStep = createAction(
  'GoToPreviousMinorConsentStep',
);

export const setMinorConsentStep = createAction<number>('SetMinorConsentStep');

export const getMinorConsentDataRequest = createAction<GetMinorConsentRequest>(
  'GetMinorConsentDataRequest',
);

export const getMinorConsentDataRequestSuccess =
  createAction<GetMinorConsentResponse>('GetMinorConsentDataRequestSuccess');

export const getMinorConsentDataRequestFailure = createAction<string>(
  'GetMinorConsentDataRequestFailure',
);

export const setMinorConsentDataRequest =
  createAction<SetMinorConsentDataRequest>('SetMinorConsentDataRequest');

export const setMinorConsentDataRequestSuccess =
  createAction<SetMinorConsentResponse>('SetMinorConsentDataRequestSuccess');

export const setMinorConsentDataRequestFailure = createAction<string>(
  'SetMinorConsentDataRequestFailure',
);
