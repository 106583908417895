import {useEffect, useState} from 'react';
import {selectMemberStatus} from 'features/Member';
import {selectUserProfile} from 'features/User';
import {
  DigitalPracticeAccountTypes,
  MemberProfile,
  SliceStatus,
  SocialOnboardingStatusEnum,
  StripePlanNames,
  UserAccountType,
} from 'interfaces';
import {useSelector} from 'react-redux';
import {
  isInsuranceCompanyTypeNone,
  isInsuranceOnboardingCompleted,
  isOboardingStatusAcceptedOrCompleted,
  isOldDPInsuranceMember,
  isOldInsuranceMember,
  isOldInsuranceMemberWithOldInsuranceList,
  isPhoneVerified,
  isUserFromUnitedStates,
} from 'utils';

export const useMemberOnboarding = (): {
  displayInsuranceModal: boolean;
  displayPhoneVerificationModal: boolean;
  displayMinorConsent: boolean;
  userOnboardingStage?: number;
  userOnboardingStatus?: SocialOnboardingStatusEnum;
  setDisplayInsuranceModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDisplayPhoneVerificationModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setUserOnboardingStage: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  memberStatus: SliceStatus;
} => {
  const [displayPhoneVerificationModal, setDisplayPhoneVerificationModal] =
    useState(false);
  const [displayInsuranceModal, setDisplayInsuranceModal] = useState(false);
  const user = useSelector(selectUserProfile) as MemberProfile;
  const memberStatus = useSelector(selectMemberStatus);
  const [userOnboardingStatus, setUserOnboardingStatus] = useState(
    user?.insuranceDetails?.onboardingStatus,
  );
  const [userOnboardingStage, setUserOnboardingStage] = useState(
    user?.insuranceDetails?.onboardStage,
  );
  const [displayMinorConsent, setDisplayMinorConsent] = useState(false);

  useEffect(() => {
    if (user) {
      const shouldDisplayPhoneVerificationModal = !isPhoneVerified(user);
      setDisplayPhoneVerificationModal(shouldDisplayPhoneVerificationModal);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      if (!isUserFromUnitedStates(user)) {
        setDisplayInsuranceModal(false);
      } else {
        if (
          isOldInsuranceMemberWithOldInsuranceList(
            user?.insuranceDetails?.companyId,
          )
        ) {
          setDisplayInsuranceModal(true);
        } else if (user?.gender && isInsuranceCompanyTypeNone(user)) {
          setDisplayInsuranceModal(false);
        } else if (user?.gender && isOldDPInsuranceMember(user)) {
          setDisplayInsuranceModal(false);
        } else if (user?.gender && isOldInsuranceMember(user)) {
          setDisplayInsuranceModal(false);
        } else if (
          user?.gender &&
          isInsuranceOnboardingCompleted(user) &&
          isOboardingStatusAcceptedOrCompleted(user)
        ) {
          setDisplayInsuranceModal(false);
        } else if (
          user?.paymentPlan !== StripePlanNames.together &&
          user?.accountType === UserAccountType.scale &&
          user?.digitalPracticeAccountType !==
            DigitalPracticeAccountTypes.insurance
        ) {
          setDisplayInsuranceModal(false);
        } else if (
          user?.paymentPlan !== StripePlanNames.together &&
          user?.accountType === UserAccountType.wellnite
        ) {
          setDisplayInsuranceModal(false);
        } else {
          setDisplayInsuranceModal(true);
        }
      }
    }
  }, [user]);
  useEffect(() => {
    if (user) {
      setUserOnboardingStatus(user?.insuranceDetails?.onboardingStatus);
      setUserOnboardingStage(user?.insuranceDetails?.onboardStage);
    }
  }, [user]);
  useEffect(() => {
    if (user) {
      if (
        (user?.isPhoneVerified &&
          user?.whereFrom &&
          user?.paymentPlan !== StripePlanNames.together &&
          user.minorConsent?.showMinorConsentForm) ||
        (user?.paymentPlan === StripePlanNames.together &&
          user?.insuranceDetails?.onboardingStatus ===
            SocialOnboardingStatusEnum.complete &&
          user.minorConsent?.showMinorConsentForm)
      ) {
        setDisplayMinorConsent(true);
      } else {
        setDisplayMinorConsent(false);
      }
    }
  }, [user]);

  return {
    displayPhoneVerificationModal,
    displayInsuranceModal,
    setDisplayInsuranceModal,
    setDisplayPhoneVerificationModal,
    userOnboardingStatus,
    userOnboardingStage,
    setUserOnboardingStage,
    memberStatus,
    displayMinorConsent,
  };
};
