import {
  GenerateImageFromPostSessionQuestionnaireResponse,
  WellniteArtImage,
} from 'interfaces';

export function getShareUrl(imageId: string) {
  const hostname = window?.location?.hostname || '';
  if (hostname.includes('www.wellnite.co')) {
    return `https://www.wellnite.com/gallery/${imageId}`;
  }

  return `https://wellnite.vercel.app/gallery/${imageId}?_vercel_share=7YCm3tJ1Az02dm5xDtLFrePvAAQV6X1l`;
}

export function getCopyContent(
  image: WellniteArtImage | GenerateImageFromPostSessionQuestionnaireResponse,
) {
  const url = getShareUrl(image._id);
  return image.generatedImageTitle + ' ' + url;
}
