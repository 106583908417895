import React from 'react';

import {Dialog} from '@headlessui/react';

interface DialogContainerProps {
  children: React.ReactNode;
}

export function DialogContainer({
  children,
}: DialogContainerProps): React.ReactElement {
  return (
    <Dialog open={true} onClose={() => {}}>
      <div className="fixed inset-0 flex md:items-center justify-center z-999">
        <Dialog.Panel className="mx-auto w-full h-full bg-white relative flex flex-col">
          <div className="mx-auto xs:pb-10 h-full w-full flex flex-grow flex-col">
            {children}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
