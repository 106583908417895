import React from 'react';

import {SvgIcon} from './SvgIcon';

export const WarningOrangeIcon: SvgIcon = ({
  width = 127,
  height = 104,
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 127 104"
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
  >
    <path
      d="M48.568 7.77563C55.5047 -2.59188 70.7475 -2.59188 77.6841 7.77563L123.265 75.8997C131.052 87.5382 122.71 103.156 108.706 103.156H17.5457C3.54239 103.156 -4.79947 87.5382 2.98758 75.8997L48.568 7.77563Z"
      fill="url(#paint0_linear_9704_689)"
    />
    <path
      d="M60.5673 67.5512L58.9558 36.1273H68.8933L67.2147 67.5512H60.5673ZM63.9246 83.5989C62.3578 83.5989 61.0597 83.0841 60.0301 82.0546C59.0006 81.025 58.4858 79.794 58.4858 78.3616C58.4858 76.9292 59.0006 75.7206 60.0301 74.7358C61.0597 73.7062 62.3578 73.1914 63.9246 73.1914C65.4913 73.1914 66.767 73.7062 67.7518 74.7358C68.7366 75.7206 69.229 76.9292 69.229 78.3616C69.229 79.794 68.7366 81.025 67.7518 82.0546C66.767 83.0841 65.4913 83.5989 63.9246 83.5989Z"
      fill="black"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9704_689"
        x1="63.126"
        y1="-13.9828"
        x2="63.126"
        y2="142.203"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6805B" />
        <stop offset="1" stopColor="#FBEBFF" />
      </linearGradient>
    </defs>
  </svg>
);
