import React from 'react';

import {SvgIcon} from './SvgIcon';

export const CheckMarkIcon: SvgIcon = ({width = 20, height = 20}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 124 157"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 134">
      <g id="Group 132">
        <g id="Group 131">
          <path
            id="Vector"
            d="M93.9358 80.1334C93.8887 79.3881 93.5928 78.6802 93.0957 78.1229C92.5985 77.5657 91.9287 77.1913 91.1936 77.0598C73.1555 73.8235 65.6251 71.4839 48.7923 63.8811C48.3605 63.6859 47.892 63.585 47.4181 63.585C46.9442 63.585 46.4757 63.6859 46.0439 63.8811C29.2111 71.4839 21.6807 73.8235 3.64262 77.0598C2.90748 77.1913 2.23771 77.5657 1.74054 78.1229C1.24337 78.6802 0.947538 79.3881 0.900401 80.1334C0.0975482 92.8763 1.80964 104.75 5.99489 115.424C9.42232 124.131 14.4945 132.096 20.9343 138.885C32.0845 150.714 43.9231 155.853 46.1815 156.762C46.984 157.086 47.8814 157.086 48.6839 156.762C50.9423 155.853 62.7809 150.714 73.9311 138.885C80.3604 132.094 85.4226 124.128 88.8413 115.424C93.0266 104.75 94.7387 92.8763 93.9358 80.1334Z"
            fill="#FFC839"
          />
          <path
            id="Vector_2"
            d="M89.6091 82.9388C89.5664 82.2628 89.2981 81.6207 88.8472 81.1153C88.3962 80.6099 87.7888 80.2703 87.122 80.151C70.7619 77.2158 63.932 75.0938 48.6651 68.1983C48.2734 68.0212 47.8485 67.9297 47.4187 67.9297C46.9889 67.9297 46.564 68.0212 46.1723 68.1983C30.9054 75.0938 24.0755 77.2158 7.71534 80.151C7.04858 80.2703 6.44111 80.6099 5.99019 81.1153C5.53927 81.6207 5.27096 82.2628 5.22821 82.9388C4.50004 94.4963 6.05286 105.265 9.84879 114.946C12.9574 122.843 17.5577 130.068 23.3985 136.225C33.5115 146.954 44.2488 151.614 46.2971 152.439C47.025 152.733 47.8388 152.733 48.5667 152.439C50.6151 151.614 61.3523 146.954 71.4654 136.225C77.2966 130.065 81.8879 122.841 84.9886 114.946C88.7845 105.265 90.3373 94.4963 89.6091 82.9388Z"
            fill="#FF85C3"
          />
          <path
            id="Vector_3"
            d="M74.4677 92.374C74.4414 91.9581 74.2763 91.5629 73.9988 91.2519C73.7213 90.9409 73.3475 90.7319 72.9372 90.6585C62.8694 88.8522 58.6664 87.5464 49.2714 83.303C49.0303 83.194 48.7689 83.1377 48.5043 83.1377C48.2398 83.1377 47.9784 83.194 47.7373 83.303C38.3423 87.5464 34.1393 88.8522 24.0715 90.6585C23.6612 90.7319 23.2874 90.9409 23.0099 91.2519C22.7324 91.5629 22.5673 91.9581 22.541 92.374C22.0929 99.4864 23.0485 106.113 25.3844 112.071C27.2974 116.931 30.1284 121.376 33.7227 125.166C39.9461 131.768 46.5536 134.636 47.8141 135.143C48.2621 135.324 48.7629 135.324 49.2108 135.143C50.4713 134.636 57.0789 131.768 63.3023 125.166C66.8908 121.375 69.7162 116.929 71.6243 112.071C73.9602 106.113 74.9158 99.4864 74.4677 92.374Z"
            fill="white"
          />
          <path
            id="Vector_4"
            d="M85.2863 85.7451C85.249 85.1384 85.0151 84.5622 84.622 84.1086C84.2289 83.655 83.6993 83.3503 83.118 83.2433C68.8553 80.6091 62.9012 78.7047 49.5915 72.5164C49.25 72.3576 48.8796 72.2754 48.5048 72.2754C48.1301 72.2754 47.7597 72.3576 47.4182 72.5164C34.1085 78.7047 28.1544 80.6091 13.8917 83.2433C13.3104 83.3503 12.7808 83.655 12.3877 84.1086C11.9946 84.5622 11.7607 85.1384 11.7234 85.7451C11.0886 96.1172 12.4423 105.782 15.7516 114.47C18.4617 121.557 22.4722 128.04 27.5641 133.566C36.3807 143.195 45.7413 147.377 47.5271 148.117C48.1616 148.381 48.8711 148.381 49.5057 148.117C51.2914 147.377 60.6521 143.195 69.4686 133.566C74.5523 128.038 78.5549 121.555 81.2581 114.47C84.5674 105.782 85.9211 96.1172 85.2863 85.7451ZM63.686 98.4934L45.4165 120.219C45.1816 120.499 44.8935 120.727 44.57 120.888C44.2465 121.049 43.8943 121.141 43.5351 121.157H43.4263C42.7358 121.157 42.0728 120.879 41.5796 120.382L33.4671 112.201C33.2196 111.951 33.0223 111.654 32.8865 111.326C32.7506 110.997 32.6789 110.645 32.6754 110.288C32.6682 109.568 32.9394 108.874 33.4292 108.36C33.919 107.845 34.5873 107.552 35.287 107.545C35.9868 107.537 36.6608 107.816 37.1606 108.321L43.2614 114.48L59.7007 94.9393C59.9274 94.6696 60.2035 94.4485 60.5132 94.2886C60.8229 94.1287 61.1602 94.0332 61.5058 94.0076C61.8514 93.9819 62.1985 94.0265 62.5273 94.1389C62.8561 94.2513 63.1602 94.4292 63.4222 94.6626C63.6842 94.896 63.899 95.1802 64.0543 95.499C64.2096 95.8178 64.3024 96.165 64.3273 96.5207C64.3523 96.8764 64.3089 97.2337 64.1998 97.5722C64.0906 97.9107 63.9177 98.2237 63.691 98.4934H63.686Z"
            fill="#2E62EC"
          />
        </g>
      </g>
      <circle
        id="Ellipse 180"
        cx="111.292"
        cy="32.9961"
        r="12"
        fill="#FF85C3"
      />
      <circle id="Ellipse 181" cx="89.292" cy="11.9961" r="7" fill="white" />
      <circle id="Ellipse 182" cx="116.292" cy="4.99609" r="5" fill="#FFC839" />
    </g>
  </svg>
);
