import * as Yup from 'yup';

export const questionnaireValidationSchema = Yup.object().shape({
  question1: Yup.string()
    .required('Answer one is required')
    .min(1, 'Minimum of 1 characters allowed')
    .max(280, 'Maximum of 120 characters allowed'),
  question2: Yup.string()
    .required('Answer two is required')
    .min(1, 'Minimum of 40 characters allowed')
    .max(280, 'Maximum of 120 characters allowed'),
  question3: Yup.string()
    .required('Answer three is required')
    .min(1, 'Minimum of 40 characters allowed')
    .max(280, 'Maximum of 120 characters allowed'),
});

export const artValidationSchema = Yup.object().shape({
  caption: Yup.string()
    .required('Caption is required')
    .min(1, 'Minimum of 1 characters allowed')
    .max(280, 'Maximum of 120 characters allowed'),
  title: Yup.string()
    .required('Title is required')
    .min(1, 'Minimum of 40 characters allowed')
    .max(280, 'Maximum of 120 characters allowed'),
});
