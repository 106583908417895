import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {userActions} from 'features/User';
import {t} from 'i18next';
import {Epic} from 'redux-observable';
import {concat, from, of, tap} from 'rxjs';
import {catchError, filter, ignoreElements, mergeMap} from 'rxjs/operators';
import {WellniteArtService} from 'services';

import {wellniteArtActions} from './wellniteArtSlice';

const generateWellniteArtQuestionnaireEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.generateWellniteArtQuestionnaire.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(WellniteArtService.generatePostSessionQuestionnaire(payload)).pipe(
        mergeMap(res =>
          of(
            wellniteArtActions.generateWellniteArtQuestionnaireSuccess(
              res.data.message,
            ),
          ).pipe(tap(() => onSuccess?.(res.data.message))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteArt',
                message,
              }),
            ),
            of(
              wellniteArtActions.generateWellniteArtQuestionnaireFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

const generateWellniteArtQuestionnaireFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.generateWellniteArtQuestionnaireFailure.match),
    tap(() => {
      Toast({
        type: 'warning',
        message: t('wellniteArt.errorGenerating'),
      });
    }),
    ignoreElements(),
  );

const generateWellniteArtImageEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.generateWellniteArtImage.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(
        WellniteArtService.generateImageFromPostSessionQuestionnaire(payload),
      ).pipe(
        mergeMap(res =>
          of(
            wellniteArtActions.generateWellniteArtImageSuccess(
              res.data.message,
            ),
          ).pipe(tap(() => onSuccess?.(res.data.message))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteArt',
                message,
              }),
            ),
            of(wellniteArtActions.generateWellniteArtImageFailure(message)),
          ),
        ),
      ),
    ),
  );

const generateWellniteArtFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.generateWellniteArtImageFailure.match),
    tap(() => {
      Toast({
        type: 'warning',
        message: t('wellniteArt.errorGenerating'),
      });
    }),
    ignoreElements(),
  );

const getPatientWellniteArtsGalleryEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.getPatientWellniteArtsGallery.match),
    mergeMap(() =>
      from(WellniteArtService.getPatientWellniteArts()).pipe(
        mergeMap(res => [
          wellniteArtActions.getPatientWellniteArtsGallerySuccess(
            res.data.message,
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteArt',
                message,
              }),
            ),
            of(
              wellniteArtActions.getPatientWellniteArtsGalleryFailure(message),
            ),
          ),
        ),
      ),
    ),
  );

const recordWellniteArtsReactionEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.recordWellniteArtReaction.match),
    mergeMap(({payload}) =>
      from(WellniteArtService.recordWellniteArtReaction(payload)).pipe(
        mergeMap(res => [
          wellniteArtActions.recordWellniteArtSuccess(res.data.message),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteArt',
                message,
              }),
            ),
            of(wellniteArtActions.likeOrDislikeWellniteArtFailure(message)),
          ),
        ),
      ),
    ),
  );

const getPatientWellniteArtsGalleryFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.getPatientWellniteArtsGalleryFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const getPaginatedWellniteArtsGalleryEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.getPaginatedWellniteArtsGallery.match),
    mergeMap(({payload}) =>
      from(WellniteArtService.getPaginatedWellniteArtsGallery(payload)).pipe(
        mergeMap(res => [
          wellniteArtActions.getPaginatedWellniteArtsGallerySuccess(
            res.data.message,
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteArt',
                message,
              }),
            ),
            of(
              wellniteArtActions.getPaginatedWellniteArtsGalleryFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

const getPaginatedWellniteArtsGalleryFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.getPaginatedWellniteArtsGalleryFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const searchWellniteArtsGalleryEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.searchWellniteArtsGallery.match),
    mergeMap(({payload}) =>
      from(WellniteArtService.searchWellniteArtsGallery(payload)).pipe(
        mergeMap(res => [
          wellniteArtActions.searchWellniteArtsGallerySuccess(res.data.message),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteArt',
                message,
              }),
            ),
            of(wellniteArtActions.searchWellniteArtsGalleryFailure(message)),
          ),
        ),
      ),
    ),
  );

const searchWellniteArtsGalleryFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.searchWellniteArtsGalleryFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const getWellniteArtForYouEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.getWellniteArtForYou.match),
    mergeMap(() =>
      from(WellniteArtService.getWellniteArtForYou()).pipe(
        mergeMap(res => [
          wellniteArtActions.getWellniteArtForYouSuccess(res.data.message),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteArt',
                message,
              }),
            ),
            of(wellniteArtActions.getWellniteArtForYouFailure(message)),
          ),
        ),
      ),
    ),
  );

const getWellniteArtForYouFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.getWellniteArtForYouFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const generateIndividualQuestionEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.generateIndividualQuestion.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(WellniteArtService.generateIndividualQuestion(payload)).pipe(
        mergeMap(res =>
          of(
            wellniteArtActions.generateIndividualQuestionSuccess(
              res.data.message,
            ),
          ).pipe(tap(() => onSuccess?.(res.data.message))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteArt',
                message,
              }),
            ),
            of(wellniteArtActions.generateIndividualQuestionFailure(message)),
          ),
        ),
      ),
    ),
  );

const generateIndividualQuestionFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.generateIndividualQuestionFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const regenerateWellniteArtCaptionEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.regenerateWellniteArtCaption.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(WellniteArtService.regenerateWellniteArtCaption(payload)).pipe(
        mergeMap(res =>
          of(
            wellniteArtActions.regenerateWellniteArtCaptionSuccess(
              res.data.message,
            ),
          ).pipe(tap(() => onSuccess?.(res.data.message))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteArt',
                message,
              }),
            ),
            of(wellniteArtActions.regenerateWellniteArtCaptionFailure(message)),
          ),
        ),
      ),
    ),
  );

const regenerateWellniteArtCaptionFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.regenerateWellniteArtCaptionFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const getPatientLikedWellniteArtsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.getPatientLikedWellniteArts.match),
    mergeMap(() =>
      from(WellniteArtService.getPatientLikedWellniteArts()).pipe(
        mergeMap(res => [
          wellniteArtActions.getPatientLikedWellniteArtsSuccess(
            res.data.message,
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteArt',
                message,
              }),
            ),
            of(wellniteArtActions.getPatientLikedWellniteArtsFailure(message)),
          ),
        ),
      ),
    ),
  );

const getPatientLikedWellniteArtsFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.getPatientLikedWellniteArtsFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const getWellniteArtConditionTagsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.getWellniteArtConditionTags.match),
    mergeMap(({payload}) =>
      from(WellniteArtService.getWellniteArtConditionTags(payload)).pipe(
        mergeMap(res => [
          wellniteArtActions.getWellniteArtConditionTagsSuccess(
            res.data.message,
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteArt',
                message,
              }),
            ),
            of(wellniteArtActions.getWellniteArtConditionTagsFailure(message)),
          ),
        ),
      ),
    ),
  );

const getWellniteArtConditionTagsFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.getWellniteArtConditionTagsFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const generateWellniteArtTitleEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.generateWellniteArtTitle.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(WellniteArtService.regenerateWellniteArtTitle(payload)).pipe(
        mergeMap(res =>
          of(
            wellniteArtActions.generateWellniteArtTitleSuccess(
              res.data.message,
            ),
          ).pipe(tap(() => onSuccess?.(res.data.message))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteArt',
                message,
              }),
            ),
            of(wellniteArtActions.generateWellniteArtTitleFailure(message)),
          ),
        ),
      ),
    ),
  );

const generateWellniteArtTitleFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.generateWellniteArtTitleFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const getWellniteArtByIdEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.getWellniteArtById.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(WellniteArtService.getWellniteArtById(payload)).pipe(
        mergeMap(res =>
          of(
            wellniteArtActions.getWellniteArtByIdSuccess(res.data.message),
          ).pipe(tap(() => onSuccess?.(res.data.message))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteArt',
                message,
              }),
            ),
            of(wellniteArtActions.getWellniteArtByIdFailure(message)),
          ),
        ),
      ),
    ),
  );

const getWellniteArtByIdFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.getWellniteArtByIdFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const updateWellniteArtInfoEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.updateWellniteArtInfo.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(WellniteArtService.updateWellniteArtInfo(payload)).pipe(
        mergeMap(res =>
          of(
            wellniteArtActions.updateWellniteArtInfoSuccess(res.data.message),
          ).pipe(tap(() => onSuccess?.(res.data.message))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteArt',
                message,
              }),
            ),
            of(wellniteArtActions.updateWellniteArtInfoFailure(message)),
          ),
        ),
      ),
    ),
  );

const updateWellniteArtInfoFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteArtActions.updateWellniteArtInfoFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

export const wellniteArtEpics = [
  generateWellniteArtQuestionnaireEpic,
  generateWellniteArtQuestionnaireFailureEpic,
  generateWellniteArtImageEpic,
  generateWellniteArtFailureEpic,
  getPaginatedWellniteArtsGalleryEpic,
  getPaginatedWellniteArtsGalleryFailureEpic,
  getPatientWellniteArtsGalleryEpic,
  getPatientWellniteArtsGalleryFailureEpic,
  getWellniteArtForYouEpic,
  getWellniteArtForYouFailureEpic,
  searchWellniteArtsGalleryEpic,
  searchWellniteArtsGalleryFailureEpic,
  recordWellniteArtsReactionEpic,
  generateIndividualQuestionEpic,
  generateIndividualQuestionFailureEpic,
  regenerateWellniteArtCaptionEpic,
  regenerateWellniteArtCaptionFailureEpic,
  getPatientLikedWellniteArtsEpic,
  getPatientLikedWellniteArtsFailureEpic,
  getWellniteArtConditionTagsEpic,
  getWellniteArtConditionTagsFailureEpic,
  generateWellniteArtTitleEpic,
  generateWellniteArtTitleFailureEpic,
  getWellniteArtByIdEpic,
  getWellniteArtByIdFailureEpic,
  updateWellniteArtInfoEpic,
  updateWellniteArtInfoFailureEpic,
].flatMap(epic => epic);
