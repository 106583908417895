import {userActions} from 'features/User';
import {
  GeneratedQuestion,
  GenerateImageFromPostSessionQuestionnaireResponse,
  SliceStatus,
  WellniteArtImage,
} from 'interfaces';

import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  generateIndividualQuestion,
  generateIndividualQuestionFailure,
  generateIndividualQuestionSuccess,
  generateWellniteArtImage,
  generateWellniteArtImageFailure,
  generateWellniteArtImageSuccess,
  generateWellniteArtQuestionnaire,
  generateWellniteArtQuestionnaireFailure,
  generateWellniteArtQuestionnaireSuccess,
  generateWellniteArtTitle,
  generateWellniteArtTitleFailure,
  generateWellniteArtTitleSuccess,
  getPaginatedWellniteArtsGallery,
  getPaginatedWellniteArtsGalleryFailure,
  getPaginatedWellniteArtsGallerySuccess,
  getPatientLikedWellniteArts,
  getPatientLikedWellniteArtsFailure,
  getPatientLikedWellniteArtsSuccess,
  getPatientWellniteArtsGallery,
  getPatientWellniteArtsGalleryFailure,
  getPatientWellniteArtsGallerySuccess,
  getWellniteArtById,
  getWellniteArtByIdFailure,
  getWellniteArtByIdSuccess,
  getWellniteArtConditionTags,
  getWellniteArtConditionTagsFailure,
  getWellniteArtConditionTagsSuccess,
  getWellniteArtForYou,
  getWellniteArtForYouFailure,
  getWellniteArtForYouSuccess,
  likeOrDislikeWellniteArtFailure,
  recordWellniteArtReaction,
  recordWellniteArtSuccess,
  regenerateWellniteArtCaption,
  regenerateWellniteArtCaptionFailure,
  regenerateWellniteArtCaptionSuccess,
  searchWellniteArtsGallery,
  searchWellniteArtsGalleryFailure,
  searchWellniteArtsGallerySuccess,
  updateWellniteArtInfo,
  updateWellniteArtInfoFailure,
  updateWellniteArtInfoSuccess,
} from './wellniteArtActions';

export type WellniteArtSliceState = {
  status: SliceStatus;
  formStep?: string;
  regenerateStatus: {[key: string]: SliceStatus};
  generatedWellniteArt?: GenerateImageFromPostSessionQuestionnaireResponse;
  newWellniteArtId?: string;
  conditionTags: {name: string; _id: string}[];
  questionnaires: GeneratedQuestion[];
  images: WellniteArtImage[];
  wellniteArtForYou: WellniteArtImage[];
  imagesStatus: SliceStatus;
  error: string;
};

export const wellniteArtSliceInitialState: WellniteArtSliceState = {
  status: SliceStatus.idle,
  images: [],
  conditionTags: [],
  wellniteArtForYou: [],
  imagesStatus: SliceStatus.idle,
  regenerateStatus: {},
  questionnaires: [],
  error: '',
};

const wellniteArtSlice = createSlice({
  name: 'wellniteArt',
  initialState: wellniteArtSliceInitialState as WellniteArtSliceState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<WellniteArtSliceState>) =>
    builder
      .addCase(userActions.setAsyncError, (state, action) => ({
        ...state,
        error:
          action.payload.filter === 'wellniteArt'
            ? action.payload.message
            : state.error,
      }))
      .addCase(userActions.resetAsyncError, (state, action) => ({
        ...state,
        error: action.payload === 'wellniteArt' ? '' : state.error,
      }))
      .addCase(generateWellniteArtQuestionnaire, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(generateWellniteArtQuestionnaireSuccess, (state, action) => ({
        ...state,
        newWellniteArtId: action.payload._id,
        questionnaires: action.payload.questionnaires,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(generateWellniteArtQuestionnaireFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(generateIndividualQuestionSuccess, (state, payload) => ({
        ...state,
        questionnaires: payload.payload.questionnaires,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(generateWellniteArtImage, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(generateWellniteArtImageSuccess, (state, payload) => ({
        ...state,
        generatedWellniteArt: payload.payload,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(generateWellniteArtImageFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(getPatientWellniteArtsGallery, state => ({
        ...state,
        imagesStatus: SliceStatus.pending,
        error: '',
      }))
      .addCase(getPatientWellniteArtsGallerySuccess, (state, action) => ({
        ...state,
        images: action.payload,
        imagesStatus: SliceStatus.resolved,
        error: '',
      }))
      .addCase(getPatientWellniteArtsGalleryFailure, (state, action) => ({
        ...state,
        imagesStatus: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(getPaginatedWellniteArtsGallery, state => ({
        ...state,
        imagesStatus: SliceStatus.pending,
        error: '',
      }))
      .addCase(getPaginatedWellniteArtsGallerySuccess, (state, action) => ({
        ...state,
        images: action.payload.results,
        imagesStatus: SliceStatus.resolved,
        error: '',
      }))
      .addCase(getPaginatedWellniteArtsGalleryFailure, (state, action) => ({
        ...state,
        imagesStatus: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(getPatientLikedWellniteArts, state => ({
        ...state,
        imagesStatus: SliceStatus.pending,
        error: '',
      }))
      .addCase(getPatientLikedWellniteArtsSuccess, (state, action) => ({
        ...state,
        images: action.payload,
        imagesStatus: SliceStatus.resolved,
        error: '',
      }))
      .addCase(getPatientLikedWellniteArtsFailure, (state, action) => ({
        ...state,
        imagesStatus: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(searchWellniteArtsGallery, state => ({
        ...state,
        imagesStatus: SliceStatus.pending,
        error: '',
      }))
      .addCase(searchWellniteArtsGallerySuccess, (state, action) => ({
        ...state,
        images: action.payload.results,
        imagesStatus: SliceStatus.resolved,
        error: '',
      }))
      .addCase(searchWellniteArtsGalleryFailure, (state, action) => ({
        ...state,
        imagesStatus: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(getWellniteArtForYou, state => ({
        ...state,
        imagesStatus: SliceStatus.pending,
        error: '',
      }))
      .addCase(getWellniteArtForYouSuccess, (state, action) => ({
        ...state,
        wellniteArtForYou: action.payload,
        imagesStatus: SliceStatus.resolved,
        error: '',
      }))
      .addCase(getWellniteArtForYouFailure, (state, action) => ({
        ...state,
        imagesStatus: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(recordWellniteArtReaction, (state, action) => ({
        ...state,
        wellniteArtForYou: state.wellniteArtForYou.map(image =>
          image._id === action.payload.wellniteArtId
            ? {
                ...image,
                liked: action.payload.reaction === 'like',
              }
            : image,
        ),
        error: '',
      }))
      .addCase(recordWellniteArtSuccess, state => ({
        ...state,
        error: '',
      }))
      .addCase(likeOrDislikeWellniteArtFailure, (state, action) => ({
        ...state,
        error: action.payload,
      }))
      .addCase(regenerateWellniteArtCaption, state => ({
        ...state,
        error: '',
      }))
      .addCase(regenerateWellniteArtCaptionSuccess, (state, action) => ({
        ...state,
        generatedWellniteArt: action.payload,
        error: '',
      }))
      .addCase(regenerateWellniteArtCaptionFailure, (state, action) => ({
        ...state,
        error: action.payload,
      }))
      .addCase(getWellniteArtConditionTags, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(getWellniteArtConditionTagsSuccess, (state, action) => ({
        ...state,
        conditionTags: action.payload,
        error: '',
      }))
      .addCase(generateWellniteArtTitle, state => ({
        ...state,
        error: '',
      }))
      .addCase(generateWellniteArtTitleSuccess, (state, action) => ({
        ...state,
        generatedWellniteArt: action.payload,
        error: '',
      }))
      .addCase(getWellniteArtById, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(getWellniteArtByIdSuccess, (state, action) => ({
        ...state,
        generatedWellniteArt: action.payload,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(updateWellniteArtInfo, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(updateWellniteArtInfoSuccess, (state, action) => ({
        ...state,
        generatedWellniteArt: action.payload,
        status: SliceStatus.resolved,
        error: '',
      })),
});

export const {reducer: wellniteArtReducer, name: wellniteArtReducerName} =
  wellniteArtSlice;

export type TWellniteArtActions =
  | ReturnType<typeof generateWellniteArtQuestionnaire>
  | ReturnType<typeof generateWellniteArtQuestionnaireSuccess>
  | ReturnType<typeof generateWellniteArtQuestionnaireFailure>
  | ReturnType<typeof generateWellniteArtImage>
  | ReturnType<typeof generateWellniteArtImageSuccess>
  | ReturnType<typeof generateWellniteArtImageFailure>
  | ReturnType<typeof getPaginatedWellniteArtsGallery>
  | ReturnType<typeof getPaginatedWellniteArtsGallerySuccess>
  | ReturnType<typeof getPaginatedWellniteArtsGalleryFailure>
  | ReturnType<typeof getPatientWellniteArtsGallery>
  | ReturnType<typeof getPatientWellniteArtsGallerySuccess>
  | ReturnType<typeof getPatientWellniteArtsGalleryFailure>
  | ReturnType<typeof getWellniteArtForYou>
  | ReturnType<typeof getWellniteArtForYouSuccess>
  | ReturnType<typeof getWellniteArtForYouFailure>
  | ReturnType<typeof searchWellniteArtsGallery>
  | ReturnType<typeof searchWellniteArtsGallerySuccess>
  | ReturnType<typeof searchWellniteArtsGalleryFailure>
  | ReturnType<typeof recordWellniteArtReaction>
  | ReturnType<typeof recordWellniteArtSuccess>
  | ReturnType<typeof likeOrDislikeWellniteArtFailure>
  | ReturnType<typeof generateIndividualQuestion>
  | ReturnType<typeof generateIndividualQuestionSuccess>
  | ReturnType<typeof generateIndividualQuestionFailure>
  | ReturnType<typeof regenerateWellniteArtCaption>
  | ReturnType<typeof regenerateWellniteArtCaptionSuccess>
  | ReturnType<typeof regenerateWellniteArtCaptionFailure>
  | ReturnType<typeof getWellniteArtConditionTags>
  | ReturnType<typeof getWellniteArtConditionTagsSuccess>
  | ReturnType<typeof getWellniteArtConditionTagsFailure>
  | ReturnType<typeof generateWellniteArtTitle>
  | ReturnType<typeof generateWellniteArtTitleSuccess>
  | ReturnType<typeof generateWellniteArtTitleFailure>
  | ReturnType<typeof getWellniteArtById>
  | ReturnType<typeof getWellniteArtByIdSuccess>
  | ReturnType<typeof getWellniteArtByIdFailure>
  | ReturnType<typeof updateWellniteArtInfo>
  | ReturnType<typeof updateWellniteArtInfoSuccess>
  | ReturnType<typeof updateWellniteArtInfoFailure>;

export const wellniteArtActions = {
  generateWellniteArtQuestionnaire,
  generateWellniteArtQuestionnaireSuccess,
  generateWellniteArtQuestionnaireFailure,
  generateWellniteArtImage,
  generateWellniteArtImageSuccess,
  generateWellniteArtImageFailure,
  getPaginatedWellniteArtsGallery,
  getPaginatedWellniteArtsGallerySuccess,
  getPaginatedWellniteArtsGalleryFailure,
  getPatientWellniteArtsGallery,
  getPatientWellniteArtsGallerySuccess,
  getPatientWellniteArtsGalleryFailure,
  getPatientLikedWellniteArts,
  getPatientLikedWellniteArtsSuccess,
  getPatientLikedWellniteArtsFailure,
  getWellniteArtForYou,
  getWellniteArtForYouSuccess,
  getWellniteArtForYouFailure,
  recordWellniteArtReaction,
  recordWellniteArtSuccess,
  likeOrDislikeWellniteArtFailure,
  searchWellniteArtsGallery,
  searchWellniteArtsGallerySuccess,
  searchWellniteArtsGalleryFailure,
  generateIndividualQuestion,
  generateIndividualQuestionSuccess,
  generateIndividualQuestionFailure,
  regenerateWellniteArtCaption,
  regenerateWellniteArtCaptionSuccess,
  regenerateWellniteArtCaptionFailure,
  getWellniteArtConditionTags,
  getWellniteArtConditionTagsSuccess,
  getWellniteArtConditionTagsFailure,
  generateWellniteArtTitle,
  generateWellniteArtTitleSuccess,
  generateWellniteArtTitleFailure,
  getWellniteArtById,
  getWellniteArtByIdSuccess,
  getWellniteArtByIdFailure,
  updateWellniteArtInfo,
  updateWellniteArtInfoSuccess,
  updateWellniteArtInfoFailure,
};

export type WellniteArtState = ReturnType<typeof wellniteArtReducer>;
