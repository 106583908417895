import React from 'react';

import {SvgIcon} from './SvgIcon';

export const CaretDownIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CaretUpIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      d="M10.707 7.05L10 6.343 4.343 12l1.414 1.414L10 9.172l4.243 4.242L15.657 12z"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CaretRightIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CaretLeftIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 404.258 404.258"
    className={`stroke-current ${strokeColor}`}
  >
    <polygon
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      points="324.7,28.238 296.37,0 89.796,207.248 296.37,414.496 324.7,386.258 146.273,207.248 "
    />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export const GoForwardIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    data-testid="goFwdIcon"
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <polygon
      points="16.172 9 10.101 2.929 11.515 1.515 20 10 19.293 10.707 11.515 18.485 10.101 17.071 16.172 11 0 11 0 9"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const GoBackIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
}) => (
  <svg
    data-testid="goBackIcon"
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      d="M3.828 9l6.071-6.071-1.414-1.414L0 10l.707.707 7.778 7.778 1.414-1.414L3.828 11H20V9H3.828z"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MoreIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path
      d="M4 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CopyIcon: SvgIcon = ({
  width = 16,
  height = 16,
  strokeColor = '#747474',
  fillColor = 'none',
  classes,
  onClick,
}) => (
  <svg
    onClick={onClick}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 1.5H10C10.8284 1.5 11.5 2.17157 11.5 3H6C4.34315 3 3 4.34315 3 6L3 11.5C2.17157 11.5 1.5 10.8284 1.5 10V3C1.5 2.17157 2.17157 1.5 3 1.5ZM4.5 13C4.5 13.8284 5.17157 14.5 6 14.5H13C13.8284 14.5 14.5 13.8284 14.5 13V6C14.5 5.17157 13.8284 4.5 13 4.5V10C13 11.6569 11.6569 13 10 13H4.5ZM11.5 4.5V10C11.5 10.8284 10.8284 11.5 10 11.5H4.5V6C4.5 5.17157 5.17157 4.5 6 4.5H11.5ZM13 3C13 1.34315 11.6569 0 10 0H3C1.34315 0 0 1.34315 0 3V10C0 11.6569 1.34315 13 3 13C3 14.6569 4.34315 16 6 16H13C14.6569 16 16 14.6569 16 13V6C16 4.34315 14.6569 3 13 3Z"
      fill={strokeColor}
    />
  </svg>
);

export const ArrowDownIcon: SvgIcon = ({
  width = 12,
  height = 6,
  strokeColor = 'text-black',
  fillColor = '#00000000',
  classes,
  onClick,
}) => (
  <svg
    data-cy="copy-link"
    onClick={onClick}
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 6"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path d="M6 5.5L11.5 0.5H0.5L6 5.5Z" fill="#747474" />
  </svg>
);

export const ArrowUpIcon: SvgIcon = ({width = 10, height = 15, classes}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
  >
    <path
      d="M5.00391 2.00391V14.0039"
      stroke="#FDFDFD"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M9.00488 6.00879L5.00391 2L0.995117 6.00879"
      stroke="#FDFDFD"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const GlobeIcon: SvgIcon = ({
  width = 20,
  height = 20,
  fillColor = 'none',
  strokeColor = 'text-gray-700',
  strokeWidth = '1.5',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    stroke="currentColor"
    className={`stroke-current ${strokeColor} ${classes}`}
    viewBox="0 0 24 24"
  >
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" />
    <path d="M7.99961 3H8.99961C7.04961 8.84 7.04961 15.16 8.99961 21H7.99961" />
    <path d="M15 3C16.95 8.84 16.95 15.16 15 21" />
    <path d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16" />
    <path d="M3 8.99998C8.84 7.04998 15.16 7.04998 21 8.99998" />
  </svg>
);

export const ToastCheckIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-green-500',
  fillColor = 'currentColor',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path
      fillRule="evenodd"
      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
      clipRule="evenodd"
    />
  </svg>
);

export const ToastCloseIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-red-500',
  fillColor = 'currentColor',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path
      fillRule="evenodd"
      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
      clipRule="evenodd"
    />
  </svg>
);

export const ToastWarningIcon: SvgIcon = ({
  width = 24,
  height = 25,
  strokeColor = 'text-yellow-500',
  fillColor = 'currentColor',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 25"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path
      fillRule="evenodd"
      d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
      clipRule="evenodd"
    />
  </svg>
);

export const ToastInfoIcon: SvgIcon = ({
  width = 24,
  height = 25,
  strokeColor = 'text-blue-500',
  fillColor = 'currentColor',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 25"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path
      fillRule="evenodd"
      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
      clipRule="evenodd"
    />
  </svg>
);
