import React, {ReactNode} from 'react';
import classNames from 'classnames';
import {FormError} from 'components';
import {Control, Controller, FieldPath, FieldValues} from 'react-hook-form';

export type RadioProps<T extends FieldValues> = {
  options: string[] | {label: string; value: string | number}[];
  control: Control<T>;
  name: FieldPath<T>;
  gridType: number;
  className?: string;
  labelClasses?: string;
  radioClasses?: string;
  radioClassesActive?: string;
  errorClasses?: string;
  customIndicator?: React.ComponentType<{isChecked: boolean}>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  label?: ReactNode;
  id: string;
};
export const FormRadio = <T extends FieldValues>({
  options,
  control,
  name,
  gridType,
  className,
  labelClasses = '',
  radioClasses = '',
  radioClassesActive = '',
  errorClasses = '',
  id,
  label,
  onChange: _onChange,
  onBlur: _onBlur,
  customIndicator: CustomIndicator,
}: RadioProps<T>) => {
  return (
    <article>
      {label ? (
        <label
          className={`${labelClasses} block text-left text-base leading-snug font-medium mb-2`}
          id={id}
          htmlFor={name}
        >
          {label}
        </label>
      ) : null}

      <Controller
        control={control}
        name={name}
        render={({field, fieldState: {error}}) => {
          return (
            <>
              <div
                className={`${className} grid lg:grid-cols-${gridType} grid-cols-1 gap-4 mt-5`}
              >
                {options.map((option, idx) => {
                  const label =
                    typeof option === 'object' ? option.label : option;
                  const value =
                    typeof option === 'object' ? option.value : option;
                  const isChecked = field.value === value;

                  return (
                    <label
                      className={classNames(
                        'max-w-full h-[3.25rem] rounded-xl border-2 flex items-center justify-start gap-2 2xs:pl-4',
                        radioClasses,
                        {
                          [radioClassesActive ?? '!border-blue-500']:
                            field.value === value,
                        },
                      )}
                      key={+idx}
                    >
                      {CustomIndicator ? (
                        <CustomIndicator isChecked={isChecked} />
                      ) : null}
                      <input
                        type="radio"
                        id={value + name}
                        name={name}
                        value={value}
                        checked={isChecked}
                        onChange={e => {
                          field.onChange(value);
                          if (typeof _onChange === 'function') {
                            _onChange(e);
                          }
                        }}
                        onBlur={e => {
                          field.onBlur();
                          if (typeof _onBlur === 'function') {
                            _onBlur(e);
                          }
                        }}
                        className={classNames('min-w-[16px] min-h-[16px]', {
                          hidden: CustomIndicator,
                        })}
                      />
                      <span
                        className={classNames(
                          'text-black text-left font-inter font-medium text-[0.875rem] leading-normal',
                          labelClasses,
                        )}
                      >
                        {label}
                      </span>
                    </label>
                  );
                })}
              </div>
              {error?.message ? (
                <FormError error={error.message} errorClasses={errorClasses} />
              ) : null}
            </>
          );
        }}
      />
    </article>
  );
};
