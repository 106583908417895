import React from 'react';

import {SvgIcon} from './SvgIcon';

export const ArrowRightIcon: SvgIcon = ({
  width = 15,
  height = 13,
  strokeColor = 'white',
  strokeWidth = 1.5,
  fillColor = 'white',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 13"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.61781 0L6.5 1.05573L12.2644 6.5L6.5 11.9443L7.61781 13L14.5 6.5L7.61781 0Z"
      fill={fillColor}
    />
    <path d="M12.5 6.5H0" stroke={strokeColor} strokeWidth={strokeWidth} />
  </svg>
);
